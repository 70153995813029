import { VerticalAlignCenter } from "@material-ui/icons";
import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "../components/materialdashboard";

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  submit: {
    margin: 0,
    color: 'white',
    backgroundColor: '#FF9A00',
    fontWeight: '700',
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  table: {
    borderBottom: "none",
  },
  tableMain: {
    width: "100%",
    marginTop: '3px',
    borderBottom: "none",
    overflowX: "auto",
    overflow: 'auto'
  },
  mainTableCellF: {
    borderBottom: "none",
    padding: "0px",
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  mainTableCell: {
    borderBottom: "none",
    borderLeft: "none", 
    padding: "0px"   
  },
  mainTableCellMob: {
    borderBottom: "none",
    borderLeft: "none",
    display: "block",
    padding: "0px"
  },
  mainTableCellBal: {
    borderBottom: "none",
    borderLeft: "1px solid rgba(224, 224, 224, 1)", 
    padding: "0px"   
  },
  mainTableCellMobBal: {
    borderBottom: "none",
    display: "block",
    padding: "0px"
  },
  headTable: {
    borderBottom: "none",
    borderLeft: "none",
    display: "flex",
    fontSize: "20px",
    alignItems: "center",
    color: "#666666",
    padding: "0px",
    textAlign: "left",
    marginTop: "5px",
    marginLeft: "5px"
  },
  headTableFirst: {
    borderBottom: "2px solid #0264ff",
    borderLeft: "none",
    display: "flex",
    fontSize: "20px",
    alignItems: "center",
    color: "#0264ff",
    padding: "0px",
    textAlign: "left",
    width: "90%",
    marginLeft: "10px",
    marginTop: "15px"
  },
  headTableBalance: {
    borderBottom: "2px solid #0264ff",
    borderLeft: "none",
    display: "flex",
    fontSize: "20px",
    alignItems: "center",
    color: "#0264ff",
    padding: "0px",
    textAlign: "left",
    width: "90%",
    marginLeft: "10px",
    marginTop: "15px"
  },
  headTableBal: {
    borderBottom: "none",
    borderLeft: "none",
    display: "flex",
    fontSize: "10px",
    alignItems: "center",
    color: "#666666",
    marginLeft: "25px",
    padding: 0
  },
  iconDolar: {
    color: "green",
    fontSize: "35px",
    marginRight: "5px",
  },
  bodyTable: {
    borderBottom: "none",
    textAlign: 'center',
  },
  textoTableBodySign: {
    fontSize: "20px",
    display: "inline-block",
    color: "#999999",
    fontWeight: "bold"
  },
  textoTableBodySignM: {
    fontSize: "35px",
    display: "inline-block",
    color: "#008000",
    fontWeight: "bold"
  },
  textoTableHead: {
    fontSize: "15px",
    display: "inline-block",
    color: "#0560fb",
    fontWeight: "bold"
  },
  textoTableHeadM: {
    fontSize: "12px",
    display: "inline-block",
    color: "#0560fb",
    fontWeight: "bold"
  }, 
  textoTableHeadTotal: {
    fontSize: "18px",
    display: "inline-block",
    color: "green",
    fontWeight: "bold"
  },
  textoTableHeadMTotal: {
    fontSize: "16px",
    display: "inline-block",
    color: "green",
    fontWeight: "bold"
  }, 
  textoTableBodySignTable: {
    fontSize: "14px",
    display: "inline-block",
    color: "black",
    fontWeight: "bold",
  },
  textoTableBodySignTableLow: {
    fontSize: "14px",
    display: "inline-block",
    color: "gray",
    fontWeight: "bold",
  },
  textoTableBodySignTableLowM: {
    fontSize: "12px",
    display: "inline-block",
    color: "gray",
    fontWeight: "bold",
  },
  textoTableBodySignMTable: {
    fontSize: "12px",
    display: "inline-block",
    color: "black",
    fontWeight: "bold"
  },
  textoTableBodySignOnly: {
    fontSize: "50px",
    display: "inline-block",
    color: "#008000",
    fontWeight: "bold"
  },
  textoTableBodyAmt: {
    fontSize: "16px",
    display: "inline-block",
    fontWeight: "bold",
    color: '#212121'
  },
  textoTableBodyAmtM: {
    fontSize: "20px",
    display: "inline-block",
    fontWeight: "bold",
    color: "#212121"
  },
  textoTableBodyAmtTable: {
    fontSize: "14px",
    display: "inline-block",
    fontWeight: "bold",
    color: '#212121'
  },
  textoTableBodyAmtMTable: {
    fontSize: "12px",
    display: "inline-block",
    fontWeight: "bold",
    color: "#212121"
  },
  textoTableBodyAmtTableTotal: {
    fontSize: "18px",
    display: "inline-block",
    fontWeight: "bold",
    color: 'green'
  },
  textoTableBodyAmtMTableTotal: {
    fontSize: "16px",
    display: "inline-block",
    fontWeight: "bold",
    color: "green"
  },
  tableRowMainBalance: {
    margin: '0px 0px 0px 0px',
    padding: '0px 0px 0px 10px'
  },
  tableRowMainTotal: {
    margin: '0px 0px 0px 0px',
    padding: '0px 0px 0px 10px'
  },
  textoTableBodyAmtOnly: {
    fontSize: "50px",
    display: "inline-block",
    fontWeight: "bold",
    color: "#008000"
  },  
  textoTableBodySignBal: {
    fontSize: "15px",
    display: "inline-block",
    color: "#999999",
    fontWeight: "bold"
  },
  textoTableBodySignMBal: {
    fontSize: "10px",
    display: "inline-block",
    color: "#999999",
    fontWeight: "bold"
  },  
  textoTableBodyAmtMBalanceF: {
    fontSize: "35px",
    display: "inline-block",
    fontWeight: "bold",
    color: "#008000"
  },
  textoTableBodyAmtBal: {
    fontSize: "15px",
    display: "inline-block",
    fontWeight: "bold"
  },
  textoTableBodyAmtMBal: {
    fontSize: "10px",
    display: "inline-block",
    fontWeight: "bold"
  },
  iconBTC: {
    color: "orange",
    fontSize: "35px",
    marginRight: "5px"
  },
  iconCryptoM: {
    fontSize: "30px",
    marginRight: "5px"
  },
  iconCrypto: {
    fontSize: "35px",
    marginRight: "5px"
  },
  iconExchange: {
    color: "#0560FB",
    fontSize: "35px",
    marginRight: "5px"
  },
  iconCustomers: {
    color: "orange",
    fontSize: "35px",
    marginRight: "5px"
  },
  CardRound: {
    borderRadius: "15px"
  },  
};

export default dashboardStyle;
