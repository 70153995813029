import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    margin: "0 -15px !important",
    width: "unset",    
  }
};

const stylesmob = {
  grid: {
    margin: "0 -30px !important",
    width: "unset",
    maxHeight: '2000vh', 
    overflow: 'auto'    
  }
};

export default function GridContainer(props) {  
  const {ismobile} = props;
  let styl = {};
  if(ismobile === undefined){
    styl = styles;
  }else{
    styl = stylesmob;
  }
  const useStyles = makeStyles(styl);
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid} alignContent="center" justifyContent="center">
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node
};
