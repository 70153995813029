import React from 'react';
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.js";
import MessagesAgent from '../../components/Tables/Messages';
import SentMessages from '../../components/Tables/SentMessages';
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { useSelector } from 'react-redux';

import Email from '@material-ui/icons/Email';
import Sent from '@material-ui/icons/Send';
import NewMessage from '../../components/Messages/NewMessage';


const Messages = () => {
  //const agentData = useSelector((state) => state.agent);

  const agentId = JSON.parse(localStorage.getItem('profile')).agentId;

  return agentId?(
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Messages",
                tabIcon: Email,
                tabContent: (
                  <MessagesAgent agentId={agentId}/>
                )
              },
              {
                tabName: "Sent",
                tabIcon: Sent,
                tabContent: (
                  <SentMessages agentId={agentId}/>
                )
              },
              {
                tabName: "New Message",
                tabIcon: Sent,
                tabContent: (
                  <NewMessage />
                )
              }
            ]}
          />
        </GridItem>        
      </GridContainer>
    </div>
  ):null
}

export default Messages
