import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Logo from '../../assets/img/logo-lg-light.png';
import LogoSmall from '../../assets/img/logo-small.png';
//import BG from "../../assets/img/bg2.png";


const useStyles = makeStyles(theme => ({
  dialog: {
      padding: theme.spacing(2),
      position: 'absolute',
      top: theme.spacing(5),
      backgroundColor: 'white'
  },
  dialogTitle: {
      //textAlign: 'center',
      //backgroundColor: 'lightgreen',
      //fontWeight: 'bold'
      //backgroundImage: `url(${BG})`,
      //backgroundPosition: 'center', 
      //backgroundSize: 'cover', 
      //backgroundRepeat: 'no-repeat',
      alignContent: 'center',
      textAlign: 'center'
  },
  dialogContent: {
      textAlign: 'center',
      color: 'white'
  },
  dialogAction: {
      justifyContent: 'center'
  },
  titleIcon: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      '&:hover': {
          backgroundColor: theme.palette.secondary.light,
          cursor: 'default'
      },
      '& .MuiSvgIcon-root': {
          fontSize: '8rem',
      }
  },
  logo: {
    textAlign: 'center',
    marginTop: '40px'
},

login: {
    alignItems: 'center',
    textAlign: 'center',
    alignContent: 'center',
    backgroundColor: 'none',
    display: 'flex',
    marginTop: '100px'
},

confirmstyle: {
    alignItems: 'center',
    textAlign: 'center',
    alignContent: 'center',
    backgroundColor: 'none',
},

form: {
    width: '100%',
    marginTop: theme.spacing(3),
    backgroundColor: 'none'
},

paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: 15,
},
textfield: {
    backgroundColor: 'white'
},

texto: {
    color: 'black',
    fontWeight: 'bold'
},
textotittle: {
    color: '#009606',
    fontWeight: 'bold',
    fontSize:'24px'
},
textotxid: {
    color: 'orange',
    fontWeight: 'bold',
    textAlign: 'Left'
},
textoDeposit: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: "32px"
},

submit: {
    backgroundColor: '#F0150F',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'none',
    "&:hover":{
        backgroundColor: '#CDCBCD',
        color: 'black'
    }
},
alert: {
    marginTop: "15px"
},
table: {
    backgroundColor: 'white'
},
textonobold: {
    color: 'black',
},
}))

export default function DepositConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles()

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography className={classes.textotittle}>Deposit Confirmed</Typography>                
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
                <TableContainer>
                <Table className={classes.table}>                    
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography className={classes.texto}>Total Deposit USD</Typography>
                            </TableCell>
                            <TableCell className={classes.cellright}>
                                <Typography className={classes.textonobold}>{`$${confirmDialog.totalAmountUSDDep}`}</Typography> 
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography className={classes.texto}>Total Deposit BTC</Typography>
                            </TableCell>
                            <TableCell className={classes.cellright}>
                                <Typography className={classes.textonobold}>{`₿${confirmDialog.totalDepOnBTC}`}</Typography> 
                            </TableCell>
                        </TableRow>
                        <TableRow>                                
                            <TableCell>
                                <Typography className={classes.texto}>Total Deposit Satochis</Typography>
                            </TableCell>
                            <TableCell className={classes.cellright}>
                                <Typography className={classes.textonobold}>{`${confirmDialog.totalAmountSatDep}`}</Typography> 
                            </TableCell>
                        </TableRow>                                                 
                        <TableRow>
                            <TableCell>
                                <Typography className={classes.texto}>Exchange Rate</Typography>
                            </TableCell>
                            <TableCell className={classes.cellright}>
                                <Typography className={classes.textonobold}>{`$${confirmDialog.exchange}`}</Typography> 
                            </TableCell>
                        </TableRow>                        
                        <TableRow>
                            <TableCell>
                                <Typography className={classes.texto}>Total Fee USD</Typography>
                            </TableCell>
                            <TableCell className={classes.cellright}>
                                <Typography className={classes.textonobold}>{`$${confirmDialog.totalFeeInUSD}`}</Typography> 
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography className={classes.texto}>Balance</Typography>
                            </TableCell>
                            <TableCell className={classes.cellright}>
                                <Typography className={classes.textonobold}>{`${confirmDialog.balance}`}</Typography> 
                            </TableCell>
                        </TableRow>
                        
                    </TableBody>
                </Table>
            </TableContainer>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button
                    variant="contained"
                    className={classes.submit}
                    onClick={confirmDialog.onConfirm}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}