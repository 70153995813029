import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
    logo: {
        textAlign: 'center',
        marginTop: '40px'
    },

    login: {
        alignItems: 'center',
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: 'none'
    },

    form: {
        width: '100%',
        marginTop: theme.spacing(3),
        backgroundColor: 'none'
    },
    textoAuth: {
        color: 'black',
        fontSize: '13px',
        fontWeight: 'bold'
    },
    sixDigit: {
        color: 'black',
        fontSize: '13px',
        fontWeight: 'bold'
    },
    textoSec: {
        color: 'black',
        fontSize: '19px',
        fontWeight: 'bold'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: 15,
    },
    textfield: {
        backgroundColor: 'white'
    },

    texto: {
        color: '#666666',
        fontWeight: 'bold'
    },

    submit: {
        backgroundColor: '#034AFF',
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        "&:hover":{
            backgroundColor: '#03b7ff',
            color: 'white'
        }
    },
    alert: {
        marginTop: "15px"
    },

    main: {
        alignItems: 'center',
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: 'none'
    }

}));
