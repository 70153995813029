import { makeStyles } from "@material-ui/core/styles";
import bgImage from "../../assets/img/bg.jpg";

export default makeStyles((theme) => ({
    containercss: {
        backgroundImage: `url(${bgImage})`,
    },
    logo: {
        textAlign: "center",
        marginTop: "40px",
    },

    invoice: {
        alignItems: "center",
        textAlign: "center",
        alignContent: "center",
        backgroundColor: "none",
        display: "flex",
        marginTop: "100px",
    },

    cardAgent: {
        backgroundColor: "white",
    },

    agentName: {
        fontSize: "20px",
        fontWeight: "bold",
    },

    tablecell: {
        borderBottom: "none",
    },

    textarea: {
        backgroundColor: "#f9f9f9",
        borderRadius: 5,
        borderWidth: 3,
    },
    confirmstyle: {
        alignItems: "center",
        textAlign: "center",
        alignContent: "center",
        backgroundColor: "none",
    },

    form: {
        width: "100%",
        marginTop: theme.spacing(3),
        backgroundColor: "none",
    },

    tableResponsive: {
        width: "100%",
        marginTop: "3px",
        overflowX: "auto",
        overflow: "auto",
    },

    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        borderRadius: 15,
    },
    textfield: {
        color: "black",
        borderRadius: 5,
        borderWidth: 3,
        borderColor: "green",
        backgroundColor: "white",
    },

    select: {
        backgroundColor: "white",
        fontWeight: "bold",
        fontSize: "16px",
        borderRadius: 5,
        borderWidth: 3,
        width: "120px",
    },

    headerCard: {
        borderRadius: "15px",
    },

    texto: {
        color: "black",
        fontWeight: "normal",
    },
    textopayment: {
        color: "#555555",
        fontSize: "22px",
    },
    bold: {
        fontWeight: "bold",
        fontSize: "22px",
    },
    cellcenter: {
        alignItems: "center",
        textAlign: "center",
        alignContent: "center",
        backgroundColor: "none",
    },
    cellright: {
        textAlign: "right",
    },
    cellleft: {
        textAlign: "left",
    },
    celllefttop: {
        padding: "0 0 0 10px",
        textAlign: "left",
        borderBottom: "none",
    },
    cellrighttop: {
        textAlign: "right",
        borderBottom: "none",
    },
    cellright2: {
        width: "65%",
    },
    containRight: {
        alignItems: "right",
        textAlign: "right",
        alignContent: "right",
        backgroundColor: "none",
    },
    textoln: {
        color: "black",
        fontWeight: "bold",
        fontSize: "16px",
    },
    textotxid: {
        color: "orange",
        fontWeight: "bold",
        textAlign: "Left",
    },
    textoDeposit: {
        color: "black",
        fontWeight: "bold",
        fontSize: "32px",
    },

    submit: {
        backgroundColor: "#034AFF",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#03b7ff",
            color: "white",
        },
    },
    alert: {
        marginTop: "15px",
    },

    tabsSt: {
        textTransform: "none",
        paddingLeft: "15px",
        paddingRight: "15px",
        fontSize: "18px",
        fontWeight: "800",
        color: "#0560fb",
    },

    tabsStMob: {
        textTransform: "none",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontSize: "14px",
        fontWeight: "800",
        color: "#0560fb",
    },

    appbar: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #E3E3E3",
        borderRadius: "15px",
        color: "#0560fb",
        padding: "0px 40px 0px 20px",
        textTransform: "none",
    },

    container: {
        width: "100%",
        maxwidth: "100%",
    },

    tabpanel: {
        width: "100%",
        maxwidth: "100%",
    },

    indicator: {
        background: "#0560fb",
    },

    textoAuth: {
        color: "black",
        fontSize: "13px",
        fontWeight: "bold",
    },
    sixDigit: {
        color: "black",
        fontSize: "13px",
        fontWeight: "bold",
    },
    textoSec: {
        color: "black",
        fontSize: "19px",
        fontWeight: "bold",
    },
}));
