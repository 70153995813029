import React from "react";
import Button from "@material-ui/core/Button";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from "@material-ui/core";
import Logo from "../../assets/img/logo.png";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(2),
        position: "absolute",
        top: theme.spacing(5),
    },
    dialogTitle: {
        textAlign: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    dialogContent: {
        textAlign: "center",
    },
    dialogAction: {
        justifyContent: "center",
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
            cursor: "default",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "8rem",
        },
    },
}));

export default function OkDialog(props) {
    const { okDialog, setOkDialog } = props;
    const classes = useStyles();

    return (
        <Dialog open={okDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <div>
                    <img src={Logo} width="210px" height="70px" />
                </div>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">{okDialog.title}</Typography>
                <Typography variant="subtitle2">{okDialog.subTitle}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button color="default" onClick={okDialog.onConfirm}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
