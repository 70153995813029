import React from "react";
import { TextField, Grid, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import UseStyles from "./styles";
import { makeStyles } from "@material-ui/core/styles";

const useStylesNew = makeStyles((theme) => ({
    inputStyle: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            display: "none",
        },
    },
}));

const Input = ({ half, name, label, autoFocus, type, handleShowPassword, handleChange, id }) => {
    const classes = UseStyles();
    const classesNew = useStylesNew();
    return (
        <Grid item xs={12} sm={half ? 6 : 12}>
            <TextField
                name={name}
                className={classes.textfield}
                onChange={handleChange}
                variant="outlined"
                required={true}
                fullWidth
                label={label}
                autoFocus={autoFocus}
                id={id}
                type={type}
                InputProps={
                    name.toString() === "password"
                        ? {
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <IconButton onClick={handleShowPassword}>{type === "password" ? <Visibility /> : <VisibilityOff />}</IconButton>
                                  </InputAdornment>
                              ),
                          }
                        : id === "pin"
                        ? { classes: { input: classesNew.inputStyle } }
                        : null
                }
            />
        </Grid>
    );
};

export default Input;
