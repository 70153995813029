import React,{useEffect} from 'react';
import moment from 'moment';
//Core
import ChartistGraph from "react-chartist";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
//Core Components
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
//Functions
import {getWeeklyData} from '../../ChartsData/WeeklyRake';
import {weekDeposits} from '../../actions/agent';

//Styles
import styles from '../../assets/styles/views/dashboardStyle';

const useStyles = makeStyles(styles);

const WeekDeposits = (props) => {
  const {agentId} = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const figuresByWeek = useSelector((state) => state.agent.weekDeposits);

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  let weeklyRake = {data: {
    labels: ["M", "T", "W", "T", "F", "S", "S"],
    series: [[0, 0, 0, 0, 0, 0, 0]]
  }};

  let day1=0;
  let day2=0;
  let day3=0;
  let day4=0;
  let day5=0;
  let day6=0;
  let day7=0;

  let todayNum = moment(new Date()).toDate().getDay();

  if(figuresByWeek?.data){
    figuresByWeek.data.forEach( row => {
      if(row._id.day === 2){
        day1=row.total;
      } else if(row._id.day === 3){
        day2=row.total;
      } else if(row._id.day === 4){
        day3=row.total;
      } else if(row._id.day === 5){
        day4=row.total;
      } else if(row._id.day === 6){
        day5=row.total;
      } else if(row._id.day === 7){
        day6=row.total;
      } else if(row._id.day === 1 && todayNum === 6){
        day7=row.total;
      }
    });
  }

  if(figuresByWeek?.data){ 
    let arr =  [day1,day2,day3,day4,day5,day6,day7];
    let min = Math.min.apply(null, arr);  
    let max = Math.max.apply(null, arr); 
    weeklyRake = getWeeklyData(arr,min,max);
  }

  useEffect(() => {
    dispatch(weekDeposits(tz,0,agentId));     
  }, [dispatch])
  return (
    <Card chart>
      <CardHeader color="success">
        <ChartistGraph
          className="ct-chart"
          data={weeklyRake?.data}
          type="Line"
          options={weeklyRake?.options}
          listener={weeklyRake?.animation}                
        />
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>Week Deposits</h4>              
      </CardBody>            
    </Card>
  )
}

export default WeekDeposits
