import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(2),
        position: "absolute",
        top: theme.spacing(5),
        backgroundColor: "white",
    },
    dialogTitle: {
        //textAlign: 'center',
        //backgroundColor: 'lightgreen',
        //fontWeight: 'bold'
        //backgroundImage: `url(${BG})`,
        //backgroundPosition: 'center',
        //backgroundSize: 'cover',
        //backgroundRepeat: 'no-repeat',
        alignContent: "center",
        textAlign: "center",
    },
    dialogContent: {
        textAlign: "center",
        color: "white",
    },
    dialogAction: {
        justifyContent: "center",
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
            cursor: "default",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "8rem",
        },
    },
    logo: {
        textAlign: "center",
        marginTop: "40px",
    },

    login: {
        alignItems: "center",
        textAlign: "center",
        alignContent: "center",
        backgroundColor: "none",
        display: "flex",
        marginTop: "100px",
    },

    confirmstyle: {
        alignItems: "center",
        textAlign: "center",
        alignContent: "center",
        backgroundColor: "none",
    },

    form: {
        width: "100%",
        marginTop: theme.spacing(3),
        backgroundColor: "none",
    },

    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        borderRadius: 15,
    },
    textfield: {
        backgroundColor: "white",
    },

    texto: {
        color: "black",
        fontWeight: "bold",
    },
    textotittle: {
        color: "#009606",
        fontWeight: "bold",
        fontSize: "24px",
    },
    textotxid: {
        color: "orange",
        fontWeight: "bold",
        textAlign: "Left",
    },
    textoDeposit: {
        color: "black",
        fontWeight: "bold",
        fontSize: "32px",
    },

    submit: {
        backgroundColor: "#0560fb",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#CDCBCD",
            color: "black",
        },
    },
    alert: {
        marginTop: "15px",
    },
    table: {
        backgroundColor: "white",
    },
    textonobold: {
        color: "black",
    },
}));

const ConfirmReject = (props) => {
    const { confirmDialog, setConfirmDialog, cryptoCurrency } = props;
    const classes = useStyles();
    const [confirmDisable, setConfirmDisable] = useState(false);

    const handleConfirm = async (e) => {
        e.preventDefault();
        setConfirmDisable(true);
        if (!e.currentTarget.disabled) {
            e.currentTarget.disabled = true;
            confirmDialog.onConfirm();
        }
    };
    return (
        <Dialog open={confirmDialog.isOpen} PaperProps={{ sx: { position: "fixed", top: 100 } }}>
            <DialogTitle>
                <Typography variant="h4">Withdrawal Confirmation</Typography>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ width: "50%" }} align="right">
                                    <Typography>Amount USD:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography sx={{ fontWeight: "700" }}>{`$${confirmDialog.totalUSD}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ width: "50%" }} align="right">
                                    <Typography>{`Amount ${cryptoCurrency}`}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography sx={{ fontWeight: "700" }}>{`${confirmDialog.totalBTC}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ width: "50%" }} align="right">
                                    <Typography>Fee:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography sx={{ fontWeight: "700" }}>{`$${confirmDialog.totalFee}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ width: "50%" }} align="right">
                                    <Typography>Amount to Receive:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography sx={{ fontWeight: "700" }}>{`$${confirmDialog.grandTotal}`}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{ textAlign: "center" }}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ width: "50%" }} align="center">
                                    <Button variant="contained" className={classes.submit} onClick={handleConfirm} disabled={confirmDisable}>
                                        Confirm
                                    </Button>
                                </TableCell>
                                <TableCell sx={{ width: "50%" }} align="center">
                                    <Button className={classes.submit} variant="contained" onClick={confirmDialog.onReject}>
                                        Cancel
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmReject;
