import React,{useEffect} from 'react';
//Core
import ChartistGraph from "react-chartist";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
//Core Components
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
//Functions
import {getHourlyData} from '../../ChartsData/HourlyRake';
import {hourDeposits} from '../../actions/agent';

//Styles
import styles from '../../assets/styles/views/dashboardStyle';

const useStyles = makeStyles(styles);

const HourDeposits = (props) => {
  const {agentId} = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const figuresByHour = useSelector((state) => state.agent.hourlyDeposits);  

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  let hourlyRake = {
    labels: ["12md", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm"],
    series: [[230, 750, 450, 300, 280, 240, 200, 190]]
  };

  let hr0=0;
  let hr1=0;
  let hr2=0;
  let hr3=0;
  let hr4=0;
  let hr5=0;
  let hr6=0;
  let hr7=0;
  let hr8=0;
  let hr9=0;
  let hr10=0;
  let hr11=0;
  let hr12=0;
  let hr13=0;
  let hr14=0;
  let hr15=0;
  let hr16=0;
  let hr17=0;
  let hr18=0;
  let hr19=0;
  let hr20=0;
  let hr21=0;
  let hr22=0;
  let hr23=0;

  if(figuresByHour?.data){
    figuresByHour.data.forEach( row => {
      if(row._id.hour === 0){
        hr0=row.total;
      } else if(row._id.hour === 1){
        hr1=row.total;
      } else if(row._id.hour === 2){
        hr2=row.total;
      } else if(row._id.hour === 3){
        hr3=row.total;
      } else if(row._id.hour === 4){
        hr4=row.total;
      } else if(row._id.hour === 5){
        hr5=row.total;
      } else if(row._id.hour === 6){
        hr6=row.total;
      } else if(row._id.hour === 7){
        hr7=row.total;
      } else if(row._id.hour === 8){
        hr8=row.total;
      } else if(row._id.hour === 9){
        hr9=row.total;
      } else if(row._id.hour === 10){
        hr10=row.total;
      } else if(row._id.hour === 11){
        hr11=row.total;
      } else if(row._id.hour === 12){
        hr12=row.total;
      } else if(row._id.hour === 13){
        hr13=row.total;
      } else if(row._id.hour === 14){
        hr14=row.total;
      } else if(row._id.hour === 15){
        hr15=row.total;
      } else if(row._id.hour === 16){
        hr16=row.total;
      } else if(row._id.hour === 17){
        hr17=row.total;
      } else if(row._id.hour === 18){
        hr18=row.total;
      } else if(row._id.hour === 19){
        hr19=row.total;
      } else if(row._id.hour === 20){
        hr20=row.total;
      } else if(row._id.hour === 21){
        hr21=row.total;
      } else if(row._id.hour === 22){
        hr22=row.total;
      } else if(row._id.hour === 23){
        hr23=row.total;
      }
    });
  }
  
  if(figuresByHour?.data){ 
    let tarr =  [hr0,hr1,hr2,hr3,hr4,hr5,hr6,hr7,hr8,hr9,hr10,hr11,hr12,hr13,hr14,hr15,hr16,hr17,hr18,hr19,hr20,hr21,hr22,hr23];
    let hrs = [];
    let arr = []
    let hourstr = "";
    let tempDate = new Date();
    let localHour = tempDate.getHours();
    let startHour = parseInt(localHour) - 7;
    if(startHour < 0){
      startHour = 23 - parseInt(startHour);
    }
    
    for(let h = 0; h < 8; h++){
      arr.push(tarr[parseInt(startHour)]);
      if(startHour<12){
        hourstr = startHour.toString() + "am";
      }else if(startHour == 12){
        hourstr = startHour.toString() + "md";
      }else{
        hourstr = (parseInt(startHour)-12).toString() + "pm";
      }
      hrs.push(hourstr);
      startHour++;
      if(startHour>23){
        startHour = 0
      }
    }
    let min = Math.min.apply(null, arr);  
    let max = Math.max.apply(null, arr);   
    hourlyRake = getHourlyData(arr,hrs,min,max);
  }else{
    let hrs = [];
    let arr = []
    let hourstr = "";
    let tempDate = new Date();
    let localHour = tempDate.getHours();
    let startHour = parseInt(localHour) - 7;
    if(startHour < 0){
      startHour = 23 - parseInt(startHour);
    }
    
    for(let h = 0; h < 8; h++){
      arr.push(0);
      if(startHour<12){
        hourstr = startHour.toString() + "am";
      }else if(startHour == 12){
        hourstr = startHour.toString() + "md";
      }else{
        hourstr = (parseInt(startHour)-12).toString() + "pm";
      }
      hrs.push(hourstr);
      startHour++;
      if(startHour>23){
        startHour = 0
      }
    }
    let min = Math.min.apply(null, arr);  
    let max = Math.max.apply(null, arr);   
    hourlyRake = getHourlyData(arr,hrs,min,max);
  }
  
  useEffect(() => { 
    dispatch(hourDeposits(tz,agentId));      
  }, [dispatch])

  return (
    <Card chart>
      <CardHeader color="danger">
        <ChartistGraph
          className="ct-chart"
          data={hourlyRake.data}
          type="Line"
          options={hourlyRake.options}
          listener={hourlyRake.animation}
        />
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>Deposits By Hour (Last 8 Hours)</h4>
      </CardBody>
    </Card>
  )
}

export default HourDeposits
