import { defaultFont, primaryColor, dangerColor, grayColor } from "./materialdashboard";
import tooltipStyle from "./toolTipStyle";
const agentListStyle = {
    ...tooltipStyle,
    root: {
        "& .texbold": {
            fontWeight: "bold",
        },
    },
    table: {
        marginBottom: "0",
        overflow: "visible",
    },
    textcode: {
        display: "none",
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid " + grayColor[5],
    },
    tableActions: {
        display: "inline-block",
        border: "none",
        padding: "12px 8px !important",
        verticalAlign: "middle",
    },
    textBold: {
        fontWeight: "bold",
    },
    tableCell: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableCellSubTotal: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
        fontWeight: "bold",
    },
    tableCellGrandotal: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "17px",
        fontWeight: "bold",
        color: "green",
    },
    tableCellRTL: {
        textAlign: "right",
    },
    tableActionButton: {
        width: "27px",
        height: "27px",
        padding: "0",
    },
    tableActionButtonIcon: {
        width: "24px",
        height: "24px",
    },
    tableAcordeon: {
        width: "100%",
    },
    edit: {
        backgroundColor: "transparent",
        color: primaryColor[0],
        boxShadow: "none",
    },
    transfer: {
        backgroundColor: "transparent",
        color: dangerColor[0],
        boxShadow: "none",
    },
    rake: {
        backgroundColor: "transparent",
        color: "green",
        boxShadow: "none",
    },
    transaction: {
        backgroundColor: "transparent",
        color: "orange",
        boxShadow: "none",
    },
    copy: {
        backgroundColor: "transparent",
        color: "blue",
        boxShadow: "none",
    },
    tableResponsive: {
        width: "100%",
        marginTop: "3px",
        overflowX: "auto",
        overflow: "auto",
    },
    tableHeadCell: {
        color: "inherit",
        ...defaultFont,
        "&, &$tableCell": {
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    tableHeadRow: {
        height: "36px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    coinlistimage: {
        width: "36px",
    },
    coinlistitem: {
        display: "flex",
    },
    tableCellMin: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "10px",
    },
    tableCellNumber: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "13px",
    },
    buttonPadding: {
        padding: "8px",
        marginLeft: "5px",
        color: "white",
        backgroundColor: "#ec0005",
        "&:hover": {
            backgroundColor: "#da0105",
        },
    },
    buttonPaddingAccept: {
        padding: "8px",
        marginLeft: "5px",
        color: "white",
        backgroundColor: "#009054",
        "&:hover": {
            backgroundColor: "#017c49",
        },
    },
    datebutton: {
        backgroundColor: "#034AFF",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: "20px",
        width: "150px",
        "&:hover": {
            backgroundColor: "#03b7ff",
            color: "white",
        },
    },
};
export default agentListStyle;
