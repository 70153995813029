import React, { useState, useEffect } from "react";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import UseStyles from "./styles";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getAgentInfoSettings, updateAgentSettings } from "../../actions/agent";
import { useDispatch } from "react-redux";
import OkDialog from "../Dialogs/OkDialog";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../constants/actionTypes";

const ChangePassword = () => {
    const classes = UseStyles();
    const history = useHistory();
    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorConfirm, setErrorConfirm] = useState(false);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const dispatch = useDispatch();
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    const token = useSelector((state) => state?.login?.loginData?.token);
    const [postData, setPostData] = useState({
        agentId: agentId,
        merchant: merchant,
        oldpassword: "",
        password: "",
        username: "",
        confirmPassword: "",
        freeplaybtc: false,
        freeplaybtcpercentage: 0,
        freeplayln: false,
        freeplaylnpercentage: 0,
        telegram: 0,
        applydepositfee: false,
        freeplayeth: false,
        freeplayethpercentage: 0,
        freeplayusdt: false,
        freeplayusdtpercentage: 0,
        freeplayusdc: false,
        freeplayusdcpercentage: 0,
        depositbtc: false,
        depositln: false,
        depositeth: false,
        depositusdt: false,
        depositusdc: false,
        withdrawbtc: false,
        withdrawln: false,
        withdraweth: false,
        withdrawusdt: false,
        withdrawusdc: false,
    });

    const handleShowPasswordOld = () => {
        setShowPasswordOld((prevShowPassword) => !prevShowPassword);
    };
    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
    };

    const validate = () => {
        let returnValidate = true;
        const password = document.getElementById("password").value;
        const confirm = document.getElementById("confirm").value;
        if (password === confirm) {
            setErrorConfirm(false);
        } else {
            setErrorConfirm(true);
            returnValidate = false;
        }
        return returnValidate;
    };

    const submitPassword = (e) => {
        e.preventDefault();
        if (validate() === true) {
            e.currentTarget.disabled = true;
            dispatch(
                updateAgentSettings(postData, true, token, (res) => {
                    if (res.error) {
                        if (res.message === "Token Expire") {
                            dispatch({ type: LOGIN, res });
                            history.push("/");
                        }
                    }
                    if (!res.error) {
                        setPostData({
                            ...postData,
                            oldpassword: "",
                            password: "",
                            confirmPassword: "",
                        });
                        document.getElementById("password").textContent = "";
                        document.getElementById("confirm").textContent = "";
                        document.getElementById("oldpassword").textContent = "";
                        setOkDialog({
                            isOpen: true,
                            title: "Settings Updated",
                            subTitle: res?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    } else {
                        setOkDialog({
                            isOpen: true,
                            title: "Error on update",
                            subTitle: res?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                    e.currentTarget.disabled = false;
                })
            );
        } else {
            setOkDialog({
                isOpen: true,
                title: "Error on change password",
                subTitle: "Confirmation password does not match",
                onConfirm: () => {
                    setOkDialog({
                        ...okDialog,
                        isOpen: false,
                    });
                },
            });
        }
    };

    let isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={12}>
                <Card style={{ borderRadius: "15px" }}>
                    <CardHeader color="info" style={{ borderRadius: "15px" }}>
                        <Typography className={classes.agentName}>{"Change Password"}</Typography>
                    </CardHeader>
                    <CardBody>
                        <TextField
                            name="oldpassword"
                            id="oldpassword"
                            className={classes.input}
                            variant="outlined"
                            label="Old Password"
                            fullWidth
                            value={postData.oldpassword}
                            onChange={(e) =>
                                setPostData({
                                    ...postData,
                                    oldpassword: e.target.value,
                                })
                            }
                            type={showPasswordOld ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPasswordOld}>{showPasswordOld === true ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <br />
                        <TextField
                            name="password"
                            id="password"
                            className={classes.input}
                            variant="outlined"
                            label="Password"
                            fullWidth
                            value={postData.password}
                            onChange={(e) =>
                                setPostData({
                                    ...postData,
                                    password: e.target.value,
                                })
                            }
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword}>{showPassword === true ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <br />
                        <TextField
                            name="confirm"
                            id="confirm"
                            className={classes.input}
                            variant="outlined"
                            label="Confirm Password"
                            fullWidth
                            value={postData.confirmPassword}
                            onChange={(e) =>
                                setPostData({
                                    ...postData,
                                    confirmPassword: e.target.value,
                                })
                            }
                            type={showConfirmPassword ? "text" : "password"}
                            {...(errorConfirm && {
                                error: true,
                                helperText: "Confirmation does not match with password",
                            })}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowConfirmPassword}>{showConfirmPassword === true ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <br />
                        <Box textAlign="center">
                            <Button type="submit" fullWidth={isMobile ? true : false} variant="contained" className={classes.submit} onClick={submitPassword}>
                                UPDATE PASSWORD
                            </Button>
                        </Box>
                    </CardBody>
                </Card>
            </GridItem>
            <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
        </GridContainer>
    );
};

export default ChangePassword;
