import { makeStyles } from '@material-ui/core/styles';
import bgImage from '../../assets/img/bg.jpg'


export default makeStyles((theme) => ({

    containercss: {
        backgroundImage: `url(${bgImage})`
    },
    logo: {
        textAlign: 'center',
        marginTop: '40px',        
    },

    invoice: {
        alignItems: 'center',
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: 'none',
        display: 'flex',
        marginTop: '100px'
    },

    cardAgent: {
        backgroundColor: 'white'
    },

    agentName: {
        fontSize: '20px',
        fontWeight: 'bold'
    },

    tablecell: {
        borderBottom: "none"
    },

    textarea: {
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
        borderWidth: 3,
    },
    confirmstyle: {
        alignItems: 'center',
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: 'none',
    },

    form: {
        width: '100%',
        marginTop: theme.spacing(3),
        backgroundColor: 'none'
    },

    tableResponsive: {
        width: "100%",
        marginTop: '3px',
        overflowX: "auto",
        overflow: 'auto',        
      },

    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: 15,
    },
    textfield: {
        color: 'black',
        borderRadius: 5,
        borderWidth: 3,
        borderColor: 'green',        
        backgroundColor: 'white',        
    },

    select: {
        backgroundColor: 'white',
        fontWeight: 'bold',
        fontSize: "16px",
        borderRadius: 5,
        borderWidth: 3,
        width:"120px",
    },

    texto: {
        color: 'black',
        fontWeight: 'normal'
    },
    textopayment: {
        color: '#555555',        
        fontSize: "22px",
    },
    bold: {
        fontWeight: 'bold',
        fontSize: "22px",
    },
    cellcenter: {
        alignItems: 'center',
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: 'none',
    },
    cellright: {
        textAlign: 'right',
    },
    cellleft: {
        textAlign: 'left',
    },
    celllefttop: {
        padding: '0 0 0 10px',
        textAlign: 'left',
        borderBottom: 'none'
    },
    cellrighttop: {
        textAlign: 'right',
        borderBottom: 'none'
    },
    cellright2: {
        width:'65%'
    },
    containRight: {
        alignItems: 'right',
        textAlign: 'right',
        alignContent: 'right',
        backgroundColor: 'none',
    },
    textoln: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: "16px",
    },        
    textotxid: {
        color: 'orange',
        fontWeight: 'bold',
        textAlign: 'Left'
    },
    textoDeposit: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: "32px"
    },

    submit: {
        backgroundColor: '#034AFF',
        color: 'white',
        fontWeight: 'bold',
        textTransform: 'none',
        "&:hover":{
            backgroundColor: '#03b7ff',
            color: 'white'
        }
    },
    alert: {
        marginTop: "15px"
    }

}));
