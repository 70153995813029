import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

//Core
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import { IconButton, InputAdornment, TableContainer, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Styles
import styles from "../../assets/styles/components/agentsListStyle";

//Functions
import { getPlayerWithdrawals } from "../../actions/agent";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";

const useStyles = makeStyles(styles);

const options = [
    {
        status: "all",
        description: "All",
    },
    {
        status: "processed",
        description: "Accepted",
    },
    {
        status: "declined",
        description: "Rejected",
    },
    {
        status: "cancel",
        description: "Canceled",
    },
    {
        status: "expired",
        description: "Expired",
    },
];

const optionsCrypto = [
    {
        crypto: "all",
        description: "All",
    },
    {
        crypto: "BTC",
        description: "BTC",
    },
    {
        crypto: "LN",
        description: "Lightning",
    },
    {
        crypto: "ETH",
        description: "ETH",
    },
    {
        crypto: "USDT",
        description: "USDT",
    },
    {
        crypto: "USDC",
        description: "USDC",
    },
];

const PlayerWithdrawals = (props) => {
    const classes = useStyles();
    const { agentId, merchant } = props;
    const dispatch = useDispatch();
    const [totals, setTotals] = useState({});
    const now = moment();
    const [status, setStatus] = useState("all");
    const [search, setSearch] = useState("");
    const [cryptoCurrency, setCryptoCurrency] = useState("all");
    const token = useSelector((state) => state?.login?.loginData?.token);

    let subTotUSD = 0;
    let subTotFees = 0;
    let subTotTotal = 0;

    let start = 0;
    let startDate = now.startOf("day");

    const [length, setLength] = useState(10);
    const [page, setPage] = useState(0);
    const [withdrawalsList, setWithdrawalsList] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);

    const handleCopy = (trid) => {
        navigator.clipboard.writeText(trid);
    };

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getPlayerWithdrawals(agentId, merchant, start, length, startDate, search, status, cryptoCurrency, token, (res) => {
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchChange = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        dispatch(
            getPlayerWithdrawals(agentId, merchant, start, length, startDate, e.target.value, status, cryptoCurrency, token, (res) => {
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const onChangeStatus = (e, v) => {
        setStatus(v.status);
        e.preventDefault();
        dispatch(
            getPlayerWithdrawals(agentId, merchant, start, length, startDate, search, v.status, cryptoCurrency, token, (res) => {
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const onChangeStatusCrypto = (e, v) => {
        setCryptoCurrency(v.crypto);
        e.preventDefault();
        dispatch(
            getPlayerWithdrawals(agentId, merchant, start, length, startDate, search, status, v.crypto, token, (res) => {
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const tableHead = ["Date", "Customer", "Agent", "Status", "Type", "Crypto Amount", "Exchange", "$USD", "Fees", "Total", "Transaction Id"];

    useEffect(() => {
        dispatch(
            getPlayerWithdrawals(agentId, merchant, start, length, startDate, search, status, cryptoCurrency, token, (res) => {
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    }, [start, length, dispatch]);

    if (withdrawalsList) {
        if (withdrawalsList.length === 0) {
            return (
                <TableContainer>
                    <Table className={classes.tableResponsive}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={1} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                                    <TextField
                                        label="Search"
                                        style={{ width: 200 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={searchChange}
                                    />
                                </TableCell>
                                <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"status"}>
                                    <Autocomplete id="status" style={{ width: 125 }} options={options} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Status" margin="normal" />} onChange={onChangeStatus} />
                                </TableCell>
                                <TableCell colSpan={8} className={classes.tableCell + " " + classes.tableHeadCell} key={"cryptoCurrency"}>
                                    <Autocomplete id="crypto" style={{ width: 125 }} options={optionsCrypto} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Crypto" margin="normal" />} onChange={onChangeStatusCrypto} />
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.tableHeadRow}>
                                <TableCell colSpan={11} key={"norecords1"}>
                                    <div style={{ textAlign: "center" }}>No records found</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            );
        } else {
            return (
                <TableContainer>
                    <Table className={classes.tableResponsive}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={1} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                                    <TextField
                                        label="Search"
                                        style={{ width: 200 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={searchChange}
                                    />
                                </TableCell>
                                <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"status"}>
                                    <Autocomplete id="status" style={{ width: 125 }} options={options} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Status" margin="normal" />} onChange={onChangeStatus} />
                                </TableCell>
                                <TableCell colSpan={9} className={classes.tableCell + " " + classes.tableHeadCell} key={"cryptoCurrency"}>
                                    <Autocomplete id="crypto" style={{ width: 125 }} options={optionsCrypto} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Crypto" margin="normal" />} onChange={onChangeStatusCrypto} />
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.tableHeadRow}>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {withdrawalsList.map((withdrawal, index) => {
                                subTotUSD += withdrawal.currencyAmount;
                                subTotFees += parseFloat(withdrawal.feeMerchantAmount + withdrawal.feeAmountChain);
                                subTotTotal += withdrawal.isLN === true ? parseFloat(withdrawal.currencyAmount + (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain)) : parseFloat(withdrawal.currencyAmount - (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain));
                                return (
                                    <TableRow key={index} className={classes.tableRow}>
                                        <TableCell className={classes.tableCell}>{moment(withdrawal.createdAt).format("MM/DD/YYYY hh:mm")}</TableCell>
                                        <TableCell className={classes.tableCell}>{withdrawal.customer}</TableCell>
                                        <TableCell className={classes.tableCell}>{withdrawal.agent}</TableCell>
                                        <TableCell className={classes.tableCell}>{withdrawal.payoutStatus}</TableCell>
                                        <TableCell className={classes.tableCell}>{withdrawal.isLN === true && withdrawal.cryptoCurrency === "BTC" ? "Lightning" : withdrawal.isLN === false && withdrawal.cryptoCurrency === "BTC" ? "BTC Mainnet" : withdrawal.cryptoCurrency}</TableCell>
                                        <TableCell className={classes.tableCell}>{`${parseFloat(withdrawal.cryptoAmount).toFixed(withdrawal.cryptoCurrency === "BTC" ? 8 : withdrawal.cryptoCurrency === "ETH" ? 18 : withdrawal.cryptoCurrency === "USDT" ? 6 : withdrawal.cryptoCurrency === "USDC" ? 6 : 2)}`}</TableCell>
                                        <TableCell className={classes.tableCell}>{`$${withdrawal.exchangeRate}`}</TableCell>
                                        <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.currencyAmount).toFixed(2)}`}</TableCell>
                                        <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.feeMerchantAmount + withdrawal.feeAmountChain).toFixed(2)}`}</TableCell>
                                        <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.isLN === true ? withdrawal.currencyAmount + withdrawal.feeMerchantAmount + withdrawal.feeAmountChain : withdrawal.currencyAmount - (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain)).toFixed(2)}`}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {`${withdrawal.txid_hash ? withdrawal.txid_hash.toString().substring(0, 6) + "..." : "N/A"}`}
                                            <IconButton aria-label="delete" onClick={() => handleCopy(withdrawal.txid_hash)}>
                                                <CopyIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow key={"subTotals"} className={classes.tableRow}>
                                <TableCell className={classes.tableCellSubTotal}>{"SubTotal"}</TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                                <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotUSD).toFixed(2)}`}</TableCell>
                                <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotFees).toFixed(2)}`}</TableCell>
                                <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotTotal).toFixed(2)}`}</TableCell>
                                <TableCell className={classes.tableCell}></TableCell>
                            </TableRow>
                            {!isNaN(totals.mainTotalBTC) ? (
                                <>
                                    <TableRow key={"grandTotal"} className={classes.tableRow}>
                                        <TableCell className={classes.tableCellGrandotal}>{"Grand Total"}</TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                        <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalUSD).toFixed(2)}`}</TableCell>
                                        <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalFees).toFixed(2)}`}</TableCell>
                                        <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalTotal).toFixed(2)}`}</TableCell>
                                        <TableCell className={classes.tableCell}></TableCell>
                                    </TableRow>
                                </>
                            ) : null}
                        </TableBody>
                    </Table>
                    <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            );
        }
    }
};

export default PlayerWithdrawals;
