import Dashboard from "@material-ui/icons/Dashboard";
import PeopleIcon from '@material-ui/icons/People';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import WorkIcon from '@material-ui/icons/Work';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SecurityIcon from '@material-ui/icons/Security';
import LockOpenIcon from '@material-ui/icons/LockOpen';

// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard";
import Deposits from "../src/components/Deposits/Deposits";
import Withdrawals from "../src/components/Withdrawals/Withdrawals";
import PlayerWithdrawals from "../src/components/Withdrawals/PlayerWithdrawals";
import RequestWithdrawals from "../src/components/Withdrawals/RequestWithdrawals";
import Players from "../src/components/Customers/CustomersList";
import Messages from "./views/Messages/Messages";
import Agents from '../src/components/Agents/Agents';
import Settings from '../src/components/Settings/Settings';
import ChangePassword from '../src/components/Settings/ChangePassword';
import Security from '../src/components/Security/Security';
/*import CashHistory from "./views/CashHistory/CashHistory";
import Agents from "./views/Agents/Agents";
import Players from "./views/Players/Players";
import Cashier from "./views/Cashier/Cashier";
import Messages from "./views/Messages/Messages";*/


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    showOnSideBar:true,
    showForAdmins: true,
    showForAgents: true,
    showForMasters:true,
  }, 
  {
    path: "/deposits",
    name: "Deposits",
    rtlName: "لوحة القيادة",
    icon: MonetizationOnIcon,
    component: Deposits,
    layout: "/admin",
    showOnSideBar:true,
    showForAdmins: true,
    showForAgents: true,
    showForMasters:true,
  },
  {
    path: "/requestwithdrawal",
    name: "Withdrawals",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceWalletIcon,
    component: RequestWithdrawals,
    layout: "/admin",
    showOnSideBar:true,
    showForAdmins: false,
    showForAgents: true,
    showForMasters:false,
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "لوحة القيادة",
    icon: PeopleIcon,
    component: Players,
    layout: "/admin",
    showOnSideBar:true,
    showForAdmins: true,
    showForAgents: true,
    showForMasters:true,
  },
  {
    path: "/messages",
    name: "Messages",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceIcon,
    component: Messages,
    layout: "/admin",
    showOnSideBar:false,
    showForAdmins: true,
    showForAgents: true,
    showForMasters:true,
  },
  {
    path: "/agents",
    name: "Agents",
    rtlName: "لوحة القيادة",
    icon: AssignmentIndIcon,
    component: Agents,
    layout: "/admin",
    showOnSideBar:true,
    showForAdmins: true,
    showForAgents: false,
    showForMasters:true,
  },
  {
    path: "/withdrawals",
    name: "Agent Withdrawals",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceWalletIcon,
    component: Withdrawals,
    layout: "/admin",
    showOnSideBar:true,
    showForAdmins: true,
    showForAgents: true,
    showForMasters:true,
  },
  {
    path: "/playerwithdrawals",
    name: "Player Withdrawals",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceWalletIcon,
    component: PlayerWithdrawals,
    layout: "/admin",
    showOnSideBar:true,
    showForAdmins: true,
    showForAgents: false,
    showForMasters:false,
  },
  {
    path: "/settings",
    name: "Agent Settings",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceWalletIcon,
    component: Settings,
    layout: "/admin",
    showOnSideBar:false,
    showForAdmins: false,
    showForAgents: false,
    showForMasters:false,
  },
  {
    path: "/password",
    name: "Change Password",
    rtlName: "لوحة القيادة",
    icon: LockOpenIcon,
    component: ChangePassword,
    layout: "/admin",
    showOnSideBar:false,
    showForAdmins: false,
    showForAgents: false,
    showForMasters:false,
  },
  {
    path: "/security",
    name: "Agent Security",
    rtlName: "لوحة القيادة",
    icon: SecurityIcon,
    component: Security,
    layout: "/admin",
    showOnSideBar:false,
    showForAdmins: false,
    showForAgents: false,
    showForMasters:false,
  },
  /*
  {
    path: "/cashhistory",
    name: "Cash History",
    rtlName: "لوحة القيادة",
    icon: MonetizationOnIcon,
    component: CashHistory,
    layout: "/admin",
    showOnSideBar:true
  },
  {
    path: "/agents",
    name: "Agents",
    rtlName: "لوحة القيادة",
    icon: WorkIcon,
    component: Agents,
    layout: "/admin",
    showOnSideBar:true
  },
  {
    path: "/players",
    name: "Players",
    rtlName: "لوحة القيادة",
    icon: PeopleIcon,
    component: Players,
    layout: "/admin",
    showOnSideBar:true
  },
  {
    path: "/cashier",
    name: "Cashier",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceIcon,
    component: Cashier,
    layout: "/admin",
    showOnSideBar:true
  }, 
  {
    path: "/messages",
    name: "Messages",
    rtlName: "لوحة القيادة",
    icon: AccountBalanceIcon,
    component: Messages,
    layout: "/admin",
    showOnSideBar:false
  },*/
];

export default dashboardRoutes;