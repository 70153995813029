import React,{useEffect} from 'react'
//Core
import ChartistGraph from "react-chartist";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
//Core Components
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
//Functions
import {getMonthlyData} from '../../ChartsData/MonthlyRake';
import {monthDeposits} from '../../actions/agent';

//Styles
import styles from '../../assets/styles/views/dashboardStyle';

const useStyles = makeStyles(styles);

const MonthDeposits = (props) => {
  const {agentId} = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const figuresByMonth = useSelector((state) => state.agent.monthlyDeposits); 

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  let monthlyRake = {data: {
    labels: ["Jan","Feb","Mar","Apr","Mai","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
  }};

  let mth1=0;
  let mth2=0;
  let mth3=0;
  let mth4=0;
  let mth5=0;
  let mth6=0;
  let mth7=0;
  let mth8=0;
  let mth9=0;
  let mth10=0;
  let mth11=0;
  let mth12=0;

  if(figuresByMonth?.data){
    figuresByMonth.data.forEach( row => {
      if(row._id.month === 1){
        mth1=row.total;
      } else if(row._id.month === 2){
        mth2=row.total;
      } else if(row._id.month === 3){
        mth3=row.total;
      } else if(row._id.month === 4){
        mth4=row.total;
      } else if(row._id.month === 5){
        mth5=row.total;
      } else if(row._id.month === 6){
        mth6=row.total;
      } else if(row._id.month === 7){
        mth7=row.total;
      } else if(row._id.month === 8){
        mth8=row.total;
      } else if(row._id.month === 9){
        mth9=row.total;
      } else if(row._id.month === 10){
        mth10=row.total;
      } else if(row._id.month === 11){
        mth11=row.total;
      } else if(row._id.month === 12){
        mth12=row.total;
      }
    });
  }

  if(figuresByMonth?.data){  
    let arr =  [mth1,mth2,mth3,mth4,mth5,mth6,mth7,mth8,mth9,mth10,mth11,mth12];
    let min = Math.min.apply(null, arr);  
    let max = Math.max.apply(null, arr);   
    monthlyRake = getMonthlyData(arr,min,max);
  }

  useEffect(() => {
    dispatch(monthDeposits(tz,agentId));      
  }, [dispatch])

  return (
    <Card chart>
      <CardHeader color="warning">
        <ChartistGraph
          className="ct-chart"
          data={monthlyRake?.data}
          type="Bar"
          options={monthlyRake?.options}
          responsiveOptions={monthlyRake.responsiveOptions}
          listener={monthlyRake?.animation}
        />
      </CardHeader>
      <CardBody>
        <h4 className={classes.cardTitle}>Monthly Deposits</h4>
      </CardBody>            
    </Card>
  )
}

export default MonthDeposits
