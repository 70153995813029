import Immutable from "seamless-immutable";
import { AGENT_INFO, GET_DEPOSITS, GET_WITHDRAWALS, UPDATE_AGENT, MONTHLY_DEPOSITS, WEEKLY_DEPOSITS, HOURLY_DEPOSITS, GET_CUSTOMERS, UPDATE_CUSTOMER, GET_ALLAGENTS } from "../constants/actionTypes";

export default (
    state = Immutable({
        balance: 0,
        exchange: 0,
        agentData: null,
        customers: [],
        totalCustomers: 0,
        deposits: [],
        totalDeposits: 0,
        withdrawals: [],
        totalWithdrawals: 0,
        weekDeposits: null,
        isAdmin: false,
        syncStatus: false,
        monthlyDeposits: null,
        hourlyDeposits: null,
        allAgents: [],
        totalAgents: 0,
        hasSubs: false,
        balanceBTC: 0,
        balanceETH: 0,
        balanceUSDT: 0,
        balanceUSDTtron: 0,
        balanceUSDC: 0,
        walletBalanceETH: 0,
        walletBalanceUSDT: 0,
        walletBalanceUSDTtron: 0,
        walletBalanceUSDC: 0,
    }),
    action
) => {
    switch (action.type) {
        case AGENT_INFO:
            return {
                ...state,
                agentData: action?.data,
                balance: action?.data?.data?.balance,
                channelBalanceBTC: action?.data?.data?.channelBalanceBTC,
                channelBalanceUSD: action?.data?.data?.channelBalanceUSD,
                walletBalanceBTC: action?.data?.data?.walletBalanceBTC,
                walletBalanceUSD: action?.data?.data?.walletBalanceUSD,
                exchange: action?.data?.exchange,
                exchangeETH: action?.data?.exchangeETH,
                exchangeUSDT: action?.data?.exchangeUSDT,
                exchangeUSDC: action?.data?.exchangeUSDC,
                hasSubs: action?.data?.hasSubs,
                isAdmin: action?.data?.isAdmin,
                syncStatus: action?.data?.syncStatus,
                balanceBTC: action?.data?.data?.balanceBTC,
                balanceETH: action?.data?.data?.balanceETH,
                balanceUSDT: action?.data?.data?.balanceUSDT,
                balanceUSDTTRON: action?.data?.data?.balanceUSDTTRON,
                balanceUSDC: action?.data?.data?.balanceUSDC,
                walletBalanceETH: action?.data?.data?.walletBalanceETH,
                walletBalanceUSDT: action?.data?.data?.walletBalanceUSDT,
                walletBalanceUSDTTRON: action?.data?.data?.walletBalanceUSDTTRON,
                walletBalanceUSDC: action?.data?.data?.walletBalanceUSDC,
                freePlayETH: action?.data?.data?.freePlayETH,
                freePlayETHPercentage: action?.data?.data?.freePlayETHPercentage,
                freePlayUSDT: action?.data?.data?.freePlayUSDT,
                freePlayUSDTPercentage: action?.data?.data?.freePlayUSDTPercentage,
                freePlayUSDC: action?.data?.data?.freePlayUSDC,
                freePlayUSDCPercentage: action?.data?.data?.freePlayUSDCPercentage,
                depositBTC: action?.data?.data?.depositBTC,
                depositLN: action?.data?.data?.depositLN,
                depositETH: action?.data?.data?.depositETH,
                depositUSDT: action?.data?.data?.depositUSDT,
                depositUSDC: action?.data?.data?.depositUSDC,
                withdrawBTC: action?.data?.data?.withdrawBTC,
                withdrawLN: action?.data?.data?.withdrawLN,
                withdrawETH: action?.data?.data?.withdrawETH,
                withdrawUSDT: action?.data?.data?.withdrawUSDT,
                withdrawUSDC: action?.data?.data?.withdrawUSDC,
                balanceAdminETH: action?.data?.data?.balanceAdminETH,
                balanceAdminUSDT: action?.data?.data?.balanceAdminUSDT,
                balanceAdminUSDTTRON: action?.data?.data?.balanceAdminUSDTTRON,
                balanceAdminUSDC: action?.data?.data?.balanceAdminUSDC,
                pendingsBTC: action?.data?.data?.pendingsBTC,
                pendingsPercentageBTC: action?.data?.data?.pendingsPercentageBTC,
                pendingsETH: action?.data?.data?.pendingsETH,
                pendingsPercentageETH: action?.data?.data?.pendingsPercentageETH,
                pendingsUSDT: action?.data?.data?.pendingsUSDT,
                pendingsUSDTTRON: action?.data?.data?.pendingsUSDTTRON,
                pendingsPercentageUSDT: action?.data?.data?.pendingsPercentageUSDT,
                pendingsUSDC: action?.data?.data?.pendingsUSDC,
                pendingsPercentageUSDC: action?.data?.data?.pendingsPercentageUSDC,
                availableToReceiveLN: action?.data?.data?.availableToReceiveLN,
            };
        case GET_DEPOSITS:
            return { ...state, deposits: action?.data?.data, totalDeposits: action?.data?.count };
        case GET_CUSTOMERS:
            return { ...state, customers: action?.data?.data, totalCustomers: action?.data?.count };
        case GET_ALLAGENTS:
            return { ...state, allAgents: action?.data?.data, totalAgents: action?.data?.count };
        case UPDATE_CUSTOMER:
            return { ...state, customers: action?.data?.data, totalCustomers: action?.data?.count };
        case GET_WITHDRAWALS:
            return { ...state, withdrawals: action?.data?.data, totalWithdrawals: action?.data?.data?.length };
        case UPDATE_AGENT:
            return { ...state, balance: action?.data?.data?.balance, exchange: action?.data?.data?.exchange };
        case WEEKLY_DEPOSITS:
            return { ...state, weekDeposits: action?.data };
        case MONTHLY_DEPOSITS:
            return { ...state, monthlyDeposits: action?.data };
        case HOURLY_DEPOSITS:
            return { ...state, hourlyDeposits: action?.data };
        default:
            return state;
    }
};
