import React, { useState, useEffect } from "react";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import UseStyles from "./styles";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getAgentInfoSettings, updateAgentSettings } from "../../actions/agent";
import { useDispatch } from "react-redux";
import OkDialog from "../Dialogs/OkDialog";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../constants/actionTypes";

const Settings = () => {
    let title = "Settings";
    const classes = UseStyles();
    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorConfirm, setErrorConfirm] = useState(false);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const dispatch = useDispatch();
    const history = useHistory();
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    const token = useSelector((state) => state?.login?.loginData?.token);
    const [postData, setPostData] = useState({
        agentId: agentId,
        merchant: merchant,
        oldpassword: "",
        password: "",
        username: "",
        confirmPassword: "",
        freeplaybtc: false,
        freeplaybtcpercentage: 0,
        freeplayln: false,
        freeplaylnpercentage: 0,
        telegram: 0,
        applydepositfee: false,
        freeplayeth: false,
        freeplayethpercentage: 0,
        freeplayusdt: false,
        freeplayusdtpercentage: 0,
        freeplayusdc: false,
        freeplayusdcpercentage: 0,
        depositbtc: false,
        depositln: false,
        depositeth: false,
        depositusdt: false,
        depositusdc: false,
        withdrawbtc: false,
        withdrawln: false,
        withdraweth: false,
        withdrawusdt: false,
        withdrawusdc: false,
    });

    const handleShowPasswordOld = () => {
        setShowPasswordOld((prevShowPassword) => !prevShowPassword);
    };
    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
    };

    const validate = () => {
        let returnValidate = true;
        const password = document.getElementById("password").value;
        const confirm = document.getElementById("confirm").value;
        if (password === confirm) {
            setErrorConfirm(false);
        } else {
            setErrorConfirm(true);
            returnValidate = false;
        }
        return returnValidate;
    };

    const submitInfo = (e) => {
        e.preventDefault();
        e.currentTarget.disabled = true;
        dispatch(
            updateAgentSettings(postData, false, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (!res.error) {
                    setOkDialog({
                        isOpen: true,
                        title: "Settings Updated",
                        subTitle: res?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                } else {
                    setOkDialog({
                        isOpen: true,
                        title: "Error on update",
                        subTitle: res?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                }
                e.currentTarget.disabled = false;
            })
        );
    };

    const submitPassword = (e) => {
        e.preventDefault();
        if (validate() === true) {
            e.currentTarget.disabled = true;
            dispatch(
                updateAgentSettings(postData, true, token, (res) => {
                    if (res.error) {
                        if (res.message === "Token Expire") {
                            dispatch({ type: LOGIN, res });
                            history.push("/");
                        }
                    }
                    if (!res.error) {
                        setPostData({
                            ...postData,
                            oldpassword: "",
                            password: "",
                            confirmPassword: "",
                        });
                        document.getElementById("password").textContent = "";
                        document.getElementById("confirm").textContent = "";
                        document.getElementById("oldpassword").textContent = "";
                        setOkDialog({
                            isOpen: true,
                            title: "Settings Updated",
                            subTitle: res?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    } else {
                        setOkDialog({
                            isOpen: true,
                            title: "Error on update",
                            subTitle: res?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                    e.currentTarget.disabled = false;
                })
            );
        } else {
            setOkDialog({
                isOpen: true,
                title: "Error on change password",
                subTitle: "Confirmation password does not match",
                onConfirm: () => {
                    setOkDialog({
                        ...okDialog,
                        isOpen: false,
                    });
                },
            });
        }
    };

    useEffect(() => {
        document.body.style.cursor = "wait";
        dispatch(
            getAgentInfoSettings(agentId, merchant, token, (data) => {
                document.body.style.cursor = "default";
                if (data.error) {
                    if (data.message === "Token Expire") {
                        dispatch({ type: LOGIN, data });
                        history.push("/");
                    }
                }
                if (data) {
                    setPostData({
                        ...postData,
                        username: data.data.username,
                        freeplaybtc: data.data.freePlayBTC,
                        freeplaybtcpercentage: data.data.freePlayBTCPercentage,
                        freeplayln: data.data.freePlayLN,
                        freeplaylnpercentage: data.data.freePlayLNPercentage,
                        telegram: data.data.telegram,
                        applydepositfee: data.data.applyDepositFee,
                        freeplayeth: data.data.freePlayETH,
                        freeplayethpercentage: data.data.freePlayETHPercentage,
                        freeplayusdt: data.data.freePlayUSDT,
                        freeplayusdtpercentage: data.data.freePlayUSDTPercentage,
                        freeplayusdc: data.data.freePlayUSDC,
                        freeplayusdcpercentage: data.data.freePlayUSDCPercentage,
                        depositbtc: data.data.depositBTC,
                        depositln: data.data.depositLN,
                        depositeth: data.data.depositETH,
                        depositusdt: data.data.depositUSDT,
                        depositusdc: data.data.depositUSDC,
                        withdrawbtc: data.data.withdrawBTC,
                        withdrawln: data.data.withdrawLN,
                        withdraweth: data.data.withdrawETH,
                        withdrawusdt: data.data.withdrawUSDT,
                        withdrawusdc: data.data.withdrawUSDC,
                    });
                }
            })
        );
    }, [dispatch]);

    let isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={12}>
                <Card style={{ borderRadius: "15px" }}>
                    <CardHeader color="info" style={{ borderRadius: "15px" }}>
                        <Typography className={classes.agentName}>{title}</Typography>
                    </CardHeader>
                    <CardBody>
                        <Table className={classes.tableResponsive}>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2} className={classes.cellleft}>
                                        <TextField
                                            name="username"
                                            id="username"
                                            className={classes.input}
                                            variant="outlined"
                                            label="Username"
                                            value={postData.username || ""}
                                            onChange={(e) =>
                                                setPostData({
                                                    ...postData,
                                                    freeplaybtcpercentage: e.target.value,
                                                })
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} className={classes.cellleft}>
                                        <TextField
                                            name="telegram"
                                            id="telegram"
                                            className={classes.input}
                                            variant="outlined"
                                            label="Telegram ID"
                                            value={postData.telegram || "0"}
                                            onChange={(e) =>
                                                setPostData({
                                                    ...postData,
                                                    telegram: e.target.value,
                                                })
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} className={classes.cellleft}>
                                        <Typography style={{ fontWeight: "bold" }}>Deposits</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} className={classes.cellleft}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.depositbtc || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            depositbtc: e.target.checked,
                                                        })
                                                    }
                                                    name="depositbtc"
                                                    color="primary"
                                                />
                                            }
                                            label="BTC"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.depositln || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            depositln: e.target.checked,
                                                        })
                                                    }
                                                    name="depositln"
                                                    color="primary"
                                                />
                                            }
                                            label="Lightning Network"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.depositeth || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            depositeth: e.target.checked,
                                                        })
                                                    }
                                                    name="depositeth"
                                                    color="primary"
                                                />
                                            }
                                            label="ETH"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.depositusdt || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            depositusdt: e.target.checked,
                                                        })
                                                    }
                                                    name="depositusdt"
                                                    color="primary"
                                                />
                                            }
                                            label="USDT"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.depositusdc || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            depositusdc: e.target.checked,
                                                        })
                                                    }
                                                    name="depositusdc"
                                                    color="primary"
                                                />
                                            }
                                            label="USDC"
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={2} className={classes.cellleft}>
                                        <Typography style={{ fontWeight: "bold" }}>Withdrawal</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} className={classes.cellleftborderbottom}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.withdrawbtc || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            withdrawbtc: e.target.checked,
                                                        })
                                                    }
                                                    name="withdrawbtc"
                                                    color="primary"
                                                />
                                            }
                                            label="BTC"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.withdrawln || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            withdrawln: e.target.checked,
                                                        })
                                                    }
                                                    name="withdrawln"
                                                    color="primary"
                                                />
                                            }
                                            label="Lightning Network"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.withdraweth || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            withdraweth: e.target.checked,
                                                        })
                                                    }
                                                    name="withdraweth"
                                                    color="primary"
                                                />
                                            }
                                            label="ETH"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.withdrawusdt || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            withdrawusdt: e.target.checked,
                                                        })
                                                    }
                                                    name="withdrawusdt"
                                                    color="primary"
                                                />
                                            }
                                            label="USDT"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.withdrawusdc || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            withdrawusdc: e.target.checked,
                                                        })
                                                    }
                                                    name="withdrawusdc"
                                                    color="primary"
                                                />
                                            }
                                            label="USDC"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table className={classes.tablemargin}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cellleftmargin}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.freeplaybtc || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            freeplaybtc: e.target.checked,
                                                        })
                                                    }
                                                    name="freeplaybtc"
                                                    color="primary"
                                                />
                                            }
                                            label="Free Play Regular Bitcoin Deposit"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.cellleft2}>
                                        <TextField
                                            name="freeplaybtcpercentage"
                                            id="freeplaybtcpercentage"
                                            className={classes.input}
                                            variant="outlined"
                                            label="Free Play BTC Percentage"
                                            value={postData.freeplaybtcpercentage || "0"}
                                            onChange={(e) =>
                                                setPostData({
                                                    ...postData,
                                                    freeplaybtcpercentage: e.target.value,
                                                })
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cellleft}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.freeplayln || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            freeplayln: e.target.checked,
                                                        })
                                                    }
                                                    name="freeplayln"
                                                    color="primary"
                                                />
                                            }
                                            label="Free Play Lightning Network Deposit"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.cellleft2}>
                                        <TextField
                                            name="freeplaylnpercentage"
                                            id="freeplaylnpercentage"
                                            className={classes.input}
                                            variant="outlined"
                                            label="Free Play LN Percentage"
                                            value={postData.freeplaylnpercentage || "0"}
                                            onChange={(e) =>
                                                setPostData({
                                                    ...postData,
                                                    freeplaylnpercentage: e.target.value,
                                                })
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cellleft}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.freeplayeth || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            freeplayeth: e.target.checked,
                                                        })
                                                    }
                                                    name="freeplayeth"
                                                    color="primary"
                                                />
                                            }
                                            label="Free Play Ethereum Deposit"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.cellleft2}>
                                        <TextField
                                            name="freeplayethpercentage"
                                            id="freeplayethpercentage"
                                            className={classes.input}
                                            variant="outlined"
                                            label="Free Play Ethereum Percentage"
                                            value={postData.freeplayethpercentage || "0"}
                                            onChange={(e) =>
                                                setPostData({
                                                    ...postData,
                                                    freeplayethpercentage: e.target.value,
                                                })
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cellleft}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.freeplayusdt || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            freeplayusdt: e.target.checked,
                                                        })
                                                    }
                                                    name="freeplayusdt"
                                                    color="primary"
                                                />
                                            }
                                            label="Free Play USDT Deposit"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.cellleft2}>
                                        <TextField
                                            name="freeplayusdtpercentage"
                                            id="freeplayusdtpercentage"
                                            className={classes.input}
                                            variant="outlined"
                                            label="Free Play USDT Percentage"
                                            value={postData.freeplayusdtpercentage || "0"}
                                            onChange={(e) =>
                                                setPostData({
                                                    ...postData,
                                                    freeplayusdtpercentage: e.target.value,
                                                })
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cellleft}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={postData.freeplayusdc || false}
                                                    onChange={(e) =>
                                                        setPostData({
                                                            ...postData,
                                                            freeplayusdc: e.target.checked,
                                                        })
                                                    }
                                                    name="freeplayusdc"
                                                    color="primary"
                                                />
                                            }
                                            label="Free Play USDC Deposit"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.cellleft2}>
                                        <TextField
                                            name="freeplayusdcpercentage"
                                            id="freeplayusdcpercentage"
                                            className={classes.input}
                                            variant="outlined"
                                            label="Free Play USDC Percentage"
                                            value={postData.freeplayusdcpercentage || "0"}
                                            onChange={(e) =>
                                                setPostData({
                                                    ...postData,
                                                    freeplayusdcpercentage: e.target.value,
                                                })
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <br />
                        <br />
                        <Box textAlign="center">
                            <Button type="submit" fullWidth={isMobile ? true : false} variant="contained" className={classes.submit} onClick={submitInfo}>
                                UPDATE INFO
                            </Button>
                        </Box>
                    </CardBody>
                </Card>
            </GridItem>
            <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
        </GridContainer>
    );
};

export default Settings;
