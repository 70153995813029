import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'
import Logo from "../../assets/img/logo75x300.png";
import BG from "../../assets/img/bg2.png";


const useStyles = makeStyles(theme => ({
  dialog: {
      padding: theme.spacing(2),
      position: 'absolute',
      top: theme.spacing(5)
  },
  dialogTitle: {
      textAlign: 'center',
      backgroundImage: `url(${BG})`,
      backgroundPosition: 'center', 
      backgroundSize: 'cover', 
      backgroundRepeat: 'no-repeat',
  },
  dialogContent: {
      textAlign: 'center'
  },
  dialogAction: {
      justifyContent: 'center'
  },
  titleIcon: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      '&:hover': {
          backgroundColor: theme.palette.secondary.light,
          cursor: 'default'
      },
      '& .MuiSvgIcon-root': {
          fontSize: '8rem',
      }
  }
}))

export default function ConfirmDialog(props) {

  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles()

  return (
      <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
          <DialogTitle className={classes.dialogTitle}>
            <div>
                <img src={Logo} width="150px" height="75px"/>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
              <Typography variant="h6">
                  {confirmDialog.title}
              </Typography>
              <Typography variant="subtitle2">
                  {confirmDialog.subTitle}
              </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
              <Button
                  color="default"
                  onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>
                    No
                </Button>
              <Button
                  color="secondary"
                  onClick={confirmDialog.onConfirm}>
                    Yes
                </Button>
          </DialogActions>
      </Dialog>
  )
}