import * as api from "../api/withdrawal";

export const requestAgentWithdrawalBTC = (agentid, invoice, amount, merchant, twoFactorAuth, twoFactorPin, token, callback) => async (dispatch) => {
    try {
        let { data } = await api.requestAgentWithdrawalBTC(agentid, invoice, amount, merchant, twoFactorAuth, twoFactorPin, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const getPreviousRequestAgentWithdrawalBTC = (agentid, merchant, token, callback) => async (dispatch) => {
    try {
        let { data } = await api.getPreviousRequestAgentWithdrawalBTC(agentid, merchant, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const requestAgentWithdrawalETH = (agentid, address, amount, merchant, cryptoCurrency, token, callback) => async (dispatch) => {
    try {
        let { data } = await api.requestAgentWithdrawalETH(agentid, address, amount, merchant, cryptoCurrency, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const requestAgentWithdrawalUSDTTRON = async (agentid, address, amount, merchant, token, callback) => {
    try {
        let { data } = await api.requestAgentWithdrawalUSDTTRON(agentid, address, amount, merchant, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const responseWithdrawalBTC = (withdrawalId, response, token, callback) => async (dispatch) => {
    try {
        let { data } = await api.responseWithdrawalBTC(withdrawalId, response, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const responseWithdrawalAgentETH = (withdrawalId, response, token, callback) => async (dispatch) => {
    try {
        let { data } = await api.responseWithdrawalAgentETH(withdrawalId, response, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const responseWithdrawalAgentUSDTTRON = async (withdrawalId, response, token, callback) => {
    try {
        let { data } = await api.responseWithdrawalAgentUSDTTRON(withdrawalId, response, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const requestPreviousWithdrawalAgentBTC = (agentId, merchant, callback) => async (dispatch) => {
    try {
        let { data } = await api.requestPreviousWithdrawalAgentBTC(agentId, merchant);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};
