import React, { useEffect, useState } from "react";
//Core
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
//Core Components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody.js";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.js";

import { Box, Typography } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { TableContainer, TableHead } from "@material-ui/core";
import Deposits from "../../components/Deposits/Deposits";
import OkDialog from "../../components/Dialogs/OkDialog";

//Material UI Icons
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { Icon } from "@iconify/react";

//Styles
import styles from "../../assets/styles/views/dashboardStyle";

//Charts
import WeekDepositsChart from "../../components/Charts/WeekDeposits";
import MonthDepositsChart from "../../components/Charts/MonthDeposits";
import HourDepositsChart from "../../components/Charts/HourDeposits";
import { getAgentInfo } from "../../actions/agent";
import CardHeader from "../../components/Card/CardHeader";
import { LOGIN } from "../../constants/actionTypes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

const Dashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    const token = useSelector((state) => state?.login?.loginData?.token);
    //console.log('AGENT ID DASHBOARD', agentId);
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    const balance = useSelector((state) => state.agent?.balance);
    const exchange = useSelector((state) => state.agent?.exchange);
    const exchangeETH = useSelector((state) => state.agent?.exchangeETH);
    const exchangeUSDT = useSelector((state) => state.agent?.exchangeUSDT);
    const exchangeUSDC = useSelector((state) => state.agent?.exchangeUSDC);
    const isAdmin = useSelector((state) => state.agent?.isAdmin);
    const syncStatus = useSelector((state) => state.agent?.syncStatus);
    const lsShow = localStorage.getItem("showMsg");

    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        justify: true,
        title: "",
        subTitle: "",
    });

    const channelBalanceBTC = useSelector((state) => state.agent?.channelBalanceBTC);
    const channelBalanceUSD = useSelector((state) => state.agent?.channelBalanceUSD);
    const walletBalanceBTC = useSelector((state) => state.agent?.walletBalanceBTC);
    const walletBalanceUSD = useSelector((state) => state.agent?.walletBalanceUSD);

    const balanceBTC = useSelector((state) => state.agent?.balanceBTC);
    const balanceETH = useSelector((state) => state.agent?.balanceETH);
    const balanceUSDT = useSelector((state) => state.agent?.balanceUSDT);
    const balanceUSDTTRON = useSelector((state) => state.agent?.balanceUSDTTRON);
    const balanceUSDC = useSelector((state) => state.agent?.balanceUSDC);
    const walletBalanceETH = useSelector((state) => state.agent?.walletBalanceETH);
    const walletBalanceUSDT = useSelector((state) => state.agent?.walletBalanceUSDT);
    const walletBalanceUSDTTRON = useSelector((state) => state.agent?.walletBalanceUSDTTRON);
    const walletBalanceUSDC = useSelector((state) => state.agent?.walletBalanceUSDC);
    const balanceAdminETH = useSelector((state) => state.agent?.balanceAdminETH);
    const balanceAdminUSDT = useSelector((state) => state.agent?.balanceAdminUSDT);
    const balanceAdminUSDTTRON = useSelector((state) => state.agent?.balanceAdminUSDTTRON);
    const balanceAdminUSDC = useSelector((state) => state.agent?.balanceAdminUSDC);

    const pendingsBTC = useSelector((state) => state.agent?.pendingsBTC);
    const pendingsPercentageBTC = useSelector((state) => state.agent?.pendingsPercentageBTC);
    const pendingsETH = useSelector((state) => state.agent?.pendingsETH);
    const pendingsPercentageETH = useSelector((state) => state.agent?.pendingsPercentageETH);
    const pendingsUSDT = useSelector((state) => state.agent?.pendingsUSDT);
    const pendingsPercentageUSDT = useSelector((state) => state.agent?.pendingsPercentageUSDT);
    const pendingsUSDTTRON = useSelector((state) => state.agent?.pendingsUSDTTRON);
    const pendingsPercentageUSDTTRON = useSelector((state) => state.agent?.pendingsPercentageUSDTTRON);
    const pendingsUSDC = useSelector((state) => state.agent?.pendingsUSDC);
    const pendingsPercentageUSDC = useSelector((state) => state.agent?.pendingsPercentageUSDC);
    const availableToReceiveLN = useSelector((state) => state.agent?.availableToReceiveLN);

    let totalSumChannel = availableToReceiveLN + channelBalanceBTC;

    const percentageAvailable = parseFloat(parseFloat((100 / totalSumChannel) * availableToReceiveLN).toFixed(2));
    const percentageSpend = parseFloat(parseFloat((100 / totalSumChannel) * channelBalanceBTC).toFixed(2));

    const walletBalanceETHCrypto = parseFloat(parseFloat(walletBalanceETH / exchangeETH).toFixed(18));
    const walletBalanceUSDTCrypto = parseFloat(parseFloat(walletBalanceUSDT / exchangeUSDT).toFixed(6));
    const walletBalanceUSDCCrypto = parseFloat(parseFloat(walletBalanceUSDC / exchangeUSDC).toFixed(6));
    //console.log("data", balanceAdminUSDTTRON, pendingsUSDTTRON, exchangeUSDT);
    const totalBalanceTotal = parseFloat(parseFloat((balanceAdminETH - pendingsETH) * exchangeETH + (balanceAdminUSDT - pendingsUSDT) * exchangeUSDT + (balanceAdminUSDTTRON - pendingsUSDTTRON) * exchangeUSDT + (balanceAdminUSDC - pendingsUSDC) * exchangeUSDC + (channelBalanceBTC + walletBalanceBTC - pendingsBTC) * exchange).toFixed(4));

    const classes = useStyles();

    let isMobile = false;

    useEffect(() => {
        /*console.log("SHOW VALUE", lsShow);
        if (lsShow !== "true") {
            setOkDialog({
                isOpen: true,
                justify: true,
                title: "Dear FlashTeller Client.",
                subTitle: "On Monday Morning , May 29th, at approximately 1am et, Flashteller services will be down for system maintenance.  At that time, we will be switching to a new node and there will be a slight change in functionality.  As of now, there is a 1.9% charge on any withdrawal out of Flashteller and no charge for the  incoming deposits .  On May 29th, it will be the incoming deposits that are charged the 1.9% fee while all withdrawals out of Flashteller will be free of charge.  Nothing will change in the effective amount on which you are charged, it will just simply be on deposits rather than withdrawals.  In order to implement this change, any residual balance left in your Flashteller account on May 29 will be charged a one time, 1.9 % charge .  After that, all withdrawals will be completely free of charge.   Thanks for using Flashteller.",
                onConfirm: () => {
                    setOkDialog({
                        ...okDialog,
                        isOpen: false,
                    });
                },
            });
            localStorage.setItem("showMsg", "true");
        }*/
        const interval = setInterval(() => {
            document.body.style.cursor = "wait";
            dispatch(
                getAgentInfo(agentId, merchant, token, (data) => {
                    //console.log("DATA BALANCES", data);
                    document.body.style.cursor = "default";
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch({ type: LOGIN, data });
                            history.push("/");
                        }
                    }
                })
            );
        }, 80000);
        return () => clearInterval(interval);
    }, [dispatch]);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    let finalBalance = parseFloat(parseFloat(balanceUSDC * exchangeUSDC + balanceBTC * exchange + balanceETH * exchangeETH + balanceUSDT * exchangeUSDT + balanceUSDTTRON * exchangeUSDT).toFixed(4));
    let finalBalanceUSDC = parseFloat(balanceUSDC * exchangeUSDC).toFixed(4);
    let finalBalanceUSDT = parseFloat(balanceUSDT * exchangeUSDT).toFixed(4);
    let finalBalanceUSDTTRON = parseFloat(balanceUSDTTRON * exchangeUSDT).toFixed(4);
    let finalBalanceETH = parseFloat(balanceETH * exchangeETH).toFixed(4);
    let finalBalanceBTC = parseFloat(balanceBTC * exchange).toFixed(4);

    let finalBalanceAdminNodes = parseFloat(parseFloat(parseFloat(parseFloat((channelBalanceBTC + walletBalanceBTC) * exchange).toFixed(4)) + parseFloat(parseFloat(balanceAdminETH * exchangeETH).toFixed(4)) + parseFloat(parseFloat(balanceAdminUSDT * exchangeUSDT).toFixed(4)) + parseFloat(parseFloat(balanceAdminUSDC * exchangeUSDC).toFixed(4))).toFixed(4));
    let finalBalanceAdminEarnings = parseFloat(parseFloat(parseFloat(parseFloat(balanceBTC * exchange).toFixed(4)) + parseFloat(parseFloat(balanceETH * exchangeETH).toFixed(4)) + parseFloat(parseFloat(balanceUSDT * exchangeUSDT).toFixed(4)) + parseFloat(parseFloat(balanceUSDC * exchangeUSDC).toFixed(4))).toFixed(4));
    let finalBalanceAdminPendings = parseFloat(parseFloat(parseFloat(parseFloat(pendingsPercentageBTC * exchange).toFixed(4)) + parseFloat(parseFloat(pendingsPercentageETH * exchangeETH).toFixed(4)) + parseFloat(parseFloat(pendingsPercentageUSDT * exchangeUSDT).toFixed(4)) + parseFloat(parseFloat(pendingsPercentageUSDC * exchangeUSDC).toFixed(4))).toFixed(4));

    return (
        <div>
            <GridContainer className={classes.gridTop}>
                <GridItem xs={12} sm={12} md={12}>
                    <Card className={classes.CardRound}>
                        <CardBody>
                            {isAdmin === false ? (
                                <TableContainer>
                                    <Table className={classes.tableMain}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableRowMainBalance}>
                                                    <Typography className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}>Crypto</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}>Balance</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}>Exchange Rate</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography
                                                        className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        $USD
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    sx={{
                                                        verticalAlign: "top",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            mt: "3px",
                                                            mb: "3px",
                                                        }}
                                                    >
                                                        <Icon icon="logos:bitcoin" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Bitcoin`}</Typography>
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "BTC" : ""}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceBTC ? parseFloat(parseFloat(balanceBTC)).toFixed(8) : "0.00000000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchange ? parseFloat(exchange).toFixed(4) : "0.0000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography
                                                        className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        $ {!isNaN(finalBalanceBTC) ? finalBalanceBTC : "0.0000"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRowMainBalance}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            mt: "3px",
                                                            mb: "3px",
                                                        }}
                                                    >
                                                        <Icon icon="cryptocurrency-color:eth" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Ethereum`}</Typography>
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "ETH" : ""}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceETH ? parseFloat(parseFloat(balanceETH)).toFixed(18) : "0.000000000000000000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchangeETH ? parseFloat(exchangeETH).toFixed(4) : "0.0000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography
                                                        className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        $ {!isNaN(finalBalanceETH) ? finalBalanceETH : "0.0000"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRowMainBalance}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            mt: "3px",
                                                            mb: "3px",
                                                        }}
                                                    >
                                                        <Icon icon="cryptocurrency-color:usdt" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Tether`}</Typography>
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "USDT" : ""}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceUSDT ? parseFloat(parseFloat(balanceUSDT)).toFixed(6) : "0.000000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchangeUSDT ? parseFloat(exchangeUSDT).toFixed(4) : "0.0000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography
                                                        className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        $ {!isNaN(finalBalanceUSDT) ? finalBalanceUSDT : "0.0000"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRowMainBalance}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            mt: "3px",
                                                            mb: "3px",
                                                        }}
                                                    >
                                                        <Icon icon="cryptocurrency-color:usdc" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `USD Coin`}</Typography>
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "USDC" : ""}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceUSDC ? parseFloat(parseFloat(balanceUSDC)).toFixed(6) : "0.000000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchangeUSDC ? parseFloat(exchangeUSDC).toFixed(4) : "0.0000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography
                                                        className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        $ {!isNaN(finalBalanceUSDC) ? finalBalanceUSDC : "0.0000"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.tableRowMainBalance}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            mt: "3px",
                                                            mb: "3px",
                                                        }}
                                                    >
                                                        <Icon icon="cryptocurrency-color:usdt" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `USDT TRON`}</Typography>
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "USDT TRON" : ""}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceUSDTTRON ? parseFloat(parseFloat(balanceUSDTTRON)).toFixed(6) : "0.000000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchangeUSDT ? parseFloat(exchangeUSDT).toFixed(4) : "0.0000"}</Typography>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Typography
                                                        className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    >
                                                        $ {!isNaN(finalBalanceUSDTTRON) ? finalBalanceUSDTTRON : "0.0000"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    style={{
                                                        textAlign: "left",
                                                        borderBottom: "none",
                                                    }}
                                                    colSpan={3}
                                                >
                                                    <Box
                                                        sx={{
                                                            mt: "15px",
                                                            mb: "3px",
                                                        }}
                                                    >
                                                        <Typography className={isMobile === true ? classes.textoTableHeadMTotal : classes.textoTableHeadTotal}>Balance</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableRowMainTotal}
                                                    style={{
                                                        textAlign: "right",
                                                        borderBottom: "none",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            mt: "15px",
                                                            mb: "3px",
                                                            textAlign: "right",
                                                            mr: "10px",
                                                        }}
                                                    >
                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTableTotal : classes.textoTableBodyAmtTableTotal}>$ {!isNaN(finalBalance) ? parseFloat(finalBalance).toFixed(4) : "0.0000"}</Typography>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <>
                                    <GridContainer>
                                        {/*<GridItem xs={12} sm={6} md={12}>
                    <Card style={{borderRadius: "15px"}}>
                        <CardHeader color="info" style={{borderRadius: "15px"}}>
                            <Typography className={classes.agentName}>Nodes</Typography>                                         
                        </CardHeader>
                        <CardBody>
                          <TableContainer>
                            <Table className={classes.tableMain}>
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.tableRowMainBalance}>
                                    <Typography className={isMobile===true?classes.textoTableHeadM:classes.textoTableHead}>Crypto</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableHeadM:classes.textoTableHead}>Balance</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableHeadM:classes.textoTableHead}>Exchange Rate</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableHeadM:classes.textoTableHead} style={{marginRight:'10px'}}>$USD</Typography>
                                  </TableCell>
                                </TableRow>                    
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell className={classes.tableRowMainBalance} sx={{ verticalAlign: 'top' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '3px', mb: '3px' }}><Icon icon="logos:bitcoin" className={isMobile===true?classes.iconCryptoM:classes.iconCrypto}/><Typography className={isMobile===true?classes.textoTableBodySignMTable:classes.textoTableBodySignTable}>{isMobile===true?"":`Bitcoin`}</Typography><Typography className={isMobile===true?classes.textoTableBodySignTableLowM:classes.textoTableBodySignTableLow}>{isMobile===true?"BTC":""}</Typography></Box>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable}>{channelBalanceBTC?parseFloat(parseFloat(channelBalanceBTC+walletBalanceBTC)).toFixed(8):'0.00000000'}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable}>{exchange?parseFloat(exchange).toFixed(4):'0.0000'}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable} style={{marginRight:'10px'}}>$ {!isNaN(finalBalanceBTC)?parseFloat((channelBalanceBTC+walletBalanceBTC)*exchange).toFixed(4):'0.0000'}</Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={classes.tableRowMainBalance}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '3px', mb: '3px'}}><Icon icon="cryptocurrency-color:eth" className={isMobile===true?classes.iconCryptoM:classes.iconCrypto}/><Typography className={isMobile===true?classes.textoTableBodySignMTable:classes.textoTableBodySignTable}>{isMobile===true?"":`Ethereum`}</Typography><Typography className={isMobile===true?classes.textoTableBodySignTableLowM:classes.textoTableBodySignTableLow}>{isMobile===true?"ETH":""}</Typography></Box>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable}>{balanceAdminETH?parseFloat(parseFloat(balanceAdminETH)).toFixed(18):'0.000000000000000000'}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable}>{exchangeETH?parseFloat(exchangeETH).toFixed(4):'0.0000'}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable} style={{marginRight:'10px'}}>$ {!isNaN(balanceAdminETH)?parseFloat(balanceAdminETH*exchangeETH).toFixed(4):'0.0000'}</Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={classes.tableRowMainBalance}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '3px', mb: '3px'}}><Icon icon="cryptocurrency-color:usdt" className={isMobile===true?classes.iconCryptoM:classes.iconCrypto}/><Typography className={isMobile===true?classes.textoTableBodySignMTable:classes.textoTableBodySignTable}>{isMobile===true?"":`Tether`}</Typography><Typography className={isMobile===true?classes.textoTableBodySignTableLowM:classes.textoTableBodySignTableLow}>{isMobile===true?"USDT":""}</Typography></Box>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable}>{balanceAdminUSDT?parseFloat(parseFloat(balanceAdminUSDT)).toFixed(6):'0.000000'}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable}>{exchangeUSDT?parseFloat(exchangeUSDT).toFixed(4):'0.0000'}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable} style={{marginRight:'10px'}}>$ {!isNaN(balanceAdminUSDT)?parseFloat(balanceAdminUSDT*exchangeUSDT).toFixed(4):'0.0000'}</Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={classes.tableRowMainBalance}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '3px', mb: '3px'}}><Icon icon="cryptocurrency-color:usdc" className={isMobile===true?classes.iconCryptoM:classes.iconCrypto}/><Typography className={isMobile===true?classes.textoTableBodySignMTable:classes.textoTableBodySignTable}>{isMobile===true?"":`USD Coin`}</Typography><Typography className={isMobile===true?classes.textoTableBodySignTableLowM:classes.textoTableBodySignTableLow}>{isMobile===true?"USDC":""}</Typography></Box>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable}>{balanceAdminUSDC?parseFloat(parseFloat(balanceAdminUSDC)).toFixed(6):'0.000000'}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable}>{exchangeUSDC?parseFloat(exchangeUSDC).toFixed(4):'0.0000'}</Typography>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'right'}}>
                                    <Typography className={isMobile===true?classes.textoTableBodyAmtMTable:classes.textoTableBodyAmtTable} style={{marginRight:'10px'}}>$ {!isNaN(balanceAdminUSDC)?parseFloat(balanceAdminUSDC*exchangeUSDC).toFixed(4):'0.0000'}</Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>                      
                                  <TableCell className={classes.tableRowMainBalance} style={{textAlign:'left',borderBottom:'none'}} colSpan={3}>
                                    <Box sx={{mt: '15px', mb: '3px'}}><Typography className={isMobile===true?classes.textoTableHeadMTotal:classes.textoTableHeadTotal}>Balance</Typography></Box>
                                  </TableCell>
                                  <TableCell className={classes.tableRowMainTotal} style={{textAlign:'right',borderBottom:'none'}}>
                                    <Box sx={{mt: '15px', mb: '3px', textAlign:'right',mr:'10px'}}><Typography className={isMobile===true?classes.textoTableBodyAmtMTableTotal:classes.textoTableBodyAmtTableTotal}>$ {!isNaN(finalBalanceAdminNodes)?parseFloat(finalBalanceAdminNodes).toFixed(4):'0.0000'}</Typography></Box>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardBody>
                    </Card>
              </GridItem>*/}
                                        {/*<GridItem xs={12} sm={6} md={12}>
                                            <Card style={{ borderRadius: "15px" }}>
                                                <CardHeader
                                                    color="info"
                                                    style={{
                                                        borderRadius: "15px",
                                                    }}
                                                >
                                                    <Typography className={classes.agentName}>Processed</Typography>
                                                </CardHeader>
                                                <CardBody>
                                                    <TableContainer>
                                                        <Table className={classes.tableMain}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell colSpan={4}>{!syncStatus ? <Typography style={{ color: "red" }}>Sync Status Down</Typography> : <Typography style={{ color: "green" }}>Sync Status Ok</Typography>}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Typography className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}>Crypto</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}>Amount</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}>Exchange Rate</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $USD
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        sx={{
                                                                            verticalAlign: "top",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="logos:bitcoin" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Bitcoin`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "BTC" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceBTC ? parseFloat(parseFloat(balanceBTC)).toFixed(8) : "0.00000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchange ? parseFloat(exchange).toFixed(4) : "0.0000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(finalBalanceBTC) ? parseFloat(balanceBTC * exchange).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="cryptocurrency-color:eth" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Ethereum`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "ETH" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceETH ? parseFloat(parseFloat(balanceETH)).toFixed(18) : "0.000000000000000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchangeETH ? parseFloat(exchangeETH).toFixed(4) : "0.0000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(balanceETH) ? parseFloat(balanceETH * exchangeETH).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="cryptocurrency-color:usdt" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Tether`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "USDT" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceAdminUSDT ? parseFloat(parseFloat(balanceUSDT)).toFixed(6) : "0.000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchangeUSDT ? parseFloat(exchangeUSDT).toFixed(4) : "0.0000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(balanceUSDT) ? parseFloat(balanceUSDT * exchangeUSDT).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="cryptocurrency-color:usdc" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `USD Coin`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "USDC" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceUSDC ? parseFloat(parseFloat(balanceUSDC)).toFixed(6) : "0.000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{exchangeUSDC ? parseFloat(exchangeUSDC).toFixed(4) : "0.0000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(balanceUSDC) ? parseFloat(balanceUSDC * exchangeUSDC).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "left",
                                                                            borderBottom: "none",
                                                                        }}
                                                                        colSpan={3}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                mt: "15px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Typography className={isMobile === true ? classes.textoTableHeadMTotal : classes.textoTableHeadTotal}>Balance</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainTotal}
                                                                        style={{
                                                                            textAlign: "right",
                                                                            borderBottom: "none",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                mt: "15px",
                                                                                mb: "3px",
                                                                                textAlign: "right",
                                                                                mr: "10px",
                                                                            }}
                                                                        >
                                                                            <Typography className={isMobile === true ? classes.textoTableBodyAmtMTableTotal : classes.textoTableBodyAmtTableTotal}>$ {!isNaN(finalBalanceAdminEarnings) ? parseFloat(finalBalanceAdminEarnings).toFixed(4) : "0.0000"}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </CardBody>
                                            </Card>
                                                                        </GridItem>*/}

                                        <GridItem xs={12} sm={6} md={12}>
                                            <Card style={{ borderRadius: "15px" }}>
                                                <CardHeader
                                                    color="info"
                                                    style={{
                                                        borderRadius: "15px",
                                                    }}
                                                >
                                                    <Typography className={classes.agentName}>Total Balance</Typography>
                                                </CardHeader>
                                                <CardBody>
                                                    <TableContainer>
                                                        <Table className={classes.tableMain}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Typography className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}>Crypto</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}>On Wallet</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableHeadM : classes.textoTableHead}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $USD
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        sx={{
                                                                            verticalAlign: "top",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="logos:bitcoin" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Bitcoin`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "BTC" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{channelBalanceBTC ? parseFloat(parseFloat(channelBalanceBTC + walletBalanceBTC - pendingsBTC)).toFixed(8) : "0.00000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(pendingsBTC) ? parseFloat((channelBalanceBTC + walletBalanceBTC - pendingsBTC) * exchange).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="cryptocurrency-color:eth" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Ethereum`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "ETH" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceAdminETH ? parseFloat(balanceAdminETH).toFixed(18) : "0.000000000000000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(pendingsETH) ? parseFloat((balanceAdminETH - pendingsETH) * exchangeETH).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="cryptocurrency-color:usdt" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `Tether`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "USDT" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceAdminUSDT ? parseFloat(balanceAdminUSDT).toFixed(4) : "0.000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(pendingsUSDT) ? parseFloat((balanceAdminUSDT - pendingsUSDT) * exchangeUSDT).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="cryptocurrency-color:usdc" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `USD Coin`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "USDC" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceAdminUSDC ? parseFloat(balanceAdminUSDC).toFixed(4) : "0.000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(pendingsUSDC) ? parseFloat((balanceAdminUSDC - pendingsUSDC) * exchangeUSDC).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.tableRowMainBalance}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                mt: "3px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Icon icon="cryptocurrency-color:usdt" className={isMobile === true ? classes.iconCryptoM : classes.iconCrypto} />
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignMTable : classes.textoTableBodySignTable}>{isMobile === true ? "" : `USDT TRON`}</Typography>
                                                                            <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>{isMobile === true ? "USDT TRON" : ""}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}>{balanceAdminUSDTTRON ? parseFloat(balanceAdminUSDTTRON).toFixed(4) : "0.000000"}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "right",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            className={isMobile === true ? classes.textoTableBodyAmtMTable : classes.textoTableBodyAmtTable}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                            }}
                                                                        >
                                                                            $ {!isNaN(pendingsUSDTTRON) ? parseFloat((balanceAdminUSDTTRON - pendingsUSDTTRON) * exchangeUSDT).toFixed(4) : "0.0000"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow>
                                                                    <TableCell
                                                                        className={classes.tableRowMainBalance}
                                                                        style={{
                                                                            textAlign: "left",
                                                                            borderBottom: "none",
                                                                        }}
                                                                        colSpan={2}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                mt: "15px",
                                                                                mb: "3px",
                                                                            }}
                                                                        >
                                                                            <Typography className={isMobile === true ? classes.textoTableHeadMTotal : classes.textoTableHeadTotal}>Total</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={classes.tableRowMainTotal}
                                                                        style={{
                                                                            textAlign: "right",
                                                                            borderBottom: "none",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                mt: "15px",
                                                                                mb: "3px",
                                                                                textAlign: "right",
                                                                                mr: "10px",
                                                                            }}
                                                                        >
                                                                            <Typography className={isMobile === true ? classes.textoTableBodyAmtMTableTotal : classes.textoTableBodyAmtTableTotal}>$ {!isNaN(totalBalanceTotal) ? parseFloat(totalBalanceTotal).toFixed(4) : "0.0000"}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                        <GridItem xs={12} sm={6} md={12}>
                                            <Card style={{ borderRadius: "15px" }}>
                                                <CardHeader
                                                    color="info"
                                                    style={{
                                                        borderRadius: "15px",
                                                    }}
                                                >
                                                    <Typography className={classes.agentName}>Lightning Network Channel Balance</Typography>
                                                </CardHeader>
                                                <CardBody>
                                                    <TableContainer>
                                                        <Table className={classes.tableMain}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell
                                                                        width={`${percentageAvailable}%`}
                                                                        style={{
                                                                            backgroundColor: "green",
                                                                            borderRadius: "5px",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                color: "white",
                                                                            }}
                                                                        >{`Available to Deposit: $${parseFloat(availableToReceiveLN * exchange).toFixed(2)} (${percentageAvailable}%)`}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        width={`${percentageSpend}%`}
                                                                        style={{
                                                                            backgroundColor: "blue",
                                                                            borderRadius: "5px",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                color: "white",
                                                                            }}
                                                                        >{`On Balance: $${parseFloat(channelBalanceBTC * exchange).toFixed(2)}  (${percentageSpend}%)`}</Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                        </Table>
                                                    </TableContainer>
                                                </CardBody>
                                            </Card>
                                        </GridItem>
                                    </GridContainer>
                                </>
                            )}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {isAdmin === false ? <Deposits titleDeposits={"Latest Deposits"} /> : null}
            {isAdmin === false ? (
                <GridContainer>
                    <GridItem xs={12} sm={6} md={12}>
                        <Card style={{ borderRadius: "15px" }}>
                            <CardHeader color="info" style={{ borderRadius: "15px" }}>
                                <Typography className={classes.agentName}>Disclaimer</Typography>
                            </CardHeader>
                            <CardBody>
                                <TableContainer>
                                    <Table className={classes.tableMain}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.tableRowMainBalance}
                                                    sx={{
                                                        verticalAlign: "top",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            mt: "3px",
                                                            mb: "3px",
                                                        }}
                                                    >
                                                        <Typography className={isMobile === true ? classes.textoTableBodySignTableLowM : classes.textoTableBodySignTableLow}>
                                                            Please note that we are not a crypto exchange. We are a temporary holding spot for your cryptocurrency.  We do however take the utmost measures in security to protect your assets.  However we encourage all parties to always keep their cryptocurrency in cold storage wallets and keep your balances in Flashteller and other 3rd party mediums, to a bare minimum  as a general rule.  <br />
                                                            Thank you for using Flashteller.
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            ) : null}
            <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
        </div>
    );
};

export default Dashboard;

/*<GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <IconCore>request_quote</IconCore>
              </CardIcon>
              <p className={classes.cardCategory}>Deposits</p>
              <h3 className={classes.cardTitle}>{`Total: ${0}`}</h3>
              <p className={classes.cardCategory}>Withdrawals</p>
              <h3 className={classes.cardTitle}>{`Total: ${0}`}</h3>             
            </CardHeader>
            <CardFooter stats>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                  onClick={
                    () => {
                      openWithdrawals()
                    }                      
                  }
                >
                  Withdrawal
                </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <IconCore>attach_money</IconCore>
              </CardIcon>
              <p className={classes.cardCategory}>BTC</p>
              <h3 className={classes.cardTitle}>{`₿${balance?parseFloat(parseFloat(balance)/100000000).toFixed(8):0}`}</h3>
              <p className={classes.cardCategory}>Exchange</p>
              <h3 className={classes.cardTitle}>{`$${exchange?parseFloat(exchange).toFixed(2):0}`}</h3>
              <p className={classes.cardCategory}>USD</p>
              <h3 className={classes.cardTitle}>{`$${balance?parseFloat(parseFloat(parseFloat(balance)/100000000)*exchange).toFixed(2):0}`}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <AccessTime />
                Last Update Info
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info" redirect="/admin/players">
                <IconCore>groups</IconCore>
              </CardIcon>
              <p className={classes.cardCategory}>Players</p>
              <h3 className={classes.cardTitle}>Total: {0}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <AccessTime />
                Last Update Info
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      
      
      <TableCell width="25%" className={isMobile===true?classes.mainTableCellMob:classes.mainTableCell}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.headTable}>
                                <AccountCircleIcon className={classes.iconCustomers}/> Customers
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.bodyTable}>
                                <Typography className={classes.textoTableBodySign}>Total: </Typography><Typography className={classes.textoTableBodyAmt}>{1}</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
      
      

                      <GridItem xs={12} sm={6} md={4}>
          <WeekDepositsChart agentId={agentId}/>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <MonthDepositsChart agentId={agentId}/>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <HourDepositsChart agentId={agentId}/>
        </GridItem>

      */
