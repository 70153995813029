import Immutable from 'seamless-immutable';
import { LOGIN, LOGIN_ERROR, LOGOUT,LOGINEND } from '../constants/actionTypes';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = Immutable({loginData: null}), action) => {
  switch(action.type){
    case LOGIN:
      /*localStorage.setItem('profile', JSON.stringify({
        agentId: action?.data?.agentId,
        merchant: action?.data?.merchant,
        token: action?.data?.token
      }));*/
      return { ...state, loginData: action?.data};
    case LOGIN_ERROR:      
      return { ...state, loginData: action?.data};
    case LOGINEND:      
      return { ...state, loginData: action?.data};
    case LOGOUT:
      //localStorage.clear();
      return { ...state, loginData: null};
    default:
      return state;
  }
}

