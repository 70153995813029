import React,{useEffect,useState} from 'react';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {KeyboardDatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import { green,grey } from '@material-ui/core/colors';
import { TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import Button from '@material-ui/core/Button';

import {useSelector,useDispatch} from 'react-redux';
import {sendMessage,getAgentsMessage} from '../../actions/agent'
//import {getPlayersByAgent,} from '../../actions/player';
import OkDialog from '../ConfirmDialog/OkDialog';


const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: '50px',
    marginBottom: '30px',
    color: '#333333',
    fontWeight: '700',
  },
  topTitle: {
    fontSize: '18px',
    fontWeight: '700',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    width: '100%',
    marginBottom: '10px',
  },

  inputcmb: {
    marginTop: '10px'
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#000000',
    backgroundColor: '#FF9A00',
    fontWeight: '700',
    ':hover': {
      backgroundColor: '#FFCC00'
    },
  },
  alerttext: {
    color: 'red'
  },
}));

const GreenSwitch = withStyles({
  switchBase: {
    color: grey[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

function LoadSelect(props){
  const {list,isAgent,postData,setPostData} = props;
  const [selectPerson,setSelectPerson]=useState('1');

  const handleChangePerson = (e) => {
    setSelectPerson(e.target.value);
    if(isAgent===true){
      setPostData({ ...postData, selectionidagent: e.target.value })
    }else{
      setPostData({ ...postData, selectionidplayer: e.target.value });
    }
  }
  return list?(
    <>
      <InputLabel id="toType">{isAgent===true?'Agent:':'Player:'}</InputLabel>
      <Select
        label="toType"
        autoWidth={true}
        style={{ width: 200}}
        value={selectPerson}
        onChange={handleChangePerson}
      >
        {isAgent===true?<MenuItem value={'1'} key={'1'}>Select Agent</MenuItem>:<MenuItem value={'1'} key={'1'}>Select Player</MenuItem>}
        {list.map(item => 
          <MenuItem value={item._id} key={item._id}>{item.username}</MenuItem>
        )}
      </Select>           
    </>
  ):null
}

const NewMessage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const agent = useSelector(store => store.agent.agent);
  const [okDialog, setOkDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const [openSelect, setOpenSelect] = useState({
    open:false,
    isAgent:false,
    list:[]
  });
  const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
  const [postData, setPostData] = useState({
    type:'0',
    selectionidagent:'1',
    selectionidplayer:'1',
    expire:false,
    expirationdate:new Date(),
    message:'',
    agentId:agentId
  });

   

  useEffect(() => {
    dispatch(getAgentsMessage(agentId));
    //dispatch(getPlayersByAgent(agent._id,0,100000));
  }, [dispatch])

  const agentList = useSelector(store => store.agent.agentsMessage); 
  const playerList = [];//useSelector(store => store.player.playersList); 

  const handleChangeTo = (e) => {
    e.preventDefault();
    setPostData({ ...postData, type: e.target.value.toString() })
    if(e.target.value === '2'){
      setOpenSelect({open:true,isAgent:true,list:agentList});
    }else if(e.target.value === '3'){
      setOpenSelect({open:true,isAgent:false,list:playerList});
    }
    else if(e.target.value === '0'){
      setOpenSelect({open:false,isAgent:false,list:[]});
    }else if(e.target.value === '1'){
      setOpenSelect({open:false,isAgent:false,list:[]});
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(sendMessage(postData, (data) => {
      if(data?.error){
        setOkDialog({
          isOpen: true,
          title: 'Error sending message.',
          subTitle: data?.error,
          onConfirm: () => {
            setOkDialog({
              ...okDialog,
              isOpen: false
            })}
        })
      }else{
        setOkDialog({
          isOpen: true,
          title: 'Message sent successfully',
          subTitle: "",
          onConfirm: () => {
            setOkDialog({
              ...okDialog,
              isOpen: false
            })
          }
        });
        setPostData({
          type:'0',
          selectionidagent:'1',
          selectionidplayer:'1',
          expire:false,
          expirationdate:new Date(),
          message:'',
          agentId:agent._id
        })
      }
    }))
  }

  return agentList && playerList?(
    <Container component="main" maxWidth="xs">
      <form noValidate onSubmit={onSubmit}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <InputLabel id="toLabel">To:</InputLabel> 
                <Select
                  onChange={handleChangeTo}
                  label="To:"
                  labelId="toLabel"
                  value={postData.type}
                  autoWidth={true}
                >
                  <MenuItem value={"0"}>ADMIN</MenuItem>
                  <MenuItem value={"1"}>SUPPORT</MenuItem>
                  <MenuItem value={"2"}>AGENT</MenuItem>
                  <MenuItem value={"3"}>PLAYER</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                {openSelect.open === true?<LoadSelect list={openSelect.list} isAgent={openSelect.isAgent} postData={postData} setPostData={setPostData} />:null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={postData.expire}
                      onChange={(e) => setPostData({ ...postData, expire: e.target.checked })}
                      name="expire"
                      color="primary"
                    />
                  }
                  label="Expire"
                />
              </TableCell>
              <TableCell>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="expirationdate"
                    name="expirationdate"
                    label="Expiration Date"
                    value={postData.expirationdate}
                    onChange={(e) => setPostData({ ...postData, expirationdate: e.target.value })}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}                      
                  />
                </MuiPickersUtilsProvider>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <TextField 
                  name="message"
                  id="message"
                  className={classes.input} 
                  variant="outlined" 
                  label="Message" 
                  fullWidth
                  multiline={true}
                  rows="10"
                  value={postData.message}
                  onChange={(e) => setPostData({ ...postData, message: e.target.value })}
                  type="text"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                >
                  SEND MESSAGE
                </Button>                
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>                       
      </form>
      <OkDialog
          okDialog={okDialog}
          setOkDialog={setOkDialog}
      />
    </Container>
  ):null
}

export default NewMessage
