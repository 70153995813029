import React from 'react';
import WithdrawalPlayersTable from '../Tables/PlayerWithdrawals';
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import {Typography} from '@material-ui/core';
import UseStyles from './styles';
import { useSelector } from 'react-redux';

const PlayerWithdrawals = () => {
    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    //const merchant = JSON.parse(localStorage.getItem('profile')).merchant;
    const classes = UseStyles();

    return (
        <>
        <GridContainer>
            <GridItem xs={12} sm={6} md={12}>
                <Card style={{borderRadius: "15px"}}>
                    <CardHeader color="info" style={{borderRadius: "15px"}}>
                        <Typography className={classes.agentName}>{'Player Withdrawals'}</Typography>                                         
                    </CardHeader>
                    <CardBody>
                        <WithdrawalPlayersTable agentId={agentId} merchant={merchant}/>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        </>
    )
}

export default PlayerWithdrawals