import React from 'react';
import AgentsList from '../Tables/AllAgentsList';
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import {Typography} from '@material-ui/core';
import UseStyles from './styles';
import { useSelector } from 'react-redux';

const Agents = () => {
  let title = 'Agents';
  const classes = UseStyles();
  const agentId = useSelector((state) => state?.login?.loginData?.agentId);
  //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
  return (
    <GridContainer>
        <GridItem xs={12} sm={6} md={12}>
            <Card style={{borderRadius: "15px"}}>
                <CardHeader color="info" style={{borderRadius: "15px"}}>
                    <Typography className={classes.agentName}>{title}</Typography>                                         
                </CardHeader>
                <CardBody>
                  <AgentsList agentId={agentId}/>
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>    
  )
}

export default Agents