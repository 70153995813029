import React, { useState, useEffect } from "react";
import UseStyles from "./styles";
import Input from "./input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Typography, Container, TextField, Grid, InputAdornment } from "@material-ui/core";
import { requestAgentWithdrawalBTC, responseWithdrawalBTC, requestPreviousWithdrawalAgentBTC, getPreviousRequestAgentWithdrawalBTC } from "../../actions/withdrawal";
import { useDispatch } from "react-redux";
import OkDialog from "../Dialogs/OkDialog";
import ConfirmDialog from "../Dialogs/ConfirmReject";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { withStyles, makeStyles } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import PropTypes from "prop-types";
import SendIcon from "@material-ui/icons/Send";
import SettingsIcon from "@material-ui/icons/Settings";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { LOGIN } from "../../constants/actionTypes";

const initialState = {
    txid_hash: "",
    amount: 0,
    pin: "",
};

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#666666",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#034AFF",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#666666",
            },
            "&:hover fieldset": {
                borderColor: "#666666",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#034AFF",
            },
        },
    },
})(TextField);

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundImage: "linear-gradient( 95deg,rgb(0,100,0) 0%,rgb(0,128,0) 50%,rgb(34,139,34) 100%)",
        },
    },
    completed: {
        "& $line": {
            backgroundImage: "linear-gradient( 95deg,rgb(0,100,0) 0%,rgb(0,128,0) 50%,rgb(34,139,34) 100%)",
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
})(StepConnector);

const ColorlibConnectorDecline = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundImage: "linear-gradient( 95deg,rgb(178,34,34) 0%,rgb(255,0,0) 50%,rgb(255,99,71) 100%)",
        },
    },
    completed: {
        "& $line": {
            backgroundImage: "linear-gradient( 95deg,rgb(178,34,34) 0%,rgb(255,0,0) 50%,rgb(255,99,71) 100%)",
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
})(StepConnector);

function getStepContent(step) {
    switch (step) {
        case 0:
            return "Your withdrawal request has been sent";
        case 1:
            return "Your withdrawal request is been processed";
        case 2:
            return "Your withdrawal request has been approved";
        case 3:
            return "Your withdrawal request has been declided";
        default:
            return "Unknown step";
    }
}

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundImage: "linear-gradient( 136deg, rgb(0,100,0) 0%, rgb(0,128,0) 50%, rgb(34,139,34) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundImage: "linear-gradient( 136deg, rgb(0,100,0) 0%, rgb(0,128,0) 50%, rgb(34,139,34) 100%)",
    },
});

const useColorlibStepIconStylesDecline = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundImage: "linear-gradient( 136deg, rgb(178,34,34) 0%, rgb(255,0,0) 50%, rgb(255,99,71) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundImage: "linear-gradient( 136deg, rgb(178,34,34) 0%, rgb(255,0,0) 50%, rgb(255,99,71) 100%)",
    },
    decline: {
        backgroundImage: "linear-gradient( 136deg, rgb(178,34,34) 0%, rgb(255,0,0) 50%, rgb(255,99,71) 100%)",
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SendIcon />,
        2: <SettingsIcon />,
        3: <DoneOutlineIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

function ColorlibStepIconDecline(props) {
    const classes = useColorlibStepIconStylesDecline();
    const { active, completed } = props;

    const icons = {
        1: <SendIcon />,
        2: <SettingsIcon />,
        3: <CancelIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,

    declined: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const AgentRequestWithdrawalBTC = () => {
    const classes = UseStyles();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(initialState);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [confirmReject, setConfirmReject] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [withdrawal, setWithdrawal] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [enablesend, setEnablesend] = useState(false);
    const [twoFactorAuth, settwoFactorAuth] = useState(false);
    const [balance, setBalance] = useState(0);
    const [exchange, setExchange] = useState(0);
    const [coinSimbol, setCoinSimbol] = useState("₿");
    const [amountOtherCoin, setAmountOtherCoin] = useState("");
    const [coinSimbolButton, setCoinSimbolButton] = useState("$");
    const [balanceUSD, setBalanceUSD] = useState(0);
    const [styleMessage, setStyleMessage] = useState(0);
    const [steps, setSteps] = useState(["Request Sent", "Processing", "Applied"]);
    const history = useHistory();

    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    const token = useSelector((state) => state?.login?.loginData?.token);
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    //const merchant = JSON.parse(localStorage.getItem('profile')).merchant;

    const handleChange = async (e) => {
        //console.log("EVENTO", e.target.name, e.target.value);
        var vl = e.target.value;
        var min = 0;
        var max = 0;
        if (coinSimbol === "$") {
            max = balanceUSD;
        } else {
            max = balance;
        }
        if (e.target.name === "amount") {
            if (vl > max) vl = max;
            if (vl < min) vl = min;
        }
        let valOtherCoin = 0;
        if (vl > 0) {
            if (coinSimbol === "$") {
                valOtherCoin = parseFloat(parseFloat(vl).toFixed(2)) / exchange;
                valOtherCoin = parseFloat(parseFloat(valOtherCoin).toFixed(8));
            } else {
                valOtherCoin = parseFloat(parseFloat(vl).toFixed(8)) * exchange;
                valOtherCoin = parseFloat(parseFloat(valOtherCoin).toFixed(2));
            }
            setAmountOtherCoin(`${coinSimbolButton} ${valOtherCoin}`);
        } else {
            setAmountOtherCoin("");
        }
        setFormData({ ...formData, [e.target.name]: vl });
    };

    const handleMax = async (e) => {
        e.preventDefault();
        var amt = document.getElementById("amount");
        if (coinSimbol === "$") {
            amt.value = balanceUSD;
            setFormData({ ...formData, amount: balanceUSD });
        } else {
            amt.value = balance;
            setFormData({ ...formData, amount: balance });
        }
        let valOtherCoin = 0;
        if (amt.value > 0) {
            if (coinSimbol === "$") {
                valOtherCoin = parseFloat(parseFloat(amt.value).toFixed(2)) / exchange;
                valOtherCoin = parseFloat(parseFloat(valOtherCoin).toFixed(8));
                if (valOtherCoin > balance) {
                    valOtherCoin = balance;
                }
            } else {
                valOtherCoin = parseFloat(parseFloat(amt.value).toFixed(8)) * exchange;
                valOtherCoin = parseFloat(parseFloat(valOtherCoin).toFixed(2));
                if (valOtherCoin > balanceUSD) {
                    valOtherCoin = balanceUSD;
                }
            }
            setAmountOtherCoin(`${coinSimbolButton} ${valOtherCoin}`);
        } else {
            setAmountOtherCoin("");
        }
        //console.log("DATA", formData, amt.value);
    };

    const handleHalf = async (e) => {
        e.preventDefault();
        var amt = document.getElementById("amount");
        amt.value = parseFloat(balance / 2).toFixed(8);
    };

    const handleTercio = async (e) => {
        e.preventDefault();
        var amt = document.getElementById("amount");
        amt.value = parseFloat(balance / 3).toFixed(8);
    };

    const handleTQuarter = async (e) => {
        e.preventDefault();
        var amt = document.getElementById("amount");
        amt.value = parseFloat(balance / 4).toFixed(8);
    };

    const handleChangePin = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCoinSimbol = (e) => {
        e.preventDefault();
        var amt = document.getElementById("amount");
        var newVal = 0;
        let valOtherCoin = 0;
        if (coinSimbol === "$") {
            if (formData.amount > 0) {
                newVal = parseFloat(parseFloat(amt.value).toFixed(6)) / exchange;
                newVal = parseFloat(parseFloat(newVal).toFixed(8));
                if (newVal > balance) {
                    newVal = balance;
                }
            }
            if (newVal > 0) {
                valOtherCoin = parseFloat(parseFloat(newVal).toFixed(8)) * exchange;
                valOtherCoin = parseFloat(parseFloat(valOtherCoin).toFixed(2));
                if (valOtherCoin > balanceUSD) {
                    valOtherCoin = balanceUSD;
                }
                setAmountOtherCoin(`$ ${valOtherCoin}`);
            } else {
                setAmountOtherCoin("");
            }
            setCoinSimbol("₿");
            setCoinSimbolButton("$");
        } else {
            if (formData.amount > 0) {
                newVal = parseFloat(formData.amount) * exchange;
                newVal = parseFloat(parseFloat(newVal).toFixed(2));
            }
            if (newVal > 0) {
                valOtherCoin = parseFloat(parseFloat(newVal).toFixed(2)) / exchange;
                valOtherCoin = parseFloat(parseFloat(valOtherCoin).toFixed(8));
                if (valOtherCoin > balance) {
                    valOtherCoin = balance;
                }
                setAmountOtherCoin(`₿ ${valOtherCoin}`);
            } else {
                setAmountOtherCoin("");
            }
            setCoinSimbol("$");
            setCoinSimbolButton("₿");
        }
        if (newVal > 0) {
            setFormData({ ...formData, amount: newVal });
            amt.value = newVal.toString();
        } else {
            amt.value = "";
        }

        amt.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!enablesend && !e.currentTarget.disabled) {
            setEnablesend(true);
            let twoFactorPin = "";
            document.body.style.cursor = "wait";
            e.currentTarget.disabled = true;
            e.currentTarget.style.opacity = "0.5";
            var finalAmt = 0;
            var txt = document.getElementById("amount");

            if (twoFactorAuth === true) {
                var pin = document.getElementById("pin");
                twoFactorPin = pin.value;
            }
            if (coinSimbol === "$") {
                let temp = 0;
                temp = parseFloat(parseFloat(txt.value).toFixed(2)) / exchange;
                finalAmt = parseFloat(parseFloat(temp).toFixed(8));
            } else {
                finalAmt = parseFloat(parseFloat(txt.value).toFixed(8));
            }
            //console.log("REQUEST BTC AMOUNT", finalAmt);
            dispatch(
                requestAgentWithdrawalBTC(agentId, formData.txid_hash, finalAmt, merchant, twoFactorAuth, twoFactorPin, token, (res) => {
                    if (res.error) {
                        if (res.message === "Token Expire") {
                            dispatch({ type: LOGIN, res });
                            history.push("/");
                        }
                    }
                    document.body.style.cursor = "default";
                    if (res.error) {
                        //console.log("HUBO UN ERROR AL INGRESAR INVOICE");
                        setOkDialog({
                            isOpen: true,
                            title: "Error on withdrawal request.",
                            subTitle: res?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    } else {
                        let totalFee = parseFloat(parseFloat(res?.data?.feeSendUSD + res?.data?.feeMerchantUSD).toFixed(2));
                        let grandTotal = parseFloat(parseFloat(res?.data?.amountUSD - totalFee).toFixed(2));
                        setConfirmReject({
                            isOpen: true,
                            title: "Withdrawal Info.",
                            subTitle: res?.message,
                            totalUSD: res?.data?.amountUSD,
                            totalBTC: res?.data?.amountBTC,
                            totalFee: totalFee,
                            grandTotal: grandTotal,
                            finalMessage: "Total after fees",
                            onConfirm: () => {
                                setConfirmReject({
                                    ...confirmReject,
                                    isOpen: false,
                                });
                                document.body.style.cursor = "wait";
                                dispatch(
                                    responseWithdrawalBTC(res?.data?.requestWithdrawalId, "Confirm", token, (res) => {
                                        if (res.error) {
                                            if (res.message === "Token Expire") {
                                                dispatch({
                                                    type: LOGIN,
                                                    res,
                                                });
                                                history.push("/");
                                            }
                                        }
                                        document.getElementById("txid_hash").value = "";
                                        document.getElementById("amount").value = "";
                                        document.body.style.cursor = "default";
                                        if (res.error) {
                                            setOkDialog({
                                                isOpen: true,
                                                title: "Error on withdrawal request.",
                                                subTitle: res?.message,
                                                onConfirm: () => {
                                                    setOkDialog({
                                                        ...okDialog,
                                                        isOpen: false,
                                                    });
                                                },
                                            });
                                        } else {
                                            setOkDialog({
                                                isOpen: true,
                                                title: "Withdrawal Applied",
                                                subTitle: `Your ${coinSimbol === "$" ? "USD Withdrawal $" : "Bitcoin Withdrawal ₿"}${formData.amount} was applied`,
                                                onConfirm: () => {
                                                    setOkDialog({
                                                        ...okDialog,
                                                        isOpen: false,
                                                    });
                                                    setFormData({
                                                        txid_hash: "",
                                                        amount: 0,
                                                    });
                                                    window.location.reload();
                                                },
                                            });
                                        }
                                    })
                                );
                            },
                            onReject: () => {
                                setConfirmReject({
                                    ...confirmReject,
                                    isOpen: false,
                                });
                                document.body.style.cursor = "wait";
                                setWithdrawal(null);
                                dispatch(
                                    responseWithdrawalBTC(res?.data?.requestWithdrawalId, "Reject", token, (res) => {
                                        if (res.error) {
                                            if (res.message === "Token Expire") {
                                                dispatch({
                                                    type: LOGIN,
                                                    res,
                                                });
                                                history.push("/");
                                            }
                                        }
                                        document.getElementById("txid_hash").value = "";
                                        document.getElementById("amount").value = "";
                                        document.getElementById("pin").value = "";
                                        document.body.style.cursor = "default";
                                        if (res.error) {
                                            console.log("Error on reject withdrawal");
                                        }
                                    })
                                );
                            },
                        });
                    }
                })
            );
            setEnablesend(false);
            setTimeout(() => {
                document.getElementById("requestBTC").disabled = false;
                document.getElementById("requestBTC").style.opacity = "1";
            }, 3000);
        }
    };

    useEffect(() => {
        dispatch(
            getPreviousRequestAgentWithdrawalBTC(agentId, merchant, token, (res) => {
                //console.log("RES DATA", res);
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (!res.error) {
                    //console.log("RES DATA", res.data);
                    if (res.data) {
                        if (!isNaN(res.data.balance.balanceBTC)) {
                            setBalance(res.data.balance.balanceBTC);
                            setBalanceUSD(res.data.balance.balanceUSD);
                            setExchange(res.data.balance.exchange);
                            settwoFactorAuth(res.data.twoFactorAuth);
                        }
                        if (res.data.withdrawal) {
                            setWithdrawal(res.data.withdrawal);
                            if (res.data.withdrawal.payoutStatus === "waiting") {
                                setSteps(["Request Sent", "Processing", "Accepted"]);
                                setActiveStep(0);
                            } else if (res.data.withdrawal.payoutStatus === "approved") {
                                setSteps(["Request Sent", "Processing", "Accepted"]);
                                setActiveStep(1);
                            } else if (res.data.withdrawal.payoutStatus === "processed") {
                                setSteps(["Request Sent", "Processing", "Accepted"]);
                                setActiveStep(2);
                            } else if (res.data.withdrawal.payoutStatus === "declined") {
                                setSteps(["Request Sent", "Processed", "Declined"]);
                                setStyleMessage(1);
                                setActiveStep(2);
                            }
                        }
                    }
                }
            })
        );
    }, [dispatch, styleMessage, merchant, agentId]);

    var isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    if (withdrawal) {
        return (
            <div>
                <React.Fragment>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {styleMessage === 0 ? (
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "700",
                                                                    color: "#006600",
                                                                    fontSize: "18px",
                                                                }}
                                                            >
                                                                {getStepContent(withdrawal.payoutStatus === "declined" ? 3 : activeStep)}
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "700",
                                                                    color: "#CC0000",
                                                                }}
                                                            >
                                                                {getStepContent(withdrawal.payoutStatus === "declined" ? 3 : activeStep)}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Amount USD:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`$${parseFloat(withdrawal.amountUSD).toFixed(2)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Amount BTC:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`₿${parseFloat(withdrawal.amountBTC).toFixed(8)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Total Fees:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`₿${parseFloat(parseFloat(withdrawal.feeMerchantBTC) + parseFloat(withdrawal.feeSendBTC)).toFixed(8)} / $${parseFloat((parseFloat(withdrawal.feeMerchantBTC) + parseFloat(withdrawal.feeSendBTC)) * withdrawal.exchangeRate).toFixed(2)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Total Withdrawal:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`$${parseFloat(withdrawal.totalCredit).toFixed(8)} / $${parseFloat(withdrawal.totalCredit * withdrawal.exchangeRate).toFixed(2)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Exchange Rate:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`$${parseFloat(withdrawal.exchangeRate).toFixed(2)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Request Date:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{moment(withdrawal.createdAt).format("MM/DD/YYYY hh:mm")}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "center" }} colSpan={2}>
                                        <Stepper alternativeLabel activeStep={activeStep} connector={withdrawal.payoutStatus === "declined" ? <ColorlibConnectorDecline /> : <ColorlibConnector />}>
                                            {steps.map((label) => (
                                                <Step key={label}>
                                                    <StepLabel StepIconComponent={withdrawal.payoutStatus === "declined" ? ColorlibStepIconDecline : ColorlibStepIcon}>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "center" }} colSpan={2}>
                                        <hr></hr>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            </div>
        );
    } else {
        return (
            <React.Fragment>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "5%" }} style={{ borderBottom: "none" }}>
                                    <Typography style={{ fontSize: isMobile ? "14px" : "18px" }}>Amount:</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ width: "95%" }} style={{ borderBottom: "none" }}>
                                    <CssTextField
                                        placeholder=""
                                        name="amount"
                                        id="amount"
                                        fullWidth
                                        size="small"
                                        type="number"
                                        variant="outlined"
                                        onChange={handleChange}
                                        sx={{
                                            backgroundColor: "#f6f9fc",
                                            width: "700px",
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{coinSimbol}</InputAdornment>,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "5%" }} style={{ borderBottom: "none" }}>
                                    <Typography sx={{ fontWeight: "700" }}></Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ width: "95%" }} style={{ borderBottom: "none" }}>
                                    <Button type="submit" variant="contained" className={classes.submit} onClick={handleCoinSimbol} id="maxamount" style={{ marginRight: "10px" }}>
                                        {coinSimbolButton}
                                    </Button>
                                    <Button type="submit" variant="contained" className={classes.submit} onClick={handleMax} id="maxamount">
                                        {"Max"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "5%" }} style={{ borderBottom: "none" }}>
                                    <Typography sx={{ fontWeight: "700" }}></Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ width: "95%" }} style={{ borderBottom: "none" }}>
                                    <Typography style={{ fontSize: isMobile ? "14px" : "18px" }}>{amountOtherCoin}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "5%" }}>
                                    <Typography style={{ fontSize: isMobile ? "14px" : "18px" }}>Current Balance:</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ width: "95%" }}>
                                    <Typography style={{ fontSize: isMobile ? "14px" : "18px" }}>{` ₿ ${balance} / Aprox USD: $ ${balanceUSD}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "5%" }}>
                                    <Typography style={{ fontSize: isMobile ? "14px" : "18px" }}>Exchange Rate:</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ width: "95%" }}>
                                    <Typography style={{ fontSize: isMobile ? "14px" : "18px" }}>{`$ ${parseFloat(exchange).toFixed(2)}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "5%" }}>
                                    <Typography style={{ fontSize: isMobile ? "14px" : "18px" }}>Bitcoin Address:</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ width: "95%" }}>
                                    <CssTextField
                                        placeholder="Address where you would like your funds to be sent."
                                        name="txid_hash"
                                        id="txid_hash"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        onChange={handleChange}
                                        sx={{
                                            backgroundColor: "#f6f9fc",
                                            width: "700px",
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            {twoFactorAuth === true ? (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell style={{ borderBottom: "none" }} align="center">
                                                        <Typography className={classes.textoSec}>{"Security Verification"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ borderBottom: "none" }} align="center">
                                                        <Typography className={classes.textoAuth}>{"Authenticador Code"}</Typography>
                                                        <CssTextField
                                                            placeholder=""
                                                            name="pin"
                                                            id="pin"
                                                            size="small"
                                                            type="number"
                                                            variant="outlined"
                                                            onChange={handleChangePin}
                                                            sx={{
                                                                backgroundColor: "#f6f9fc",
                                                                width: "700px",
                                                            }}
                                                        />
                                                        <Typography className={classes.sixDigit}>{"Enter the 6-digit code from FlashTeller/Google Authenticator"}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            ) : null}

                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Button type="submit" variant="contained" className={classes.submit} onClick={handleSubmit} id="requestBTC" disabled={enablesend}>
                                        {"Send Request"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
                <ConfirmDialog confirmDialog={confirmReject} setConfirmDialog={setConfirmReject} cryptoCurrency={"BTC"} />
            </React.Fragment>
        );
    }
};

export default AgentRequestWithdrawalBTC;
