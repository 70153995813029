import React,{useEffect,useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

//Core
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from '@material-ui/core/TablePagination';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

//Icons
import Delete from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

//Functions
import {getSentMessages,deleteMessage} from '../../actions/agent'

//Styles
import styles from '../../assets/styles/components/agentsListStyle';

const useStyles = makeStyles(styles);

function Row(props){
  const dispatch = useDispatch();
  const {message} = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })

  const onDeleteClick = (messageId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
    dispatch(deleteMessage(messageId,true));
  }

  return(
    <>
      <TableRow key={message._id} className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {message?.from?.username}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {moment(message.createdAt).format("YYYY/MM/DD HH:mm")}
        </TableCell>              
        <TableCell className={classes.tableActions}>
          <Tooltip
            id="tooltip-top"
            title="Delete Message"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              aria-label="Delete"
              className={classes.tableActionButton}
              onClick={() => {                
                setConfirmDialog({
                    isOpen: true,
                    title: 'Are you sure to delete this message?',
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {onDeleteClick(message._id)}
                })
              }}
            >
              <Delete
                className={
                  classes.tableActionButtonIcon + " " + classes.transfer
                }
              />
            </IconButton>
          </Tooltip>                                                
        </TableCell>
      </TableRow>
      <TableRow key={4564}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table className={classes.tableResponsive}>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={4565}
                    >
                      From:
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={4566}
                    >
                      {message.from.username}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={4567}>
                    <TableCell className={classes.tableCell} colSpan={2}>
                      {message.message}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
      />
    </>
  )
}

const SentMessages = (props) => {
  const {agentId} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  let start = 0;

  const [length, setLength] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    start = length * newPage;
    dispatch(getSentMessages(agentId,start,length));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLength(parseInt(event.target.value, 10));    
    setPage(0);
  };

  const onDeleteClick = () => {

  }

  const tableHead = ['','From', 'Date'];

  useEffect(() => {
    dispatch(getSentMessages(agentId,start,length))
  }, [start,length,dispatch])

  let messages = useSelector(store => store.agent.sentmessages);
  const recordsTotal = useSelector(store => store.agent.totalSentMessages);

  return messages?(
    <div>
      <Table className={classes.tableResponsive}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            {tableHead.map((prop, key) => {
              return (
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={key}
                >
                  {prop}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map(message => (
            <Row key={message._id} message={message} />
          ))}        
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={recordsTotal?recordsTotal:0}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={length}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  ):null
}

export default SentMessages
