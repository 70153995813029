import React from "react";
import Button from "@material-ui/core/Button";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from "@material-ui/core";
import Logo from "../../assets/img/logo-lg-light.png";
import LogoSmall from "../../assets/img/logo-small.png";
//import BG from "../../assets/img/bg2.png";

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(2),
        position: "absolute",
        top: theme.spacing(5),
    },
    dialogTitle: {
        //textAlign: 'center',
        //backgroundColor: 'orange',
        //backgroundImage: `url(${BG})`,
        //backgroundPosition: 'center',
        //backgroundSize: 'cover',
        //backgroundRepeat: 'no-repeat',
        alignContent: "center",
        textAlign: "center",
    },
    dialogContentred: {
        textAlign: "center",
        color: "red",
    },
    dialogContentgreen: {
        textAlign: "center",
        color: "black",
    },
    dialogAction: {
        justifyContent: "center",
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
            cursor: "default",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "8rem",
        },
    },
    submitgreen: {
        backgroundColor: "#034AFF",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#03b7ff",
            color: "white",
        },
    },
    submitred: {
        backgroundColor: "#F0150F",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#CDCBCD",
            color: "black",
        },
    },
    textotittlered: {
        color: "red",
        fontWeight: "bold",
        fontSize: "22px",
    },
    textotittlegreen: {
        color: "green",
        fontWeight: "bold",
        fontSize: "22px",
    },
    textomessage: {
        fontWeight: "bold",
    },
}));

export default function OkDialog(props) {
    const { okDialog, setOkDialog } = props;
    const classes = useStyles();

    return (
        <Dialog open={okDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography className={okDialog?.error === true ? classes.textotittlered : classes.textotittlegreen}>{okDialog.title}</Typography>
            </DialogTitle>
            <DialogContent className={okDialog?.error === true ? classes.dialogContentred : classes.dialogContentgreen} style={{ textAlign: okDialog.justify === true ? "justify" : "" }}>
                <Typography variant="subtitle2" className={okDialog?.messageBold === true ? classes.textomessage : ""}>
                    {okDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button className={okDialog?.error === true ? classes.submitred : classes.submitgreen} color="default" onClick={okDialog.onConfirm}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
