import React,{useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/MainDashBoard';
import { verifytoken } from './actions/login';
import {useSelector,useDispatch} from 'react-redux';
import { LOGINEND } from './constants/actionTypes';

const App = () => {  
  //const user = JSON.parse(localStorage.getItem('profile')); 
  const rutaServer = "";//Pruebas 
  const dispatch = useDispatch();
  //const rutaServer = "/app.playerteller";//Produccion

  const login = useSelector((state) => state?.login?.loginData?.login);
   
  const token = useSelector((state) => state?.login?.loginData?.token);
  //console.log('LOGIN', login);
  const [isAuthenticated, setIsAuthenticated] = useState(login);
  //console.log('IS AUTHENTICATE', isAuthenticated); 

  /*if(isAuthenticated === true){
    verifytoken( token, response => {
      if(response){
        if(response.ok !== undefined){
          if(response.ok === false || response.ok === false || response.ok === 'false'){
            let data = {
              error:false,
              message: '', 
              address: '',
              login: false,
              twoFactorAuth: null, 
              agentId: null, 
              token: null, 
              merchant: null
            };
            dispatch({type: LOGINEND, data});
          }
        }
      }
    });
  }*/

  return(
    <Router forceRefresh={true}>
      <Switch>
        <PrivateRoute path={`${rutaServer}/admin`} component={Dashboard}/>            
        <PublicRoute path={`${rutaServer}/`} component={Login} />        
      </Switch>     
    </Router>    
  );

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          login === true ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }
  function PublicRoute({ component,componentadmin, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          React.createElement(component, props)
        }
      />
    );
  }

}

export default App;