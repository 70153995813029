import React, { useState } from "react";
import { TextField, Grid, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import UseStyles from "./styles";

const Input = ({ half, name, label, autoFocus, type, handleShowPassword, handleChange, disabled, fullWidth, id, minValue, maxValue, adorment }) => {
    const classes = UseStyles();
    const min = minValue;
    const max = maxValue;
    const [value, setValue] = useState(0);
    return (
        <Grid item xs={12} sm={half ? 6 : 12}>
            <TextField
                name={name}
                className={classes.textfield}
                variant="outlined"
                required={true}
                label={label}
                autoFocus={autoFocus}
                disabled={disabled}
                fullWidth={fullWidth}
                value={value}
                onChange={(e) => {
                    var value = parseFloat(e.target.value);

                    if (value > max) value = max;
                    if (value < min) value = min;
                    setValue(value);
                    handleChange({ target: { value: value, name: e.target.name } });
                }}
                size="small"
                id={id}
                type={type}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{adorment ? (adorment === "none" ? "" : adorment) : "$"}</InputAdornment>,
                }}
            />
        </Grid>
    );
};

export default Input;
