import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { green, grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import { TextField, InputAdornment, IconButton, Input, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { insertNewAgent } from "../../actions/agent";
import OkDialog from "../../components/ConfirmDialog/OkDialog";
import BG from "../../assets/img/bg2.png";
import Logo from "../../assets/img/logo.png";
import Checkbox from "@material-ui/core/Checkbox";
import { whiteColor } from "../../assets/styles/components/materialdashboard";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        textAlign: "center",
        backgroundColor: "#034AFF",
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: whiteColor,
    },
});

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "10px",
    },
    title: {
        marginTop: "50px",
        marginBottom: "30px",
        color: "#333333",
        fontWeight: "700",
    },
    topTitle: {
        fontSize: "18px",
        fontWeight: "700",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    input: {
        width: "100%",
        marginBottom: "10px",
    },

    inputcmb: {
        marginTop: "10px",
    },

    alerttext: {
        color: "red",
    },

    submitdiv: {
        width: "100%",
        textAlign: "center",
        margin: "auto 0 auto 0",
    },

    submit: {
        backgroundColor: "#333333",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        textAlign: "center",
        borderRadius: "10px",
        "&:hover": {
            backgroundColor: "#000000",
            color: "white",
        },
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const AddAgent = (props) => {
    const { openCreateAgent, setOpenCreateAgent, merchants } = props;
    //console.log("Mer", merchants);
    const [okDialog, setOkDialog] = useState({ isOpen: false, title: "", subTitle: "" });
    const classes = useStyles();
    const dispatch = useDispatch();
    const [postData, setPostData] = useState({
        agentExternalId: "",
        username: "",
        password: "",
        name: "",
        merchant: "",
        telegram: "",
        webSiteUrl: "",
        freeplayBTC: false,
        freeplayBTCPercentage: "",
        freeplayLN: false,
        freeplayLNPercentage: "",
        freeplayETH: false,
        freeplayETHPercentage: "",
        freeplayUSDT: false,
        freeplayUSDTPercentage: "",
        freeplayUSDC: false,
        freeplayUSDCPercentage: "",
        depositBTC: false,
        depositLN: false,
        depositETH: false,
        depositUSDT: false,
        depositUSDC: false,
        withdrawBTC: false,
        withdrawLN: false,
        withdrawETH: false,
        withdrawUSDT: false,
        withdrawUSDC: false,
    });
    const handleClose = () => {
        setOpenCreateAgent({
            open: false,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(
            insertNewAgent(postData, (data) => {
                if (data?.error) {
                    setOkDialog({
                        isOpen: true,
                        title: "Error creating Agent.",
                        subTitle: data?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                } else {
                    setOkDialog({
                        isOpen: true,
                        title: "Agent created successfully.",
                        subTitle: "",
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                            setOpenCreateAgent({
                                isOpen: false,
                            });
                        },
                    });
                }
            })
        );
    };

    const onChangeMerchant = (e, v) => {
        setPostData({ ...postData, merchant: v });
        //console.log("Value ", v);
    };

    return merchants ? (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openCreateAgent?.isOpen || false} maxWidth="sm" fullWidth={true}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography className={classes.topTitle}>{`ADD AGENT`}</Typography>
            </DialogTitle>
            <MuiDialogContent>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <form className={classes.form} noValidate onSubmit={onSubmit}>
                            <TextField name="agentExternalId" id="agentExternalId" className={classes.input} variant="outlined" label="Agent External Id" fullWidth value={postData.agentExternalId} onChange={(e) => setPostData({ ...postData, agentExternalId: e.target.value })} />
                            <br />
                            <Autocomplete id="merchant" style={{ width: 150 }} options={merchants} getOptionLabel={(option) => option.username} renderInput={(params) => <TextField {...params} label="Merchant" margin="normal" />} onChange={onChangeMerchant} />
                            <br />
                            <TextField name="username" id="username" className={classes.input} variant="outlined" label="Username" fullWidth value={postData.username} onChange={(e) => setPostData({ ...postData, username: e.target.value })} />
                            <br />
                            <TextField name="password" id="password" className={classes.input} variant="outlined" label="Password" fullWidth value={postData.password} onChange={(e) => setPostData({ ...postData, password: e.target.value })} />
                            <br />
                            <TextField name="name" id="name" className={classes.input} variant="outlined" label="Name" fullWidth value={postData.name} onChange={(e) => setPostData({ ...postData, name: e.target.value })} />
                            <br />
                            <TextField name="telegram" id="telegram" className={classes.input} variant="outlined" label="Telegram" fullWidth value={postData.telegram} onChange={(e) => setPostData({ ...postData, telegram: e.target.value })} />
                            <br />
                            <TextField name="webSiteUrl" id="webSiteUrl" className={classes.input} variant="outlined" label="Website Url" fullWidth value={postData.webSiteUrl} onChange={(e) => setPostData({ ...postData, webSiteUrl: e.target.value })} />
                            <br />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, freeplayBTC: e.target.checked })} name="freeplayBTC" color="primary" />} label="Free Play BTC" />
                            <br />
                            <TextField name="freeplayBTCPercentage" id="freeplayBTCPercentage" className={classes.input} variant="outlined" label="Free Play BTC Percentage" value={postData.freeplayBTCPercentage} onChange={(e) => setPostData({ ...postData, freeplayBTCPercentage: e.target.value })} />
                            <br />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, freeplayLN: e.target.checked })} name="freeplayLN" color="primary" />} label="Free Play Lightning" />
                            <br />
                            <TextField name="freeplayLNPercentage" id="freeplayLNPercentage" className={classes.input} variant="outlined" label="Free Play Lightning Percentage" value={postData.freeplayLNPercentage} onChange={(e) => setPostData({ ...postData, freeplayLNPercentage: e.target.value })} />
                            <br />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, freeplayETH: e.target.checked })} name="freeplayETH" color="primary" />} label="Free Play Ethereum" />
                            <br />
                            <TextField name="freeplayETHPercentage" id="freeplayETHPercentage" className={classes.input} variant="outlined" label="Free Play Ethereum Percentage" value={postData.freeplayETHPercentage} onChange={(e) => setPostData({ ...postData, freeplayETHPercentage: e.target.value })} />
                            <br />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, freeplayUSDT: e.target.checked })} name="freeplayUSDT" color="primary" />} label="Free Play USDT" />
                            <br />
                            <TextField name="freeplayUSDTPercentage" id="freeplayUSDTPercentage" className={classes.input} variant="outlined" label="Free Play USDT Percentage" value={postData.freeplayUSDTPercentage} onChange={(e) => setPostData({ ...postData, freeplayUSDTPercentage: e.target.value })} />
                            <br />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, freeplayUSDC: e.target.checked })} name="freeplayUSDC" color="primary" />} label="Free Play USDC" />
                            <br />
                            <TextField name="freeplayUSDCPercentage" id="freeplayUSDCPercentage" className={classes.input} variant="outlined" label="Free Play USDC Percentage" value={postData.freeplayUSDCPercentage} onChange={(e) => setPostData({ ...postData, freeplayUSDCPercentage: e.target.value })} />
                            <br />
                            <Typography style={{ fontWeight: "bold" }}>Depostis</Typography>
                            <br />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, depositBTC: e.target.checked })} name="depositBTC" color="primary" />} label="BTC" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, depositLN: e.target.checked })} name="depositLN" color="primary" />} label="Lightning Network" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, depositETH: e.target.checked })} name="depositETH" color="primary" />} label="ETH" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, depositUSDT: e.target.checked })} name="depositUSDT" color="primary" />} label="USDT" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, depositUSDC: e.target.checked })} name="depositUSDC" color="primary" />} label="USDC" />
                            <br />
                            <Typography style={{ fontWeight: "bold" }}>Withdrawals</Typography>
                            <br />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, withdrawBTC: e.target.checked })} name="withdrawBTC" color="primary" />} label="BTC" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, withdrawLN: e.target.checked })} name="withdrawLN" color="primary" />} label="Lightning Network" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, withdrawETH: e.target.checked })} name="withdrawETH" color="primary" />} label="ETH" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, withdrawUSDT: e.target.checked })} name="withdrawUSDT" color="primary" />} label="USDT" />
                            <FormControlLabel control={<Checkbox onChange={(e) => setPostData({ ...postData, withdrawUSDC: e.target.checked })} name="withdrawUSDC" color="primary" />} label="USDC" />
                            <br />
                            <br />
                            <div className={classes.submitdiv}>
                                <Button type="submit" variant="contained" className={classes.submit}>
                                    ADD AGENT
                                </Button>
                            </div>
                            <br />
                        </form>
                    </div>
                </Container>
            </MuiDialogContent>
            <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
        </Dialog>
    ) : null;
};

export default AddAgent;
