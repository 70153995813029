import { LOGIN,LOGIN_ERROR } from '../constants/actionTypes';
import * as api from '../api/login';

export const login = (formData, history, callback) => async(dispatch) => {
  try {
    const { data } = await api.login(formData);
    if(data.error){
      dispatch({type: LOGIN_ERROR, data});
      callback(data);      
    }else{            
      //console.log('LOGIN DATA', data.twoFactorAuth);
      if(data.twoFactorAuth === true){
        callback(data);
      }else{
        await dispatch({type: LOGIN, data});
        history.push("/admin");
      }      
    }          
  } catch (error) {
    //dispatch({type: LOGIN_ERROR, error: 'Wrong username or password'});
    //history.push('/');
  }
}

export const verifytoken = async (token, callback) => {
  try {
    const { data } = await api.verifytoken(token);
    if(data.ok){
      callback(data);      
    }else{      
      callback({ok:false,error:'Invalid Token'});
    }           
  } catch (error) {
    callback({ok:false,error:'Invalid Token'});
  }
}
