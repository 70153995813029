import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

//Core
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import { IconButton, TableContainer, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";

//Styles
import styles from "../../assets/styles/components/agentsListStyle";

//Functions
import { getWithdrawals } from "../../actions/agent";
import { LOGIN } from "../../constants/actionTypes";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";

const useStyles = makeStyles(styles);

const Withdrawals = (props) => {
    const classes = useStyles();
    const [totals, setTotals] = useState({});
    const { agentId, merchant } = props;
    const dispatch = useDispatch();
    const now = moment();
    const history = useHistory();
    const token = useSelector((state) => state?.login?.loginData?.token);

    let subTotCryptoAmount = 0;
    let subTotUSD = 0;
    let subTotFees = 0;
    let subTotTotal = 0;

    let start = 0;
    let startDate = now.startOf("day");

    const [length, setLength] = useState(10);
    const [page, setPage] = useState(0);
    const [withdrawalsList, setWithdrawalsList] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);

    const handleCopy = (trid) => {
        navigator.clipboard.writeText(trid);
    };

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getWithdrawals(agentId, merchant, start, length, startDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, 10));
        setPage(0);
    };

    const tableHead = ["Date", "Agent", "Status", "Type", "Crypto Amount", "Exchange", "$USD", "Fees", "Total", "Transaction Id"];

    useEffect(() => {
        dispatch(
            getWithdrawals(agentId, merchant, start, length, startDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    setWithdrawalsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setWithdrawalsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    }, [start, length, dispatch]);

    if (withdrawalsList) {
        if (withdrawalsList.length === 0) {
            return <div style={{ textAlign: "center" }}>No records found</div>;
        }
    }

    if (withdrawalsList === undefined) {
        return <div style={{ textAlign: "center" }}>No records found</div>;
    }

    if (withdrawalsList.length === 0) {
        return (
            <TableContainer>
                <Table className={classes.tableResponsive}>
                    <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell colSpan={11} key={"norecords1"}>
                                <div style={{ textAlign: "center" }}>No records found</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        );
    } else {
        return (
            <TableContainer>
                <Table className={classes.tableResponsive}>
                    <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {withdrawalsList.map((withdrawal, index) => {
                            subTotCryptoAmount += withdrawal.isLN === "true" ? parseFloat(withdrawal.cryptoAmount / 100000000) : parseFloat(withdrawal.cryptoAmount);
                            subTotUSD += withdrawal.currencyAmount;
                            subTotFees += parseFloat(withdrawal.feeMerchantAmount + withdrawal.feeAmountChain);
                            subTotTotal += withdrawal.isLN === true ? parseFloat(withdrawal.currencyAmount + (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain)) : parseFloat(withdrawal.currencyAmount - (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain));
                            return (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell className={classes.tableCell}>{moment(withdrawal.createdAt).format("MM/DD/YYYY HH:mm")}</TableCell>
                                    <TableCell className={classes.tableCell}>{withdrawal.agent}</TableCell>
                                    <TableCell className={classes.tableCell}>{withdrawal.payoutStatus}</TableCell>
                                    <TableCell className={classes.tableCell}>{withdrawal.isLN === true && withdrawal.cryptoCurrency === "BTC" ? "Lightning" : withdrawal.isLN === false && withdrawal.cryptoCurrency === "BTC" ? "BTC Mainnet" : withdrawal.cryptoCurrency}</TableCell>
                                    <TableCell className={classes.tableCell}>{`${parseFloat(withdrawal.cryptoAmount).toFixed(withdrawal.cryptoCurrency === "BTC" ? 8 : withdrawal.cryptoCurrency === "ETH" ? 18 : withdrawal.cryptoCurrency === "USDT" ? 6 : withdrawal.cryptoCurrency === "USDC" ? 6 : 2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${withdrawal.exchangeRate}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.currencyAmount).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.feeMerchantAmount + withdrawal.feeAmountChain).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(withdrawal.isLN === true ? withdrawal.currencyAmount + withdrawal.feeMerchantAmount + withdrawal.feeAmountChain : withdrawal.currencyAmount - (withdrawal.feeMerchantAmount + withdrawal.feeAmountChain)).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {`${withdrawal.txid_hash ? withdrawal.txid_hash.toString().substring(0, 6) + "..." : ""}`}
                                        {withdrawal.txid_hash ? (
                                            <Tooltip title={withdrawal.txid_hash}>
                                                <IconButton aria-label="delete" onClick={() => handleCopy(withdrawal.txid_hash)}>
                                                    <CopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow key={"subTotals"} className={classes.tableRow}>
                            <TableCell className={classes.tableCellSubTotal}>{"SubTotal"}</TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotUSD).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotFees).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotTotal).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                        {!isNaN(totals.mainTotalBTC) ? (
                            <>
                                <TableRow key={"grandTotal"} className={classes.tableRow}>
                                    <TableCell className={classes.tableCellGrandotal}>{"Grand Total"}</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalUSD).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalFees).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalTotal).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                </TableRow>
                            </>
                        ) : null}
                    </TableBody>
                </Table>
                <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
        );
    }
};

export default Withdrawals;
