import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgentsList } from "../../actions/agent";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import AddAgent from "../Dialogs/AddAgent";

const useStyles = makeStyles({
    root: {
        width: "100%",
        overflowX: "auto",
    },
    table: {
        minWidth: 650,
    },
    backGray: {
        backgroundColor: "#999999",
    },
});

const NewExpandableRow = (row) => {
    let hasCh = row?.data?.length > 0 ? true : false;
    if (hasCh) {
        return (
            <ExpandableTableRow key={row.name} row={row}>
                <TableCell component="th" scope="row">
                    {row.agentExternalId}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.webSiteUrl}</TableCell>
                <TableCell align="right">{row.active ? "Yes" : "No"}</TableCell>
                <TableCell align="right">{`₿${parseInt(row.balance) > 0 ? parseFloat(row.balance) / 100000000 : 0}`}</TableCell>
            </ExpandableTableRow>
        );
    } else {
        return (
            <TableRow key={row.name} style={{ backgroundColor: "#999999" }}>
                <TableCell padding="checkbox" />
                <TableCell component="th" scope="row">
                    {row.agentExternalId}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.webSiteUrl}</TableCell>
                <TableCell align="right">{row.active ? "Yes" : "No"}</TableCell>
                <TableCell align="right">{`₿${parseInt(row.balance) > 0 ? parseFloat(row.balance) / 100000000 : 0}`}</TableCell>
            </TableRow>
        );
    }
};

const ExpandableTableRow = ({ children, expandComponent, row, hasChilds, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <TableRow {...otherProps}>
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                </TableCell>
                {children}
            </TableRow>
            {isExpanded
                ? row.data.map((rw) => {
                      return NewExpandableRow(rw);
                  })
                : ""}
        </>
    );
};

const AllAgentsList = (props) => {
    const { agentId } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const now = moment();
    let start = 0;
    let startDate = now.startOf("day");
    let search = "";

    const [length, setLength] = useState(10);
    const [page, setPage] = useState(0);
    const [agentsList, setAgentsList] = useState([]);
    const [merchants, setMerchants] = useState([]);
    const [exchange, setEchange] = useState({});
    const [totals, setTotals] = useState({});
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [openCreateAgent, setOpenCreateAgent] = useState({ isOpen: false, title: "", subTitle: "" });

    const isAdmin = useSelector((state) => state.agent?.isAdmin);

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        getAllAgentsList(agentId, start, length, startDate, search, (data) => {
            setAgentsList(data.data);
            setEchange(data.exchange);
            setTotals(data.totals);
            setRecordsTotal(data.count);
        });
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchChange = (e) => {
        e.preventDefault();
        search = e.target.value;
        getAllAgentsList(agentId, start, length, startDate, search, (data) => {
            setAgentsList(data.data);
            setEchange(data.exchange);
            setTotals(data.totals);
            setRecordsTotal(data.count);
        });
    };

    const openAddAgent = (e) => {
        e.preventDefault();
        setOpenCreateAgent({
            isOpen: true,
            title: "Add Agent",
            subTitle: "",
            onConfirm: () => {
                setOpenCreateAgent({
                    open: false,
                });
            },
        });
    };

    useEffect(() => {
        getAllAgentsList(agentId, start, length, startDate, search, (data) => {
            setAgentsList(data.data);
            //console.log('DATAAAAAA', data.data);
            setEchange(data.exchange);
            setTotals(data.totals);
            setMerchants(data.merchants);
            setRecordsTotal(data.count);
        });
    }, [start, length, dispatch]);

    return agentsList ? (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {isAdmin === true ? (
                            <TableCell colSpan={2} className={classes.tableCell} key={"addbutton"}>
                                <Button variant="contained" className={classes.submit} startIcon={<AddIcon />} onClick={openAddAgent}>
                                    ADD AGENT
                                </Button>
                            </TableCell>
                        ) : null}
                        <TableCell colSpan={7} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                            <TextField
                                label="Search"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={searchChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell padding="checkbox" />
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Website</TableCell>
                        <TableCell align="right">Active</TableCell>
                        <TableCell align="right">Balance BTC</TableCell>
                        <TableCell align="right">Balance ETH</TableCell>
                        <TableCell align="right">Balance USDT</TableCell>
                        <TableCell align="right">Balance USDC</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {agentsList.map((row) => {
                        let hasCh = row?.agentExternalId !== "admin" ? true : false;
                        if (hasCh === true) {
                            return (
                                <TableRow key={row.agentExternalId} style={{ backgroundColor: "white" }}>
                                    <TableCell padding="checkbox" />
                                    <TableCell component="th" scope="row">
                                        {row.agentExternalId}
                                    </TableCell>
                                    <TableCell align="right">{row.name}</TableCell>
                                    <TableCell align="right">{row.webSiteUrl}</TableCell>
                                    <TableCell align="right">{row.active ? "Yes" : "No"}</TableCell>
                                    <TableCell align="right">{`${parseFloat(row.balanceBTC) > 0 ? parseFloat(parseFloat(row.balanceBTC)).toFixed(8) : 0}`}</TableCell>
                                    <TableCell align="right">{`${parseFloat(row.balanceETH) > 0 ? parseFloat(parseFloat(row.balanceETH)).toFixed(18) : 0}`}</TableCell>
                                    <TableCell align="right">{`${parseFloat(row.balanceUSDT) > 0 ? parseFloat(parseFloat(row.balanceUSDT)).toFixed(6) : 0}`}</TableCell>
                                    <TableCell align="right">{`${parseFloat(row.balanceUSDC) > 0 ? parseFloat(parseFloat(row.balanceUSDC)).toFixed(6) : 0}`}</TableCell>
                                </TableRow>
                            );
                        }
                    })}
                    <TableRow key={"totals"} style={{ backgroundColor: "white" }}>
                        <TableCell padding="checkbox" />
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>{`${parseFloat(totals.totalBTC) > 0 ? parseFloat(parseFloat(totals.totalBTC)).toFixed(8) : 0}`}</TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>{`${parseFloat(totals.totalETH) > 0 ? parseFloat(parseFloat(totals.totalETH)).toFixed(18) : 0}`}</TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>{`${parseFloat(totals.totalUSDT) > 0 ? parseFloat(parseFloat(totals.totalUSDT)).toFixed(6) : 0}`}</TableCell>
                        <TableCell align="right" style={{ fontWeight: "bold" }}>{`${parseFloat(totals.totalUSDC) > 0 ? parseFloat(parseFloat(totals.totalUSDC)).toFixed(6) : 0}`}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
            <AddAgent openCreateAgent={openCreateAgent} setOpenCreateAgent={setOpenCreateAgent} merchants={merchants} />
        </Paper>
    ) : null;
};

export default AllAgentsList;
