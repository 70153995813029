import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

//Core
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import { TableContainer, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";

import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import { useHistory } from "react-router-dom";
import UpdateCustomer from "../Dialogs/Customer.js";

//Styles
import styles from "../../assets/styles/components/agentsListStyle";

import { getCustomers, updateCustomer } from "../../actions/agent";
import { LOGIN } from "../../constants/actionTypes";

const useStyles = makeStyles(styles);

const CustomTableCell = ({ row, name, onChange, type }) => {
    const classes = useStyles();
    const { isEditMode } = row;
    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? <Input type={type} value={row[name]} name={name} onChange={(e) => onChange(e, row)} className={classes.input} /> : row[name]}
        </TableCell>
    );
};

const CustomTableCellCheck = ({ row, name, onChangeCheck }) => {
    const classes = useStyles();
    const { isEditMode } = row;
    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? <Checkbox checked={row.active} onChange={(e) => onChangeCheck(e, row)} name={name} color="primary" /> : row.active ? "Yes" : "No"}
        </TableCell>
    );
};

const Customers = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { agentId, merchant } = props;
    const dispatch = useDispatch();
    const [previous, setPrevious] = useState(null);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [openCustomer, setOpenCustomer] = useState({ isOpen: false, title: "", subTitle: "" });
    const [postData, setPostData] = useState({
        id: "",
        name: "",
        notes: "",
        freePlayBTC: false,
        freePlayBTCPercentage: 0,
        freePlayLN: false,
        freePlayLNPercentage: 0,
    });
    const now = moment();
    let search = "";

    //const customersList = useSelector((store) => store.agent.customers);
    const token = useSelector((state) => state?.login?.loginData?.token);

    const [rows, setRows] = useState([]);

    const onToggleEditMode = (id) => {
        let cust = null;
        rows.map((row) => {
            if (row._id === id) {
                cust = row;
            }
        });
        setPostData({
            id: id,
            name: cust.name,
            notes: cust.notes,
            freePlayBTC: cust.freePlayBTC,
            freePlayBTCPercentage: cust.freePlayBTCPercentage,
            freePlayLN: cust.freePlayLN,
            freePlayLNPercentage: cust.freePlayLNPercentage,
        });
        setOpenCustomer({
            isOpen: true,
            title: "Update Customer",
            subTitle: "",
            id: cust._id,
            onConfirm: () => {
                setOpenCustomer({
                    open: false,
                });
                getCustomers(agentId, merchant, start, length, startDate, search, token, (data) => {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch({ type: LOGIN, data });
                            history.push("/");
                        }
                    }
                    setRows(data.data);
                    setRecordsTotal(data.count);
                });
            },
        });
    };

    let start = 0;
    let startDate = now.startOf("day");

    const [length, setLength] = useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        setRows([]);
        getCustomers(agentId, merchant, start, length, startDate, search, token, (data) => {
            if (data.error) {
                if (data.message === "Token Expire") {
                    dispatch({ type: LOGIN, data });
                    history.push("/");
                }
            }
            setRows(data.data);
            setRecordsTotal(data.count);
        });
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, 10));
        setPage(0);
    };

    let tableHead = [];
    tableHead = ["Customer", "Agent", "Name", "Deposits", "Total Deposits", "Withdrawals", "Total Withdraw", "Notes"]; //, "Specific Free Play %"

    const searchChange = (e) => {
        e.preventDefault();
        search = e.target.value;
        //console.log("CUSTOMERS LIST", search);
        getCustomers(agentId, merchant, start, length, startDate, search, token, (data) => {
            //console.log("CUSTOMERS LIST", data);
            if (data.error) {
                if (data.message === "Token Expire") {
                    dispatch({ type: LOGIN, data });
                    history.push("/");
                }
            }
            setRows(data.data);
            setRecordsTotal(data.count);
        });
    };

    useEffect(() => {
        setRows([]);
        getCustomers(agentId, merchant, start, length, startDate, search, token, (data) => {
            //console.log("CUSTOMERS", data);
            if (data.error) {
                if (data.message === "Token Expire") {
                    dispatch({ type: LOGIN, data });
                    history.push("/");
                }
            }
            setRows(data.data);
            setRecordsTotal(data.count);
        });
    }, [start, length, dispatch]);

    return rows.length > 0 ? (
        <TableContainer>
            <Table className={classes.tableResponsive}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                            <TextField
                                label="Search"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={searchChange}
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow className={classes.tableHeadRow}>
                        <TableCell align="left" className={classes.tableHeadRow} />
                        {tableHead.map((prop, key) => {
                            return (
                                <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                    {prop}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row._id} className={classes.tableRow}>
                            <TableCell className={classes.selectTableCell}>
                                <IconButton aria-label="delete" onClick={() => onToggleEditMode(row._id)}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell className={classes.tableCell}>{row.customer}</TableCell>
                            <TableCell className={classes.tableCell}>{`${row.subAgentName}`}</TableCell>
                            <TableCell className={classes.tableCell}>{`${row.name}`}</TableCell>
                            {/*<CustomTableCell className={classes.tableCell} {...{ row, name: "freePlay", onChange, type: "number" }}></CustomTableCell>*/}
                            <TableCell className={classes.tableCell}>{`${row.totalDeposits}`}</TableCell>
                            <TableCell className={classes.tableCell}>{`$${row.totalDepositsAmount}`}</TableCell>
                            <TableCell className={classes.tableCell}>{`${row.totalWithdrawals}`}</TableCell>
                            <TableCell className={classes.tableCell}>{`$${row.totalWithdrawalsAmount}`}</TableCell>
                            {/*<CustomTableCellCheck className={classes.tableCell} {...{ row, name: "active", onChangeCheck }}>
                  </CustomTableCellCheck>*/}
                            <TableCell className={classes.tableCell}>{`${row.notes}`}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
            <UpdateCustomer openCustomer={openCustomer} setOpenCustomer={setOpenCustomer} postData={postData} setPostData={setPostData} />
        </TableContainer>
    ) : null;
};

export default Customers;
