//Login
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGINEND = 'LOGINEND';

//Agent
export const AGENT_INFO = 'AGENT_INFO';
export const GET_DEPOSITS = 'GET_DEPOSITS';
export const GET_WITHDRAWALS = 'GET_WITHDRAWALS';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_SENT_MESSAGES = 'GET_SENT_MESSAGES';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const SEND_MESSAGE = 'DELETE_MESSAGE';
export const GET_AGENTS_FOR_MESSAGE = 'GET_AGENTS_FOR_MESSAGE';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_ALLAGENTS = 'GET_ALLAGENTS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';


//Figures
export const WEEKLY_DEPOSITS = 'WEEKLY_DEPOSITS';
export const MONTHLY_DEPOSITS = 'MONTHLY_DEPOSITS';
export const HOURLY_DEPOSITS = 'HOURLY_DEPOSITS';



