import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "../CustomInput/CustomInput";
import Button from "../CustomButtons/Button";
//import ChangePassword from '../Dialogs/AgentProfile';

import styles from "../../assets/styles/components/headerLinksStyle";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGOUT } from "../../constants/actionTypes";
import { Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
    const { totalunreadmessages, agentUser } = props;
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const agentSession = useSelector((store) => store.agent);
    const [openProfilePw, setOpenProfilePw] = React.useState({
        open: false,
        agentId: "",
    });
    const [openNotification, setOpenNotification] = React.useState(null);
    const [openProfile, setOpenProfile] = React.useState(null);
    const handleClickNotification = (event) => {
        /*if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
      history.push('/admin/messages');
    } else {
      setOpenNotification(event.currentTarget);
    }*/
        history.push("/admin/messages");
    };
    const handleCloseNotification = () => {
        setOpenNotification(null);
    };
    const handleClickProfile = (event) => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };
    const handleCloseProfile = (option) => {
        setOpenProfile(null);
        if (option === 3) {
            dispatch({ type: LOGOUT });
            history.push("/");
        } else if (option === 2) {
            history.push("/admin/settings");
        } else if (option === 5) {
            history.push("/admin/security");
        } else if (option === 4) {
            history.push("/admin/password");
        } else if (option === 1) {
            setOpenProfilePw({
                open: true,
                agentId: agentSession._id,
            });
        }
    };

    //console.log(agentSession);

    return (
        <div>
            {/*<div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          {totalunreadmessages?totalunreadmessages>0?
            <span className={classes.notifications}>{totalunreadmessages?totalunreadmessages:0}</span>
          :null:null
          }
          
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        {/*<Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Mike John responded to your email
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You have 5 new tasks
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You{"'"}re now friend with Andrew
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another Notification
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another One
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
            </Poppers>}
      </div>*/}
            <div className={classes.manager}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "none" }}>
                                <Typography style={{ color: "black", fontWeight: "bold" }}>{agentUser !== undefined ? agentUser : agentSession?.agentData?.data?.username}</Typography>
                            </TableCell>
                            <TableCell style={{ border: "none" }}>
                                <Button color={window.innerWidth > 959 ? "transparent" : "white"} justIcon={window.innerWidth > 959} simple={!(window.innerWidth > 959)} aria-owns={openProfile ? "profile-menu-list-grow" : null} aria-haspopup="true" onClick={handleClickProfile} className={classes.buttonLink}>
                                    <Person className={classes.icons} style={{ transform: "scale(1.5)", color: "black" }} />
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Poppers open={Boolean(openProfile)} anchorEl={openProfile} transition disablePortal className={classNames({ [classes.popperClose]: !openProfile }) + " " + classes.popperNav}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list-grow"
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList role="menu">
                                        {/*<MenuItem
                      onClick={() => handleCloseProfile(1)}
                      className={classes.dropdownItem}
                    >
                      <Typography style={{color:"#0560FB"}}>Profile</Typography> 
                    </MenuItem>*/}
                                        <MenuItem onClick={() => handleCloseProfile(4)} className={classes.dropdownItem}>
                                            <Typography style={{ color: "#0560FB" }}>Change Password</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleCloseProfile(5)} className={classes.dropdownItem}>
                                            <Typography style={{ color: "#0560FB" }}>Security</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => handleCloseProfile(2)} className={classes.dropdownItem}>
                                            <Typography style={{ color: "#0560FB" }}>Settings</Typography>
                                        </MenuItem>
                                        <Divider light />
                                        <MenuItem onClick={() => handleCloseProfile(3)} className={classes.dropdownItem}>
                                            <Typography style={{ color: "#0560FB" }}>Logout</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Poppers>
                {/*openProfilePw.open ===true ?<ChangePassword openProfilePw={openProfilePw} setOpenProfilePw={setOpenProfilePw}/>:null*/}
            </div>
        </div>
    );
}
