import { Button, Container, CssBaseline, Dialog, DialogContent as MuiDialogContent, DialogTitle, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { DateRange as DateRangeComponent } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "10px",
    },
    title: {
        marginTop: "50px",
        marginBottom: "30px",
        color: "#333333",
        fontWeight: "700",
    },
    topTitle: {
        fontSize: "18px",
        fontWeight: "700",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    input: {
        width: "100%",
        marginBottom: "10px",
    },

    inputcmb: {
        marginTop: "10px",
    },

    alerttext: {
        color: "red",
    },

    submitdiv: {
        width: "100%",
        textAlign: "center",
        margin: "auto 0 auto 0",
    },

    datebutton: {
        backgroundColor: "#034AFF",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: "20px",
        width: "150px",
        "&:hover": {
            backgroundColor: "#03b7ff",
            color: "white",
        },
    },

    celltable: {
        borderBottom: "none",
        marginTop: 0,
        padding: "5px",
    },

    dateRangeContainer: {
        width: "100%",

        [theme.breakpoints.down("sm")]: {
            width: "80%",
        },

        [theme.breakpoints.down("xs")]: {
            width: "90%",
        },
    },
}));

const DateRange = (props) => {
    const { openDateRange, setOpenDateRange, state, setState, processByDateRange } = props;

    //console.log("OPEN STATUS", openDateRange);

    const classes = useStyles();
    const handleClose = () => {
        setOpenDateRange({
            open: false,
        });
    };

    const handleToday = (e) => {
        e.preventDefault();
        let item = {
            startDate: new Date(),
            key: "Today",
            endDate: new Date(),
        };
        setState([item]);
        processByDateRange(item);
    };

    const handleThisWeek = (e) => {
        e.preventDefault();
        let item = {
            startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
            key: "Last 7 Days",
            endDate: new Date(new Date()),
        };
        setState([item]);
        processByDateRange(item);
    };

    const handleLastThirty = (e) => {
        e.preventDefault();
        let item = {
            startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
            key: "Last 30 Days",
            endDate: new Date(new Date()),
        };
        setState([item]);
        processByDateRange(item);
    };

    const handleThisMonth = (e) => {
        e.preventDefault();
        let item = {
            startDate: new Date(new Date().setDate(1)),
            key: "This Month",
            endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        };
        setState([item]);
        processByDateRange(item);
    };

    const handleLastMonth = (e) => {
        e.preventDefault();
        let item = {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
            key: "Last Month",
            endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
        };
        setState([item]);
        processByDateRange(item);
    };

    const handleChangeDatePicker = (item) => {
        //console.log("POR SELECCION", item);
        setState([item.selection]);
    };

    const handleApplyRange = () => {
        //console.log("ACTUAL STATE BY DATE RANGE", state);
        processByDateRange(state[0]);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDateRange?.isOpen || false} maxWidth="sm" fullWidth={false}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography className={classes.topTitle}>{`Date Range`}</Typography>
            </DialogTitle>
            <MuiDialogContent style={{ padding: 0 }}>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.celltable}>
                                    <Button type="submit" variant="contained" className={classes.datebutton} onClick={handleToday} id="today">
                                        {"Today"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.celltable}>
                                    <Button type="submit" variant="contained" className={classes.datebutton} onClick={handleThisWeek} id="today">
                                        {"Last 7 Days"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.celltable}>
                                    <Button type="submit" variant="contained" className={classes.datebutton} onClick={handleLastThirty} id="today">
                                        {"Last 30 Days"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.celltable}>
                                    <Button type="submit" variant="contained" className={classes.datebutton} onClick={handleThisMonth} id="today">
                                        {"This Month"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.celltable}>
                                    <Button type="submit" variant="contained" className={classes.datebutton} onClick={handleLastMonth} id="today">
                                        {"Last Month"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.celltable}>
                                    <div className={classes.dateRangeContainer}>
                                        <DateRangeComponent editableDateInputs={true} onChange={(item) => handleChangeDatePicker(item)} moveRangeOnFirstSelection={false} ranges={state} endDatePlaceholder={"End Date"} />
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.celltable} style={{ textAlign: "center" }}>
                                    <Button type="submit" variant="contained" className={classes.datebutton} onClick={handleApplyRange} id="byrange">
                                        {"Apply Range"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Container>
            </MuiDialogContent>
        </Dialog>
    );
};

export default DateRange;
