import React, { useState, useEffect } from "react";
import UseStyles from "./styles";
import Input from "./input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Typography, Container, TextField } from "@material-ui/core";
import { requestAgentWithdrawalETH, responseWithdrawalAgentETH } from "../../actions/withdrawal";
import { useDispatch } from "react-redux";
import OkDialog from "../Dialogs/OkDialog";
import ConfirmDialog from "../Dialogs/ConfirmReject";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { withStyles, makeStyles } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import PropTypes from "prop-types";
import SendIcon from "@material-ui/icons/Send";
import SettingsIcon from "@material-ui/icons/Settings";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { LOGIN } from "../../constants/actionTypes";

const initialState = {
    txid_hash: "",
    amount: 0,
    cryptoCurrency: "ETH",
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundImage: "linear-gradient( 95deg,rgb(0,100,0) 0%,rgb(0,128,0) 50%,rgb(34,139,34) 100%)",
        },
    },
    completed: {
        "& $line": {
            backgroundImage: "linear-gradient( 95deg,rgb(0,100,0) 0%,rgb(0,128,0) 50%,rgb(34,139,34) 100%)",
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
})(StepConnector);

const ColorlibConnectorDecline = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundImage: "linear-gradient( 95deg,rgb(178,34,34) 0%,rgb(255,0,0) 50%,rgb(255,99,71) 100%)",
        },
    },
    completed: {
        "& $line": {
            backgroundImage: "linear-gradient( 95deg,rgb(178,34,34) 0%,rgb(255,0,0) 50%,rgb(255,99,71) 100%)",
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
    },
})(StepConnector);

function getStepContent(step) {
    switch (step) {
        case 0:
            return "Your withdrawal request has been sent";
        case 1:
            return "Your withdrawal request is been processed";
        case 2:
            return "Your withdrawal request has been approved";
        case 3:
            return "Your withdrawal request has been declided";
        default:
            return "Unknown step";
    }
}

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundImage: "linear-gradient( 136deg, rgb(0,100,0) 0%, rgb(0,128,0) 50%, rgb(34,139,34) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundImage: "linear-gradient( 136deg, rgb(0,100,0) 0%, rgb(0,128,0) 50%, rgb(34,139,34) 100%)",
    },
});

const useColorlibStepIconStylesDecline = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
    },
    active: {
        backgroundImage: "linear-gradient( 136deg, rgb(178,34,34) 0%, rgb(255,0,0) 50%, rgb(255,99,71) 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
        backgroundImage: "linear-gradient( 136deg, rgb(178,34,34) 0%, rgb(255,0,0) 50%, rgb(255,99,71) 100%)",
    },
    decline: {
        backgroundImage: "linear-gradient( 136deg, rgb(178,34,34) 0%, rgb(255,0,0) 50%, rgb(255,99,71) 100%)",
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SendIcon />,
        2: <SettingsIcon />,
        3: <DoneOutlineIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

function ColorlibStepIconDecline(props) {
    const classes = useColorlibStepIconStylesDecline();
    const { active, completed } = props;

    const icons = {
        1: <SendIcon />,
        2: <SettingsIcon />,
        3: <CancelIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,

    declined: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const AgentRequestWithdrawalETH = () => {
    const classes = UseStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [formData, setFormData] = useState(initialState);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [confirmReject, setConfirmReject] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [withdrawal, setWithdrawal] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [balance, setBalance] = useState({
        balanceETH: 0,
        balanceUSDT: 0,
        balanceUSDC: 0,
    });
    const [actualBalance, setActualBalance] = useState(0);
    const [styleMessage, setStyleMessage] = useState(0);
    const [steps, setSteps] = useState(["Request Sent", "Processing", "Applied"]);

    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    const token = useSelector((state) => state?.login?.loginData?.token);
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    //const merchant = JSON.parse(localStorage.getItem('profile')).merchant;

    const handleChange = async (e) => {
        //console.log("EVENTO", e.target.name, e.target.value);
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeCurrency = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.value === "ETH") {
            setActualBalance(balance.balanceETH);
        } else if (e.target.value === "USDT") {
            setActualBalance(balance.balanceUSDT);
        } else if (e.target.value === "USDC") {
            setActualBalance(balance.balanceUSDC);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!e.currentTarget.disabled) {
            document.body.style.cursor = "wait";
            e.currentTarget.disabled = true;
            e.currentTarget.style.opacity = "0.5";
            dispatch(
                requestAgentWithdrawalETH(agentId, formData.txid_hash, formData.amount, merchant, formData.cryptoCurrency, token, (res) => {
                    if (res.error) {
                        if (res.message === "Token Expire") {
                            dispatch({ type: LOGIN, res });
                            history.push("/");
                        }
                    }
                    //console.log("CHEQUEO DE INVOICE RESULTADO FRONT END ", res);
                    document.body.style.cursor = "default";
                    if (res.error) {
                        //console.log("HUBO UN ERROR AL INGRESAR INVOICE");
                        setOkDialog({
                            isOpen: true,
                            title: "Error on withdrawal request.",
                            subTitle: res?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    } else {
                        let totalFee = parseFloat(parseFloat(res?.data?.feeSendUSD + res?.data?.feeMerchantUSD).toFixed(2));
                        let grandTotal = parseFloat(parseFloat(res?.data?.amountUSD - totalFee).toFixed(2));
                        setConfirmReject({
                            isOpen: true,
                            title: "Withdrawal Info.",
                            subTitle: res?.message,
                            totalUSD: res?.data?.amountUSD,
                            totalBTC: res?.data?.amountCrypto,
                            totalFee: totalFee,
                            grandTotal: grandTotal,
                            finalMessage: "Total after fees",
                            onConfirm: () => {
                                setConfirmReject({
                                    ...confirmReject,
                                    isOpen: false,
                                });
                                document.body.style.cursor = "wait";
                                dispatch(
                                    responseWithdrawalAgentETH(res?.data?.requestWithdrawalId, "Confirm", token, (res) => {
                                        if (res.error) {
                                            if (res.message === "Token Expire") {
                                                dispatch({
                                                    type: LOGIN,
                                                    res,
                                                });
                                                history.push("/");
                                            }
                                        }
                                        document.getElementById("txid_hash").value = "";
                                        document.getElementById("amount").value = "";
                                        document.body.style.cursor = "default";
                                        if (res.error) {
                                            setOkDialog({
                                                isOpen: true,
                                                title: "Error on withdrawal request.",
                                                subTitle: res?.message,
                                                onConfirm: () => {
                                                    setOkDialog({
                                                        ...okDialog,
                                                        isOpen: false,
                                                    });
                                                },
                                            });
                                        } else {
                                            setOkDialog({
                                                isOpen: true,
                                                title: "Withdrawal Applied",
                                                subTitle: `Your $${formData.amount} was applied`,
                                                onConfirm: () => {
                                                    setOkDialog({
                                                        ...okDialog,
                                                        isOpen: false,
                                                    });
                                                    setFormData({
                                                        txid_hash: "",
                                                        amount: 0,
                                                    });
                                                    window.location.reload();
                                                },
                                            });
                                        }
                                    })
                                );
                            },
                            onReject: () => {
                                setConfirmReject({
                                    ...confirmReject,
                                    isOpen: false,
                                });
                                document.body.style.cursor = "wait";
                                setWithdrawal(null);
                                dispatch(
                                    responseWithdrawalAgentETH(res?.data?.requestWithdrawalId, "Reject", token, (res) => {
                                        if (res.error) {
                                            if (res.message === "Token Expire") {
                                                dispatch({
                                                    type: LOGIN,
                                                    res,
                                                });
                                                history.push("/");
                                            }
                                        }
                                        document.getElementById("txid_hash").value = "";
                                        document.getElementById("amount").value = "";
                                        document.body.style.cursor = "default";
                                        if (res.error) {
                                            console.log("Error on reject withdrawal");
                                        }
                                    })
                                );
                            },
                        });
                    }
                })
            );
            setTimeout(() => {
                document.getElementById("requestBTC").disabled = false;
                document.getElementById("requestBTC").style.opacity = "1";
            }, 3000);
        }
    };

    useEffect(() => {
        dispatch(
            requestAgentWithdrawalETH(agentId, formData.txid_hash, formData.amount, merchant, formData.cryptoCurrency, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (!res.error) {
                    if (res.data) {
                        if (!isNaN(res.data.balanceETH)) {
                            setBalance(res.data);
                            setActualBalance(res.data.balanceETH);
                        } else {
                            setWithdrawal(res.data);
                            if (res.data) {
                                if (res.data.payoutStatus === "waiting") {
                                    setSteps(["Request Sent", "Processing", "Accepted"]);
                                    setActiveStep(0);
                                } else if (res.data.payoutStatus === "approved") {
                                    setSteps(["Request Sent", "Processing", "Accepted"]);
                                    setActiveStep(1);
                                } else if (res.data.payoutStatus === "processed") {
                                    setSteps(["Request Sent", "Processing", "Accepted"]);
                                    setActiveStep(2);
                                } else if (res.data.payoutStatus === "declined") {
                                    setSteps(["Request Sent", "Processed", "Declined"]);
                                    setStyleMessage(1);
                                    setActiveStep(2);
                                }
                            }
                        }
                    }
                }
            })
        );
    }, [dispatch, styleMessage, merchant, agentId]);

    if (withdrawal) {
        return (
            <div>
                <React.Fragment>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {styleMessage === 0 ? (
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "700",
                                                                    color: "#006600",
                                                                    fontSize: "18px",
                                                                }}
                                                            >
                                                                {getStepContent(withdrawal.payoutStatus === "declined" ? 3 : activeStep)}
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: "700",
                                                                    color: "#CC0000",
                                                                }}
                                                            >
                                                                {getStepContent(withdrawal.payoutStatus === "declined" ? 3 : activeStep)}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Amount USD:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`$${parseFloat(withdrawal.currencyAmount).toFixed(2)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>{`Amount ${formData.cryptoCurrency}:`}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`${formData.cryptoCurrency === "ETH" ? "Ξ" : formData.cryptoCurrency === "USDT" ? "₮" : "$"}${parseFloat(withdrawal.cryptoAmount).toFixed(8)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Total Fees:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`$${parseFloat(parseFloat(withdrawal.feeMerchantAmount) + parseFloat(withdrawal.feeAmountChain)).toFixed(2)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Exchange Rate:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{`$${parseFloat(withdrawal.exchangeRate).toFixed(2)}`}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: "50%",
                                            textAlign: "right",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "700" }}>Request Date:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontWeight: "700" }}>{moment(withdrawal.createdAt).format("MM/DD/YYYY hh:mm")}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "center" }} colSpan={2}>
                                        <Stepper alternativeLabel activeStep={activeStep} connector={withdrawal.payoutStatus === "declined" ? <ColorlibConnectorDecline /> : <ColorlibConnector />}>
                                            {steps.map((label) => (
                                                <Step key={label}>
                                                    <StepLabel StepIconComponent={withdrawal.payoutStatus === "declined" ? ColorlibStepIconDecline : ColorlibStepIcon}>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ textAlign: "center" }} colSpan={2}>
                                        <hr></hr>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            </div>
        );
    } else {
        return (
            <React.Fragment>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "100%" }} colSpan={2}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Crypto Currency</FormLabel>
                                        <RadioGroup aria-label="currency" name="cryptoCurrency" value={formData.cryptoCurrency} onChange={handleChangeCurrency}>
                                            <FormControlLabel value="ETH" control={<Radio />} label="ETH" />
                                            <FormControlLabel value="USDT" control={<Radio />} label="USDT" />
                                            <FormControlLabel value="USDC" control={<Radio />} label="USDC" />
                                        </RadioGroup>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "15%" }}>
                                    <Typography sx={{ fontWeight: "700" }}>Amount:</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ width: "85%" }}>
                                    <Input minValue={0} maxValue={balance} name="amount" id="amount" handleChange={handleChange} type="number" autoFocus />
                                    <Typography sx={{ fontWeight: "700" }}>{`Maximum request: $${actualBalance}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" sx={{ width: "15%" }}>
                                    <Typography sx={{ fontWeight: "700" }}>Ethereum Address:</Typography>
                                </TableCell>
                                <TableCell align="left" sx={{ width: "85%" }}>
                                    <TextField
                                        placeholder="Address where you would like your funds to be sent."
                                        name="txid_hash"
                                        id="txid_hash"
                                        fullWidth
                                        onChange={handleChange}
                                        sx={{
                                            backgroundColor: "#f6f9fc",
                                            width: "700px",
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} align="center">
                                    <Button type="submit" variant="contained" className={classes.submit} onClick={handleSubmit} id="requestBTC">
                                        {"Send Request"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
                <ConfirmDialog confirmDialog={confirmReject} setConfirmDialog={setConfirmReject} cryptoCurrency={formData.cryptoCurrency} />
            </React.Fragment>
        );
    }
};

export default AgentRequestWithdrawalETH;
