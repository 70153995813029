import React, { useState, useEffect } from "react";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { Box, Checkbox, FormControlLabel, Grid, Input, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import UseStyles from "./styles";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import OkDialog from "../Dialogs/OkDialog";
import { getAgentInfoSecurity, updateAgentSecurity, verifyTwoFactCode } from "../../actions/agent";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../constants/actionTypes";

const Security = () => {
    const classes = UseStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    const token = useSelector((state) => state?.login?.loginData?.token);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [postData, setPostData] = useState({
        agentId: agentId,
        twoFactorAuth: false,
    });

    const [showOptions, setShowOptions] = useState({
        showQr: false,
        showVerify: false,
        qrAuthString: "",
    });

    const [pinData, setPinData] = useState({
        agentId: agentId,
        pin: "",
        fromLogin: false,
    });

    const handleChangePin = (e) => {
        e.preventDefault();
        setPinData({ ...pinData, [e.target.name]: e.target.value });
    };

    const handleSubmitPin = (e) => {
        e.preventDefault();
        let pinValue = document.getElementById("pin").value;
        dispatch(
            verifyTwoFactCode(
                {
                    agentId: agentId,
                    merchant: merchant,
                    pin: pinValue,
                    fromLogin: false,
                },
                null,
                token,
                (res) => {
                    if (res.error) {
                        if (res.message === "Token Expire") {
                            dispatch({ type: LOGIN, res });
                            history.push("/");
                        }
                    }
                    //console.log('RETORNO DE VERIFICACION', res);
                    if (!res.error) {
                        setOkDialog({
                            isOpen: true,
                            title: "Security code verified",
                            subTitle: "Two factor verification is now active.",
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                        setShowOptions({ ...showOptions, showVerify: false });
                        setPinData({ ...pinData, pin: "" });
                    } else {
                        setOkDialog({
                            isOpen: true,
                            title: "Security code expire",
                            subTitle: `You'r code expire or is invalid, please try again.`,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                }
            ),
            []
        );
    };

    let googleLink = "";

    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        googleLink = "https://apps.apple.com/us/app/google-authenticator/id388497605";
    } else {
        googleLink = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US";
    }

    const changeTwoFactor = (e) => {
        e.preventDefault();
        setPostData({ ...postData, twoFactorAuth: e.target.checked });
        var info = {
            agentId: agentId,
            merchant: merchant,
            twoFactorAuth: e.target.checked,
        };
        dispatch(
            updateAgentSecurity(info, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (!res.error) {
                    if (res.data.twoFactorAuth === true) {
                        setShowOptions({
                            showQr: true,
                            showVerify: res.data.verify,
                            qrAuthString: res.data.strImageQR,
                        });
                    } else {
                        setShowOptions({
                            showQr: false,
                            showVerify: false,
                            qrAuthString: "",
                        });
                        setOkDialog({
                            isOpen: true,
                            title: "Security Updated",
                            subTitle: res?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                } else {
                    setOkDialog({
                        isOpen: true,
                        title: "Error on update security",
                        subTitle: res?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                }
            }),
            []
        );
    };

    useEffect(() => {
        dispatch(
            getAgentInfoSecurity(agentId, merchant, token, (data) => {
                if (data.error) {
                    if (data.message === "Token Expire") {
                        dispatch({ type: LOGIN, data });
                        history.push("/");
                    }
                }
                if (data) {
                    //console.log('DATA ON LOAD', data.data);
                    let showq = data.data.twoFactorAuth;
                    let showAct = true;
                    if (!data.data.twoFactorAuthActive) {
                        showAct = false;
                    }
                    setShowOptions({
                        showQr: showq,
                        showVerify: showAct,
                        qrAuthString: data.data.strImageQR,
                    });
                    setPostData({
                        ...postData,
                        twoFactorAuth: data.data.twoFactorAuth,
                    });
                }
            })
        );
    }, [dispatch]);

    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={12}>
                <Card style={{ borderRadius: "15px" }}>
                    <CardHeader color="info" style={{ borderRadius: "15px" }}>
                        <Typography className={classes.agentName}>{"Security"}</Typography>
                    </CardHeader>
                    <CardBody>
                        <FormControlLabel control={<Checkbox checked={postData.twoFactorAuth || false} onChange={changeTwoFactor} name="twoFactorAuth" color="primary" />} label="Enable Two Factor Authentication using Google Authenticator" className={classes.checkTwoFactor} />

                        {showOptions.showQr && (
                            <>
                                <br />
                                <Typography className={classes.textoScan}>{"Scan this with the "}</Typography>
                                <a href={googleLink} style={{ textDecoration: "none" }}>
                                    <Typography className={classes.textoGoogle}>{"Google Authenticator App"}</Typography>
                                </a>
                                <img src={showOptions.qrAuthString} className={classes.qrcode} />
                                <form
                                    className={classes.form}
                                    onSubmit={handleSubmitPin}
                                    style={{
                                        display: showOptions.showVerify ? "block" : "none",
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <TextField name="pin" id="pin" className={classes.input} variant="outlined" label="Verification Code" type={"number"} onChange={handleChangePin} value={pinData.pin} />
                                    </Grid>
                                    <Button type="submit" variant="contained" className={classes.submit}>
                                        {"Verify"}
                                    </Button>
                                </form>
                            </>
                        )}
                    </CardBody>
                </Card>
            </GridItem>
            <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
        </GridContainer>
    );
};

export default Security;
