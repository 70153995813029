import React from "react";
import Button from "@material-ui/core/Button";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const ConfirmRequest = (props) => {
    const { confirmDialog, setConfirmDialog } = props;

    return (
        <Dialog open={confirmDialog.isOpen} PaperProps={{ sx: { position: "fixed", top: 100 } }}>
            <DialogTitle>
                <Typography style={{ fontSize: "16px", color: "green", fontWeight: "bold", textAlign: "center" }}> {confirmDialog.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ textAlign: "center" }}>{confirmDialog.subTitle}</Typography>
            </DialogContent>
            <DialogActions>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ textAlign: "center", width: "50%" }} style={{ textAlign: "center" }}>
                                    <Button variant="contained" onClick={confirmDialog.onConfirm}>
                                        CONFIRM
                                    </Button>
                                </TableCell>
                                <TableCell sx={{ textAlign: "center", width: "50%" }} style={{ textAlign: "center" }}>
                                    <Button variant="contained" onClick={confirmDialog.onCancel}>
                                        CANCEL
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmRequest;
