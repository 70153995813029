import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { green, grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import { TextField, InputAdornment, IconButton, Input } from "@material-ui/core";
import { getCustomer, insertNewAgent, updateCustomer } from "../../actions/agent";
import OkDialog from "../../components/ConfirmDialog/OkDialog";
import BG from "../../assets/img/bg2.png";
import Logo from "../../assets/img/logo.png";
import Checkbox from "@material-ui/core/Checkbox";
import { whiteColor } from "../../assets/styles/components/materialdashboard";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../constants/actionTypes";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        textAlign: "center",
        backgroundColor: "#034AFF",
        color: "white",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: whiteColor,
    },
});

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "10px",
    },
    title: {
        marginTop: "50px",
        marginBottom: "30px",
        color: "#333333",
        fontWeight: "700",
    },
    topTitle: {
        fontSize: "18px",
        fontWeight: "700",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    input: {
        width: "100%",
        marginBottom: "10px",
    },

    inputcmb: {
        marginTop: "10px",
    },

    alerttext: {
        color: "red",
    },

    submitdiv: {
        width: "100%",
        textAlign: "center",
        margin: "auto 0 auto 0",
    },

    submit: {
        backgroundColor: "#333333",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        textAlign: "center",
        borderRadius: "10px",
        "&:hover": {
            backgroundColor: "#000000",
            color: "white",
        },
    },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const Customer = (props) => {
    const { openCustomer, setOpenCustomer, postData, setPostData } = props;
    const [okDialog, setOkDialog] = useState({ isOpen: false, title: "", subTitle: "" });
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector((state) => state?.login?.loginData?.token);

    const handleClose = () => {
        setOpenCustomer({
            open: false,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        //console.log("ID TO UPDATE", openCustomer?.customer?._id, postData._id);
        dispatch(
            updateCustomer(postData, postData.id, token, (data) => {
                //console.log("DATA TO UPDATE", postData);
                if (data?.error) {
                    setOkDialog({
                        isOpen: true,
                        title: "Error updating customer.",
                        subTitle: data?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                } else {
                    setOkDialog({
                        isOpen: true,
                        title: "Customer updated successfully.",
                        subTitle: "",
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                            openCustomer.onConfirm();
                        },
                    });
                }
            })
        );
    };

    useEffect(() => {
        //console.log(postData);
    }, [postData]);

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openCustomer?.isOpen || false} maxWidth="sm" fullWidth={true}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography className={classes.topTitle}>{`UPDATE CUSTOMER`}</Typography>
            </DialogTitle>
            <MuiDialogContent>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <form className={classes.form} noValidate onSubmit={onSubmit}>
                            <TextField name="name" id="name" className={classes.input} variant="outlined" label="Name" fullWidth value={postData?.name} onChange={(e) => setPostData({ ...postData, name: e.target.value })} />
                            <br />
                            <TextField name="notes" id="notes" multiline rows={3} className={classes.input} variant="outlined" label="Notes" fullWidth value={postData?.notes} onChange={(e) => setPostData({ ...postData, notes: e.target.value })} />
                            <br />
                            <FormControlLabel control={<Checkbox checked={postData?.freePlayBTC} onChange={(e) => setPostData({ ...postData, freePlayBTC: e.target.checked })} name="freeplayBTC" color="primary" />} label="Free Play BTC" />
                            <br />
                            <TextField name="freeplayBTCPercentage" id="freeplayBTCPercentage" className={classes.input} variant="outlined" label="Free Play BTC Percentage" value={postData?.freePlayBTCPercentage} onChange={(e) => setPostData({ ...postData, freePlayBTCPercentage: e.target.value })} />
                            <br />
                            <FormControlLabel control={<Checkbox checked={postData?.freePlayLN} onChange={(e) => setPostData({ ...postData, freePlayLN: e.target.checked })} name="freeplayLN" color="primary" />} label="Free Play Lightning" />
                            <br />
                            <TextField name="freeplayLNPercentage" id="freeplayLNPercentage" className={classes.input} variant="outlined" label="Free Play Lightning Percentage" value={postData?.freePlayLNPercentage} onChange={(e) => setPostData({ ...postData, freePlayLNPercentage: e.target.value })} />
                            <br />
                            <br />
                            <br />
                            <div className={classes.submitdiv}>
                                <Button type="submit" variant="contained" className={classes.submit}>
                                    UPDATE
                                </Button>
                            </div>
                            <br />
                        </form>
                    </div>
                </Container>
            </MuiDialogContent>
            <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
        </Dialog>
    );
};

export default Customer;
