import React, { useState } from "react";
import { Avatar, Button, Paper, Grid, Typography, Container, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import Logo from "../../assets/img/login-name.png";
import LogoSmall from "../../assets/img/login-icon.png";
import UseStyles from "./styles";
import Input from "./input";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { login } from "../../actions/login";
import { useHistory } from "react-router-dom";
import { verifyTwoFactCode } from "../../actions/agent";
import { makeStyles } from "@material-ui/core/styles";

const useStylesNew = makeStyles((theme) => ({
    inputStyle: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            display: "none",
        },
    },
}));

const initialState = {
    username: "",
    password: "",
};
const pinVerify = {
    agentId: null,
    merchant: null,
    pin: null,
    fromLogin: true,
};

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [pinData, setPinData] = useState(pinVerify);
    const [showError, setShowError] = useState({ show: false, error: "" });
    const [stepActual, setStepActual] = useState(1);
    const [qrAuthString, setQrAuthString] = useState("");

    localStorage.clear();

    const classes = UseStyles();
    const clases = useStylesNew();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangePin = (e) => {
        e.preventDefault();
        setPinData({ ...pinData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            login(formData, history, (res) => {
                if (res.error) {
                    setShowError({ show: true, error: res.message });
                    setTimeout(() => {
                        setShowError({ show: false, error: "" });
                    }, 6000);
                } else {
                    if (res.twoFactorAuth === true) {
                        setPinData({ ...pinData, agentId: res.agentId, merchant: res.merchant });
                        setStepActual(2);
                        document.getElementById("pin").focus();
                    }
                }
            })
        );
    };

    const handleSubmitPin = (e) => {
        e.preventDefault();
        let pinValue = document.getElementById("pin").value;
        setStepActual(3);
        dispatch(
            verifyTwoFactCode(
                {
                    agentId: pinData.agentId,
                    merchant: pinData.merchant,
                    pin: pinValue,
                    fromLogin: true,
                },
                history,
                "",
                (res) => {
                    if (!res.data) {
                        setStepActual(2);
                        setShowError({ show: true, error: "Invalid Code" });
                        setTimeout(() => {
                            setShowError({ show: false, error: "" });
                        }, 6000);
                    }
                }
            )
        );
    };

    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    if (stepActual === 1) {
        return (
            <div className={classes.main}>
                <div className={classes.logo}>
                    <img src={LogoSmall}></img>
                    <br />
                    <img src={Logo}></img>
                </div>
                <div className={classes.login}>
                    <Container component="main" maxWidth="xs" className={classes.main}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container spacing={1}>
                                <Typography className={classes.texto}>{"Username"}</Typography>
                                <Input name="username" label="Username" handleChange={handleChange} type="text" />
                                <Typography className={classes.texto}>{"Password"}</Typography>
                                <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} />
                            </Grid>
                            <br />
                            <Button type="submit" fullWidth variant="contained" className={classes.submit}>
                                {"Log In"}
                            </Button>
                        </form>
                        {showError.show ? (
                            <div className={classes.alert}>
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {showError.error}
                                </Alert>
                            </div>
                        ) : null}
                    </Container>
                </div>
            </div>
        );
    } else if (stepActual === 2) {
        return (
            <div className={classes.main}>
                <div className={classes.logo}>
                    <img src={LogoSmall}></img>
                    <br />
                    <img src={Logo}></img>
                </div>
                <div className={classes.login}>
                    <Container component="main" maxWidth="xs" className={classes.main}>
                        <form className={classes.form} onSubmit={handleSubmitPin}>
                            <Grid container spacing={1}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none" }}>
                                                <Typography className={classes.textoSec}>{"Security Verification"}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none" }}>
                                                <Typography className={classes.textoAuth}>{"Authenticador Code"}</Typography>
                                                <Input id="pin" name="pin" handleChange={handleChangePin} type="number" InputProps={{ classes: { input: classes.inputStyle } }} />
                                                <Typography className={classes.sixDigit}>{"Enter the 6-digit code from FlashTeller/Google Authenticator"}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Button type="submit" fullWidth variant="contained" className={classes.submit}>
                                {"Submit"}
                            </Button>
                        </form>
                        {showError.show ? (
                            <div className={classes.alert}>
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {showError.error}
                                </Alert>
                            </div>
                        ) : null}
                    </Container>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default Login;
