import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RequestWithdrawalTable from '../Tables/WithdrawalsRequest';
import ProcessWithdrawalTable from '../Tables/ProcessWithdrawals';
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import {Typography} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import UseStyles from './styles';
import { useSelector } from 'react-redux';

const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
        textTransform: "none",
      },
      secondary: {
        main: '#0560fb',
        textTransform: "none",
      },
    },
    root: {
        textTransform: "none"
    }
  });

  const CustomTab = withStyles({
    root: {
      textTransform: "none",
      paddingLeft: "30px",
      paddingRight: "30px",
      fontSize: "18px",
      fontWeight: "800",
      color: "#0560fb"
    }
  })(Tab);
  
  function TabPanel(props) {
      const { children, value, index, ...other } = props;
      const classes = UseStyles();

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <> 
              {children}
            </>
          )}
        </div>
      );
    }
    
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

const RequestWithdrawals = (props) => {
    const {titleWithdrawalsRequest} = props;
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    //const merchant = JSON.parse(localStorage.getItem('profile')).merchant;
    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    const classes = UseStyles();
    let isMobile = false;

    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    let title = 'Requested Withdrawals';
    let title2 = 'Processed Withdrawals';

    if(titleWithdrawalsRequest !== undefined){
        if(titleWithdrawalsRequest !== ''){
            title = titleWithdrawalsRequest;
        }        
    }

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        isMobile = true;
    }
    return (   
        <>
            <ThemeProvider theme={theme}>
            <AppBar className={classes.appbar} style={{boxShadow: "none"}} position="static">
                <Tabs classes={{indicator: classes.indicator}} value={value} onChange={handleChange} aria-label="">
                <CustomTab label="Requested" {...a11yProps(0)} />
                <CustomTab label="Processed" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            
            <TabPanel className={classes.tabpanel} value={value} index={0}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={12}>
                        <Card style={{borderRadius: "15px"}}>
                            <CardHeader color="info" style={{borderRadius: "15px"}}>
                                <Typography className={classes.agentName}>{title}</Typography>                                         
                            </CardHeader>
                            <CardBody>
                                <RequestWithdrawalTable agentId={agentId} merchant={merchant}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={12}>
                        <Card style={{borderRadius: "15px"}}>
                            <CardHeader color="info" style={{borderRadius: "15px"}}>
                                <Typography className={classes.agentName}>{title2}</Typography>                                         
                            </CardHeader>
                            <CardBody>
                                <ProcessWithdrawalTable agentId={agentId} merchant={merchant}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </TabPanel> 
            </ThemeProvider>
        </>   
    )
}

export default RequestWithdrawals