import React, { useState, createRef, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import styles from "../../assets/styles/components/admin";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Sidebar from "../SideBar/sidebar";
import imagebg from "../../assets/img/bg3.jpg";
import routes from "../../routes";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "../Navbar/navbar";
import Footer from "../Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
//Functions
import { getAgentInfo } from "../../actions/agent";
import { LOGIN } from "../../constants/actionTypes";
import { useHistory } from "react-router-dom";

let ps;

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            }
            return null;
        })}
        <Redirect from="/" to="/admin/dashboard" />
        <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
);

const useStyles = makeStyles(styles);

const Dashboard = ({ ...rest }) => {
    // styles
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = createRef();
    // states and functions
    const [image, setImage] = useState(imagebg);
    const [color, setColor] = useState("blue");
    const [fixedClasses, setFixedClasses] = useState("dropdown show");
    const [mobileOpen, setMobileOpen] = useState(false);

    const agentId = useSelector((state) => state?.login?.loginData?.agentId);
    const merchant = useSelector((state) => state?.login?.loginData?.merchant);
    const token = useSelector((state) => state?.login?.loginData?.token);
    //console.log('AGENT ID MAIN DASHBOARD', agentId);

    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;

    let isAdminUser = false;

    if (agentId === "admin") {
        isAdminUser = true;
    }

    const handleImageClick = (image) => {
        setImage(image);
    };
    const handleColorClick = (color) => {
        setColor(color);
    };
    const handleFixedClick = () => {
        if (fixedClasses === "dropdown") {
            setFixedClasses("dropdown show");
        } else {
            setFixedClasses("dropdown");
        }
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    useEffect(() => {
        //const interval = setInterval(() => {
        document.body.style.cursor = "wait";
        dispatch(
            getAgentInfo(agentId, merchant, token, (data) => {
                document.body.style.cursor = "default";
                if (data.error) {
                    if (data.message === "Token Expire") {
                        dispatch({ type: LOGIN, data });
                        history.push("/");
                    }
                }
            })
        );
        //}, 10000);
        //return () => clearInterval(interval);
    }, [dispatch]);

    const agentData = useSelector((state) => state.agent);
    const isAdmin = useSelector((state) => state.agent?.isAdmin);
    // initialize and destroy the PerfectScrollbar plugin
    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);
    return agentData ? (
        <div className={classes.wrapper}>
            <Sidebar routes={routes} logoText={"FlashTeller"} logo={logo} isAdmin={isAdmin} hasSubs={agentData.hasSubs} image={imagebg} handleDrawerToggle={handleDrawerToggle} open={mobileOpen} color={color} {...rest} />
            <div className={classes.mainPanel} ref={mainPanel}>
                <Navbar routes={routes} handleDrawerToggle={handleDrawerToggle} totalunreadmessages={agentData?.agent?.totalunreadmessages ? agentData?.agent?.totalunreadmessages : 0} agentUser={agentData?.agentData?.data?.username} {...rest} />
                <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes}</div>
                </div>
                <Footer />
            </div>
        </div>
    ) : null;
};

export default Dashboard;
